import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AiPill, AiBall, AiWord, AiCross, AiBot } from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const Hero = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <AnimatedSVG
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <AiBall primaryColor="#47AD4A" secondaryColor="#0F88C1" />
      </AnimatedSVG>
      <AnimatedSVG
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <AiPill primaryColor="#CE9200" secondaryColor="#EDB40D" />
      </AnimatedSVG>
      <RenderWhenReady>
        {windowWidth > 768 && (
          <>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#47AD4A" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiWord color="#FFFFFF" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBall primaryColor="#CE9200" secondaryColor="#E04527" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBall primaryColor="#7A68CC" secondaryColor="#DA629E" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#7A68CC" secondaryColor="#E04527" />
            </AnimatedSVG>
          </>
        )}
        {windowWidth > 1024 && (
          <>
          <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiWord color="#30F1FE" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#0F88C1" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#EDB40D" />
            </AnimatedSVG>
          </>
        )}
      </RenderWhenReady>
      <h1>
        The Ai Revolution: <br />
        <p>Redefining Healthcare Marketing</p>
      </h1>
      <RenderWhenReady>
        {windowWidth <= 768 && (
          <MobileIcons>
            <SVGContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBot />
            </SVGContainer>
            <SVGContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#EDB40D" />
            </SVGContainer>
            <SVGContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#7A68CC" />
            </SVGContainer>
            <SVGContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiWord color="#DA629E" />
            </SVGContainer>
          </MobileIcons>
        )}
      </RenderWhenReady>
    </Container>
  );
};

const SVGContainer = styled(motion.div)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export const AnimatedSVG = styled(motion.div)`
  @keyframes roll {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(-100px) rotate(-60deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  position: relative;
  margin: 25vw auto 5rem auto;
  max-width: 90rem;

  @media screen and (min-width: 48rem) {
    margin: 17.5vw 0 7.5rem;
  }

  @media screen and (min-width: 67.5rem) {
    margin: 8.5vw 0 4.75rem;
  }

  @media screen and (min-width: 90rem) {
    margin: 6rem auto 6.7rem;
  }

  h1,
  h2,
  p {
    position: relative;
    z-index: 1;
  }

  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: 0;
      left: -60px;
      width: 170px;
      svg {
        transform: rotate(-90deg);
        width: 100%;
      }
    }
    :nth-child(2) {
      top: -40px;
      left: 160px;
      width: 120px;
      svg {
        transform: rotate(90deg);
      }
    }
    :nth-child(3) {
      top: 100px;
      left: 500px;
      width: 90px;
      svg {
        transform: rotate(45deg);
      }
    }
    :nth-child(4) {
      top: 120px;
      left: -20px;
      width: 120px;
      opacity: 0 !important;
      svg {
        transform: rotate(45deg);
      }
      @media screen and (min-width: 64rem) {
        opacity: 1 !important;
      }
    }
    :nth-child(5) {
      bottom: -120px;
      left: -60px;
      width: 170px;
      opacity: 0 !important;
      svg {
        transform: rotate(-45deg);
      }
      @media screen and (min-width: 64rem) {
        opacity: 1 !important;
      }
    }
    :nth-child(6) {
      bottom: 100px;
      right: -40px;
      width: 170px;
      svg {
        animation: roll 3s 1s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(7) {
      bottom: 200px;
      right: 80px;
      width: 120px;
      svg {
        transform: rotate(270deg);
      }
    }
    :nth-child(8) {
      top: 40px;
      right: 280px;
      width: 120px;
    }
    :nth-child(9) {
      top: 80px;
      right: 400px;
      width: 80px;
      svg {
        animation: spin 3s 1s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(10) {
      top: 160px;
      right: 280px;
      width: 80px;
      svg {
        transform: rotate(45deg);
      }
    }
  }

  h1 {
    margin-top: 40vw;
    grid-column: 2;
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 1;
    p {
      font-size: 2rem;
      line-height: 1;
    }

    @media screen and (min-width: 48rem) {
      margin-top: 13.125rem;
      font-size: 6.125rem;
      p {
        font-size: 4.5rem;
      }
    }
  }
  @media screen and (min-width: 64rem) {
    ${AnimatedSVG} {
      :nth-child(1) {
        top: 0;
        left: 100px;
      }
      :nth-child(2) {
        top: -40px;
        left: 320px;
      }
    }
  }
`;

const MobileIcons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column: 2;
  height: 300px;
  ${SVGContainer} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: 25%;
      left: 0;
      width: 170px;
    }
    :nth-child(2) {
      bottom: 0;
      right: 0;
      width: 90px;
      svg {
        transform: rotate(45deg);
      }
    }
    :nth-child(3) {
      bottom: 100px;
      right: 80px;
      width: 90px;
      svg {
        animation: spin 3s 1s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(4) {
      top: -20px;
      right: 20px;
      width: 140px;
      svg {
        transform: rotate(45deg);
      }
    }
  }
`;

export default Hero;

const getPublicationPrices = (publication) => {
  const {
    frontCover,
    backCover,
    doublePageSpread,
    fullPage,
    halfPage,
    quarterPage,
    insideFront,
    insideBack,
    _10X4,
    sponsorshipStrip,
    looseInserts_WrapArounds_BoundInserts,
    cpdModuleSponsorship,
    _1MoMpu,
    _3MoMpu,
    _6MoMpu,
    _12MoMpu,
    _1MoLeaderboard,
    _3MoLeaderboard,
    _6MoLeaderboard,
    _12MoLeaderboard,
    mpu_lbdForDigitalIssue,
    bespokeEmail__under1000Recipients_,
    bespokeEmail__over1000Recipients_,
    tramWrap,
    tramTop,
    tramSide,
    tramCentre,
    dominationSkyline,
    portraitPanels,
    straplines,
    backPageSolus,
    frontPageSolus,
    perCycle,
    _30SecondSpot,
    billboard_HpCpm,
    lbd_MpuCpm,
    _1DayHomepageTakeover,
    other
  } = publication;

  const priceFieldValues = [
    frontCover,
    backCover,
    doublePageSpread,
    fullPage,
    halfPage,
    quarterPage,
    insideFront,
    insideBack,
    _10X4,
    sponsorshipStrip,
    looseInserts_WrapArounds_BoundInserts,
    cpdModuleSponsorship,
    _1MoMpu,
    _3MoMpu,
    _6MoMpu,
    _12MoMpu,
    _1MoLeaderboard,
    _3MoLeaderboard,
    _6MoLeaderboard,
    _12MoLeaderboard,
    mpu_lbdForDigitalIssue,
    bespokeEmail__under1000Recipients_,
    bespokeEmail__over1000Recipients_,
    tramWrap,
    tramTop,
    tramSide,
    tramCentre,
    dominationSkyline,
    portraitPanels,
    straplines,
    backPageSolus,
    frontPageSolus,
    perCycle,
    _30SecondSpot,
    billboard_HpCpm,
    lbd_MpuCpm,
    _1DayHomepageTakeover,
    other
  ];

  const hasValues = priceFieldValues.filter((field) => /\d/.test(field));

  return hasValues;
};

export default getPublicationPrices;

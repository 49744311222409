import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';

const Hero = ({ children, homepage }) => {
  const { windowHeight } = useWindowSize();

  return (
    <Container homepage={homepage} heroHeight={homepage ? windowHeight : 'auto'}>
      <div>{children}</div>
    </Container>
  );
};

const Container = styled.section`
  padding-top: ${({ homepage }) => (homepage ? '3rem' : '7.1rem')};
  position: relative;
  min-height: ${({ heroHeight }) => `${heroHeight}px`};
  > div {
    &:first-child {
      grid-column: 2 / 3;
      max-width: 1579px;
      margin: 0 auto;
      width: 100%;
    }
  }

  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1.208em;
    text-transform: capitalize;
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.001em;
    line-height: 1.6em;
  }

  @media screen and (min-width: 48rem) {
    padding-top: ${({ homepage }) => (homepage ? '7.5rem' : '10rem')};
    min-height: auto;
    h1 {
      font-size: 6rem;
      font-weight: 900;
      line-height: 1.219em;
    }
  }
`;

export default Hero;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import AgencyXLogo from 'images/shared/AgencyXLogo';
import AxBannerPoster from 'images/shared/ax-banner-poster.jpg';
import { motion, AnimatePresence } from 'framer-motion';

const HomepageHeader = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const videoRef = useRef(null);
  useEffect(() => {
    const id = window.addEventListener('scroll', () => {
      setScrollYPosition(window.scrollY);
    });
    return () => window.removeEventListener('scroll', id);
  }, []);

  return (
    <StyledHeader>
      <HeaderVideo
        ref={videoRef}
        muted
        autoPlay
        loop
        playsInline
        id="bgvid"
        preload="auto"
        controls={false}
        poster={AxBannerPoster}>
        <source src="/banner-video.mp4" type="video/mp4" />
      </HeaderVideo>
      <HeaderContent>
        <AnimatePresence>
          <Svg
            key="svg"
            $scrollPosition={scrollYPosition}
            initial={{ opacity: 0, transform: 'translateY(-50px)' }}
            animate={
              scrollYPosition > 0
                ? { opacity: 0, transform: 'translateY(-50px)' }
                : { opacity: 1, transform: 'translateY(0px)' }
            }
            transition={{ delay: 0.25, duration: 0.5 }}
            layout>
            <StyledAgencyXLogo />
          </Svg>

          <H1
            key="h1"
            initial={{ opacity: 0, transform: 'scale(0)' }}
            animate={{ opacity: 1, transform: 'scale(1)' }}
            transition={{ delay: 0.5, duration: 1 }}>
            Campaigns. Evolved.
          </H1>
          <P
            key="p"
            initial={{ opacity: 0, transform: 'translateY(50px)' }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            transition={{ delay: 1, duration: 1 }}>
            Agency X is an award winning creative agency for healthcare, helping brands gain
            competitive advantage by harnessing changes in consumer behaviour, technology, and
            business methods.
          </P>
          <P
            key="p2"
            initial={{ opacity: 0, transform: 'translateY(50px)' }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            transition={{ delay: 1.5, duration: 1 }}>
            <strong>
              We are made for progressive healthcare marketers who want to stay ahead of the game.
            </strong>
          </P>
        </AnimatePresence>
      </HeaderContent>
    </StyledHeader>
  );
};

const StyledAgencyXLogo = styled(AgencyXLogo)`
  width: 12rem;
  path {
    fill: white;
    &:nth-child(2) {
      fill: var(--ax-gold);
    }
  }
  @media (min-width: 768px) {
    width: 20rem;
  }
`;

const P = styled(motion.p)`
  font-size: 1rem;
  line-height: 1.4em;
  margin-bottom: 1em;
  color: white;
  text-align: center;
  max-width: 1000px;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const Svg = styled(motion.div)`
  width: auto;
  height: auto;
  opacity: 0;
  margin-bottom: 2rem;
`;

const HeaderVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  opacity: 70%;
`;

const H1 = styled(motion.h1)`
  font-size: 3rem;
  font-weight: 800;
  color: white;
  margin-bottom: 0.5em;
  text-align: center;
  @media (min-width: 768px) {
    h1 {
      font-size: 4rem;
    }
  }
  @media (min-width: 1150px) {
    font-size: 6.25rem;
  }
`;

const HeaderContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 1250px;
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 1rem;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 2rem;
  }
`;

const StyledHeader = styled.header`
  height: 100vh;
  position: relative;
  z-index: 0;
  min-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  @media (min-width: 1000px) and (max-width: 1300px) and (min-height: 1000px) {
    height: 50vh;
  }
`;

export default HomepageHeader;

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: state.isFocused ? `0.143em solid ${colors.primary}` : `1px solid ${colors.primary}`,
    borderRadius: '2px',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0',
    marginBottom: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: state.isFocused ? '0 0 0 0.571em' : '0 0 0 0.571em',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.primary,
    '&:hover': {
      color: colors.primary
    }
  }),
  clearIndicator:(provided) => ({
    ...provided,
    color: colors.primary,
    '&:hover': {
      color: colors.primary
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0,
    width: 'auto'
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: `0.25em solid ${colors.primary}`,
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.143em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.286em)',
    zIndex: '10'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: colors.primary
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.primary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: colors.primary
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.571em',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.primary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.primary,
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: 'normal',
    margin: 0,
    opacity: 0.75
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0
  }),
  multiValue: (provided) => ({
    ...provided,
    color: colors.secondary,
    backgroundColor: colors.primary,
    fontFamily: "'Noto Sans', sans-serif"
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: colors.secondary
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

export default customDropdownMenuStyles;

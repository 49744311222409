import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Star, Vs, ThreeBalls } from 'images/omnichannel/versus';
import { Mesh } from 'images/omnichannel/start-your-journey';

const Versus = () => {
  return (
    <Container>
      <Channel>
        <StyledMesh />
        <span>Omni</span>
        <br />
        Channel
      </Channel>
      <StarContainer>
        <Star />
        <Vs />
      </StarContainer>
      <Channel>
        <ThreeBalls />
        Multi
        <br />
        Channel
      </Channel>
    </Container>
  );
};

const spin = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 25deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 359deg);
  }
`;

const pop = keyframes`
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.175);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 67.5rem) {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    left: -3px;
    width: 100%;
  }
`;

const StyledMesh = styled(Mesh)`
  animation: ${spin} 3s ease-in-out infinite;
`;

const Channel = styled.h2`
  color: #000;
  display: inline;
  font-size: 3.9rem;
  flex-shrink: 1;
  line-height: 0.935em;
  text-transform: uppercase;
  position: relative;

  &:nth-of-type(1) {
    text-align: right;

    svg {
      width: 40.2%;
      position: absolute;
      top: -49.5%;
      left: -2.5%;
    }

    span {
      margin-right: 0.25em;
    }
  }

  &:nth-of-type(2) {
    svg {
      width: 40%;
      position: absolute;
      top: 10%;
      right: -8%;

      @media screen and (min-width: 4850px) {
        width: 52.75%;
        top: 6.5%;
        right: -21%;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    font-size: 6.25rem;
  }

  @media screen and (min-width: 67.5rem) {
    font-size: 7.225vw;

    &:nth-of-type(1) {
      padding-right: 0.07em;
    }

    &:nth-of-type(2) {
      padding-left: 0.07em;
    }
  }

  @media screen and (min-width: 1440px) {
    font-size: 6.5rem;
  }
`;

const StarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.9rem 0 1.25rem;
  max-width: 125px;
  position: relative;
  width: 100%;

  svg:nth-of-type(1) {
    animation: ${rotate} 9s infinite linear forwards;
    height: 100%;
    width: 100%;
  }

  svg:nth-of-type(2) {
    animation: ${pop} 0.8s infinite ease-in-out alternate-reverse;
    position: absolute;
    width: 38%;
  }

  @media screen and (min-width: 67.5rem) {
    flex-shrink: 1;
    max-width: 148px;
    margin: unset;
  }

  @media screen and (min-width: 1440px) {
    width: 148px;
  }
`;

export default Versus;

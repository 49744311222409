import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import {
  BenefitsX,
  ExBenefit1,
  ExBenefit2,
  ExBenefit3,
  ExBenefit4,
  ExBenefit5,
  ExBenefit6,
  ExBenefit7,
  ExBenefit8
} from 'images/event-x';

const benefitsInitialState = [
  {
    id: 1,
    text: `Sponsors’ Booths`
  },
  {
    id: 2,
    text: `Integrated Polling`
  },
  {
    id: 3,
    text: `Q&A Functions `
  },
  {
    id: 4,
    text: `Downloadable content & attendees analytics`
  },
  {
    id: 5,
    text: `Full compatibility with Livestream platforms (eg, Zoom, Vimeo, YouTube etc)`
  },
  {
    id: 6,
    text: `Easy to Use Moderation Panel`
  },
  {
    id: 7,
    text: `Full Social Media Integration`
  },
  {
    id: 8,
    text: `Donation Options`
  }
];

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0
    };
  }
};

const AXBenefits = () => {
  const { windowWidth } = useWindowSize();

  const [benefits] = useState(benefitsInitialState);

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const index = wrap(0, benefits.length, page);

  useEffect(() => {
    const benefitsTimer = setTimeout(() => paginate(1), 5000);
    return () => clearTimeout(benefitsTimer);
  }, [page]);

  return (
    <Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <FeaturesTitle>Features</FeaturesTitle>
      <FeaturesSubtitle>
        AX Engage is ideal for one off events, or a content series, and offers unique platform
        features and functions such as:
      </FeaturesSubtitle>
      {windowWidth >= 768 && (
        <Benefits>
          {benefits.map((benefit, i) => (
            <Benefit key={i}>
              <SVGContainer>
                {benefit.id === 1 ? (
                  <ExBenefit1 />
                ) : benefit.id === 2 ? (
                  <ExBenefit2 />
                ) : benefit.id === 3 ? (
                  <ExBenefit3 />
                ) : benefit.id === 4 ? (
                  <ExBenefit4 />
                ) : benefit.id === 5 ? (
                  <ExBenefit5 />
                ) : benefit.id === 6 ? (
                  <ExBenefit6 />
                ) : benefit.id === 7 ? (
                  <ExBenefit7 />
                ) : benefit.id === 8 ? (
                  <ExBenefit8 />
                ) : (
                  <ExBenefit1 />
                )}
              </SVGContainer>
              <Caption>
                <p>{benefit.text}</p>
              </Caption>
            </Benefit>
          ))}
        </Benefits>
      )}
      {windowWidth < 768 && (
        <Benefits
          key={page}
          id={benefits[index]}
          alt="slide"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            duration: 0.25
          }}
          drag="x"
          dragConstraints={{ left: 10, right: 10 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          <AnimatePresence initial={false} custom={direction}>
            <Benefit>
              <SVGContainer>
                {benefits[index].id === 1 ? (
                  <ExBenefit1 />
                ) : benefits[index].id === 2 ? (
                  <ExBenefit2 />
                ) : benefits[index].id === 3 ? (
                  <ExBenefit3 />
                ) : benefits[index].id === 4 ? (
                  <ExBenefit4 />
                ) : benefits[index].id === 5 ? (
                  <ExBenefit5 />
                ) : benefits[index].id === 6 ? (
                  <ExBenefit6 />
                ) : benefits[index].id === 7 ? (
                  <ExBenefit7 />
                ) : benefits[index].id === 8 ? (
                  <ExBenefit8 />
                ) : (
                  <ExBenefit1 />
                )}
              </SVGContainer>
              <Caption>
                <p>{benefits[index].text}</p>
              </Caption>
            </Benefit>
          </AnimatePresence>
        </Benefits>
      )}
      {windowWidth > 1150 && <StyledBenefitsX />}
    </Container>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const FeaturesTitle = styled.h3`
  justify-self: center;
  color: black;
  font-size: 2rem;
  line-height: 1em;
  margin-bottom: 0;
  letter-spacing: 0px;
  color: var(--ax-pink);
  grid-column: 2/3;
  text-align: center;
  text-transform: uppercase;
`;

const FeaturesSubtitle = styled.h5`
  justify-self: center;
  font-size: 1rem;
  line-height: 1.25em;
  letter-spacing: 0px;
  margin: 2em auto;
  grid-column: 2/3;
  text-align: center;
  @media (min-width: 48em) {
    font-size: 1.25em;
  }
`;

const Container = styled(motion.div)`
  position: relative;
  z-index: 1;
  grid-column: 1/6;
  @media (min-width: 900px) {
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const StyledBenefitsX = styled(BenefitsX)`
  bottom: -10em;
  left: -7.5em;
  position: absolute;
  width: 12.5em;
`;

const Benefits = styled(motion.div)`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-row-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    margin-bottom: 5rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, minmax(220px, 300px));
  }
`;

const Caption = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  text-align: center;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
    transition: font-weight 0.25s ease-in-out;
    line-height: 1.25em;
    font-weight: 100;
    margin: 0;
  }
`;

const SVGContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ex-beige);
  position: relative;
  svg {
    position: absolute;
    max-width: 75%;
    width: 8rem;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      transition: 0.5s;
    }
  }
`;

const Benefit = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid transparent;
  cursor: pointer;
  :hover {
    border: 1px solid var(--ax-pink);
  }
  :hover ${SVGContainer} {
    svg {
      path {
        stroke: var(--ax-pink);
      }
    }
  }
  :hover ${Caption} {
    color: var(--ax-pink);
    p {
      font-weight: 700;
    }
  }
  @media (max-width: 768px) {
    border: 1px solid var(--ax-pink);
    ${SVGContainer} {
      svg {
        path {
          stroke: var(--ax-pink);
        }
      }
    }
    ${Caption} {
      color: var(--ax-pink);
    }
  }
`;

export default AXBenefits;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Design = ({ design }) => {
  const [showDesignToastr, setDesignToastr] = useState(false);

  useEffect(() => {
    setDesignToastr(true);
    return () => setDesignToastr(false);
  }, []);
  return (
    <>
      {showDesignToastr && <Toastr>DESIGN</Toastr>}
      <Img src={design} alt="design" />
    </>
  );
};

const Img = styled.img`
  left: 0;
  max-width: 375px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  opacity: 0.75
`;

const Toastr = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  background-color: var(--ax-gold);
  padding: 0.5em 1em;
  border-radius: 2px;
  z-index: 1000;
`;

export default Design;

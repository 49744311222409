import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const variants = {
  enter: (direction) => {
    return {
      opacity: 0
    };
  },
  center: {
    opacity: 1
  },
  exit: (direction) => {
    return {
      opacity: 0
    };
  }
};

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      author: 'Communications Manager Fighting Blindness',
      quote:
        'Working with Agency X was a fantastic experience, they went above and beyond our expectations. The final product was polished and professional and the feedback from attendees was exceptional. We’re already looking forward to Retina 2021!'
    },
    {
      id: 2,
      author: 'Pfizer Ireland HBU Marketing Lead',
      quote: 'A great team, highly recommended for creative strategy.'
    },
    {
      id: 3,
      author: 'IPHA Commercial Manager',
      quote:
        'Over the last few years, the IPHA have worked very closely with Brian and all his team at Agency X, on several different projects. They are professional, yet friendly, and always deliver in a timely, accommodating and creative manner. We look forward to working together on more successful projects.'
    }
  ];

  const data = useStaticQuery(graphql`
    query {
      officeImage: file(relativePath: { eq: "home/people_in_office_tablet.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
    }
  `);

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const selectPage = (selectedPage) => {
    setPage([selectedPage, 1]);
  };

  const index = wrap(0, testimonials.length, page);

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [page]);

  return (
    <Section>
      <Container
        key={page}
        id={testimonials[index]}
        alt="slide"
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          duration: 0.5
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}>
        <AnimatePresence initial={false} custom={direction}>
          <Slide layout>
            <PeopleInOffice image={getImage(data.officeImage)} alt="people in office" />
            <TextContainer>
              <div>“</div>
              <p>{testimonials[index].quote}</p>
              <h5>{testimonials[index].author}</h5>
            </TextContainer>
          </Slide>
        </AnimatePresence>
      </Container>
    </Section>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const PeopleInOffice = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  grid-column: 2/3;
  height: auto;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden !important;
  position: relative;
`;

const Slide = styled(motion.div)`
  align-items: center;
  cursor:grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  @media (min-width: 48em) {
    padding: 4rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 800px;
  div {
    font-size: 7.5rem;
    color: white;
    text-align: Center;
    line-height: 1em;
    margin-bottom: -0.4em;
  }
  h5 {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    color: white;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5em;
  }
  @media (min-width: 48em) {
    div {
      font-size: 10rem;
    }
    h5 {
      font-size: 1rem;
      span {
        font-weight: 300;
      }
    }
    p {
      font-size: 1rem;
    }
  }
`;

const Section = styled.section`
  margin-bottom: 5rem;
  > div {
    grid-column: 2 / 3;
    @media screen and (min-width: 48rem) {
      grid-column: 1 / -1;
      margin: 0 1.25rem;
    }
    @media screen and (min-width: 76.875rem) {
      margin: 0 3rem;
    }
    @media screen and (min-width: 114.75rem) {
      max-width: 108.75rem;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default Testimonials;

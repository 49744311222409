import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RenderWhenReady } from 'components';
import { AiCross, AiBot, AiWord, AiPill, AiBall } from 'images/aiMarketingCaseStudy';
import { useWindowSize } from 'hooks';

const Introduction = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <motion.div>
        <div>
          <h2>Harnessing the potential of Generative Ai</h2>
          <p>
            The WHO reports an estimated shortage of 10 million health workers in 2030 -
            particularly in low and lower-middle-income countries. The projected labour shortage is
            anticipated to further strain an already burdened industry. Healthcare and
            pharmaceutical organisations are hoping that solutions driven by artificial intelligence
            (AI) will improve the efficiency of industry professionals.
            <br />
            <br />
            Generative artificial intelligence (AI) has already demonstrated its utility in two
            broad areas:
          </p>
        </div>
        <RenderWhenReady>
          {windowWidth > 768 && (
            <div style={{ position: 'relative' }}>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiWord color="#DA629E" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiPill primaryColor="#DA629E" secondaryColor="#0F88C1" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiBot />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiPill primaryColor="#47AD4A" secondaryColor="#0F88C1" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiBall primaryColor="#E04527" secondaryColor="#FFFFFF" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiCross color="#EDB40D" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiBall primaryColor="#7A68CC" secondaryColor="#EDB40D" />
              </AnimatedSVG>
              <AnimatedSVG
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <AiPill primaryColor="#47AD4A" secondaryColor="#EDB40D" />
              </AnimatedSVG>
            </div>
          )}
        </RenderWhenReady>
      </motion.div>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  @keyframes roll {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(-100px) rotate(-60deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0 0 0;
  margin: 0 auto;
  position: relative;

  > div {
    grid-column: 2/3;
  }
  h2 {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      bottom: 0;
      right: 0;
      width: 120px;
      svg {
        transform: rotate(45deg);
      }
      @media (min-width: 64rem) {
        right: -120px;
        bottom: -80px;
      }
    }
    :nth-child(2) {
      top: -80px;
      left: 100px;
      width: 120px;
      svg {
        transform: rotate(90deg);
      }
    }
    :nth-child(3) {
      top: 120px;
      right: -40px;
      width: 170px;
      @media (min-width: 64rem) {
        top: 0;
      }
    }
    :nth-child(4) {
      bottom: 20px;
      right: -180px;
      width: 120px;
    }
    :nth-child(5) {
      width: 170px;
      left: 0;
      bottom: 90px;
      @media (min-width: 64rem) {
        left: 35%;
        svg {
          animation: roll 3s 1s ease-in-out infinite alternate-reverse;
        }
      }
    }
    :nth-child(6) {
      width: 80px;
      left: 60%;
      top: -130px;
      svg {
        animation: spin 2s 1s ease-in-out infinite alternate-reverse;
      }
      @media (min-width: 64rem) {
        top: -40px;
      }
    }
    :nth-child(7) {
      width: 170px;
      left: -120px;
      top: -140px;
      svg {
        transform: rotate(-70deg);
      }
    }
    :nth-child(8) {
      top: -220px;
      right: -120px;
      width: 120px;
    }
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 3rem 0;

    > div {
      display: grid;
      grid-template-columns: 4fr 3fr;
    }
  }
`;

export default Introduction;

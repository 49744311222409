const getPrices = (publication) => {
  const fieldsToRemove = [
    'hcpOnly',
    'nameOfPublication',
    'mediaType',
    'associatedBody',
    'publisher',
    'print',
    'contact',
    'website',
    'email',
    'contentDescription',
    'frequency',
    'audience',
    'reach',
    'importantInfo',
    'deadlines',
    'agencyRebate',
    'maximumPotentialDiscount'
  ];
  const onlyPrices = Object.entries(publication).filter(
    (field) => !fieldsToRemove.includes(field[0]) && field[1] !== null
  );

  return onlyPrices;
};

export default getPrices;

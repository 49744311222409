import * as React from 'react';
const SvgAiMarketer2 = (props) => (
  <svg viewBox="0 0 194 224" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M149.645 179.375c24.375-24.377 24.375-63.901 0-88.278-24.376-24.377-63.897-24.377-88.273 0-24.376 24.377-24.376 63.901 0 88.278 24.376 24.378 63.897 24.378 88.273 0Z"
      fill="#C41A6F"
    />
    <path
      d="M105.507 191.295c-14.974 0-29.05-5.831-39.637-16.419-10.587-10.588-16.417-24.666-16.417-39.64 0-14.973 5.83-29.051 16.418-39.639 10.587-10.588 24.665-16.42 39.637-16.42 14.972 0 29.049 5.832 39.636 16.42 10.588 10.589 16.419 24.666 16.419 39.639 0 14.974-5.831 29.052-16.419 39.64-10.587 10.588-24.664 16.419-39.636 16.419h-.001Z"
      fill="#C41A6F"
    />
    <path
      d="M105.507 188.749c-14.292 0-27.73-5.565-37.838-15.673-10.106-10.108-15.672-23.545-15.672-37.84 0-14.294 5.566-27.732 15.672-37.84 10.108-10.107 23.544-15.673 37.838-15.673 14.293 0 27.73 5.566 37.837 15.674 10.107 10.107 15.673 23.545 15.673 37.839 0 14.295-5.566 27.732-15.673 37.84-10.107 10.108-23.544 15.673-37.837 15.673Z"
      fill="#DA629E"
    />
    <path
      d="M108.98 138.708a4.91 4.91 0 0 0 0-6.944 4.91 4.91 0 0 0-6.944 0 4.91 4.91 0 0 0 0 6.944 4.91 4.91 0 0 0 6.944 0Z"
      fill="#000"
    />
    <path
      d="M105.253 135.858a1.424 1.424 0 0 0 2.009 0l27.3-27.301a1.426 1.426 0 0 0 0-2.009 1.424 1.424 0 0 0-2.009 0l-27.3 27.301a1.426 1.426 0 0 0 0 2.009Z"
      fill="#000"
    />
    <path
      d="M106.496 99.513a2.85 2.85 0 0 1-2.841-2.841v-10.1a2.85 2.85 0 0 1 2.841-2.84 2.849 2.849 0 0 1 2.84 2.84v8.201c0 1.562-1.278 4.74-2.84 4.74ZM106.496 170.959a2.85 2.85 0 0 0-2.841 2.841v10.1a2.85 2.85 0 0 0 2.841 2.841 2.85 2.85 0 0 0 2.84-2.841v-10.1a2.85 2.85 0 0 0-2.84-2.841ZM54.994 134.501v1.471c0 1.163.942 2.105 2.105 2.105h11.57a2.105 2.105 0 0 0 2.105-2.105v-1.471a2.105 2.105 0 0 0-2.105-2.105H57.1a2.105 2.105 0 0 0-2.105 2.105ZM142.216 134.5v1.471c0 1.163.943 2.105 2.105 2.105h11.571a2.105 2.105 0 0 0 2.105-2.105V134.5a2.105 2.105 0 0 0-2.105-2.105h-11.571a2.105 2.105 0 0 0-2.105 2.105ZM70.128 112.877a3.096 3.096 0 1 0-6.042-1.354 3.096 3.096 0 0 0 6.042 1.354ZM88.169 95.189a3.097 3.097 0 1 1-6.194-.001 3.097 3.097 0 0 1 6.194.001ZM145.027 115.115a3.095 3.095 0 1 0-1.793-5.927 3.095 3.095 0 0 0 1.793 5.927ZM123.061 95.189a3.096 3.096 0 1 0 6.191.001 3.096 3.096 0 0 0-6.191-.001ZM69.402 160.314a3.097 3.097 0 0 0-4.627-4.115 3.096 3.096 0 0 0 4.627 4.115ZM85.575 178.321a3.096 3.096 0 1 0-.992-6.112 3.096 3.096 0 0 0 .992 6.112ZM146.776 159.923a3.096 3.096 0 1 0-5.267-3.256 3.096 3.096 0 0 0 5.267 3.256ZM123.061 175.284a3.095 3.095 0 1 1 6.192 0 3.095 3.095 0 1 1-6.192 0Z"
      fill="#fff"
    />
    <path
      d="M105.615 135.237c.781 0 1.421-.64 1.421-1.421v-25.319c0-.782-.64-1.421-1.421-1.421-.782 0-1.421.639-1.421 1.421v25.319c0 .781.639 1.421 1.421 1.421Z"
      fill="#000"
    />
    <path
      d="M56.166 190.44H16.33c-2.55-1.644-7.052-5.042-10.28-10.924-4.678-8.521-3.676-16.764-3.213-20.562.348-2.85 1.488-11.38 7.39-18.635 1.47-1.808 2.475-2.6 8.674-9.638 4.042-4.59 4.57-5.336 5.14-6.426 3.081-5.882 2.235-11.844 1.928-13.815-.7-4.49-10.298-17.222-14.457-22.168-.863-1.027-5.64-6.806-8.353-14.78-1.164-3.422-5.143-15.119.322-27.31C6.39 39.69 10.852 35.57 13.76 33.33h44.978c2.907 2.238 7.37 6.358 10.28 12.852 5.464 12.191 1.485 23.888.322 27.31-2.713 7.974-7.49 13.752-8.353 14.78-4.16 4.947-13.758 17.678-14.457 22.168-.307 1.971-1.154 7.933 1.928 13.815.57 1.09 1.097 1.836 5.14 6.426 6.199 7.038 7.203 7.83 8.674 9.638 5.901 7.255 7.042 15.785 7.39 18.635.462 3.798 1.465 12.041-3.213 20.562-3.23 5.882-7.73 9.28-10.28 10.924h-.002Z"
      fill="#fff"
    />
    <path
      d="M20.354 190.44c-3.531-2.441-9.47-8.869-11.457-12.488-4.175-7.603-3.27-15.035-2.836-18.606.832-6.835 3.082-12.547 6.685-16.977.579-.71 1.085-1.257 2.006-2.247 1.226-1.319 3.08-3.313 6.586-7.294 4.181-4.748 4.828-5.629 5.58-7.066 2.417-4.613 3.177-9.937 2.26-15.823-.247-1.595-1.188-4.601-6.465-12.258-3.212-4.66-6.702-9.109-8.714-11.503-1.217-1.448-5.378-6.721-7.763-13.735-.567-1.664-1.745-5.128-2.166-9.623-.515-5.492.284-10.643 2.376-15.31 1.88-4.196 7.064-11.119 10.793-14.182h38.205c3.728 3.063 8.732 9.985 10.612 14.181 2.092 4.667 2.891 9.819 2.377 15.311-.422 4.495-1.6 7.959-2.166 9.623-2.386 7.015-6.546 12.287-7.764 13.735-2.013 2.394-5.502 6.843-8.714 11.503-5.276 7.656-6.217 10.663-6.465 12.258-.916 5.886-.156 11.21 2.26 15.823.752 1.437 1.4 2.318 5.58 7.066 3.507 3.981 5.36 5.975 6.587 7.294.92.99 1.427 1.537 2.005 2.247 3.604 4.43 5.852 10.141 6.685 16.976.435 3.571 1.34 11.003-2.835 18.606-1.988 3.619-8.331 10.047-11.862 12.488H20.36l-.006.001Z"
      fill="#fff"
    />
    <path
      d="M35.935 129.821c.547.044 1.989-9.885 7.746-21.361.61-1.873 2.122-5.002 6.103-10.778 3.213-4.66 6.702-9.109 8.714-11.503.596-.707 1.892-2.33 3.342-4.62-9.975.142-16.278-1.736-20.447-3.983-.972-.524-3.347-1.887-7.017-3.64-4.567-2.18-11.1-5.255-19.753-7.277a79.113 79.113 0 0 0-10.291-1.695c.529 3.449 1.428 6.091 1.9 7.481 2.387 7.014 6.546 12.286 7.764 13.736 2.012 2.393 5.503 6.842 8.714 11.502 4.718 6.844 5.969 9.971 6.359 11.697 5.353 11.478 6.385 20.404 6.865 20.441h.001ZM37.932 135.236a1.684 1.684 0 1 1-3.368.001 1.684 1.684 0 0 1 3.368-.001ZM33.087 141.258a.969.969 0 1 1-1.937 0 .969.969 0 0 1 1.937 0ZM37.932 146.628a1.471 1.471 0 1 0 0-2.943 1.471 1.471 0 0 0 0 2.943ZM33.087 148.276a.968.968 0 1 1-1.937 0 .968.968 0 0 1 1.937 0ZM38.218 155.492a3.08 3.08 0 1 0-3.95-4.73 3.08 3.08 0 0 0 3.95 4.73Z"
      fill="#F096F9"
    />
    <path
      d="M106.328 137.994a2.84 2.84 0 1 0-1.646-5.438 2.84 2.84 0 0 0 1.646 5.438Z"
      fill="#000"
    />
    <path
      d="M36.235 162.398a1.672 1.672 0 1 1-3.344 0 1.672 1.672 0 0 1 3.344 0ZM56.988 161.012c-11.696 2.077-28.33 12.477-37.427 13.256-3.163.271-7.271.619-12.35-.075a25.391 25.391 0 0 0 1.685 3.758c1.987 3.619 7.926 10.047 11.457 12.488h31.383c3.531-2.441 9.875-8.869 11.862-12.488 3.734-6.798 3.405-13.458 2.983-17.345-2.915-.251-6.15-.207-9.593.405v.001Z"
      fill="#F096F9"
    />
    <path
      d="M64.174 190.075H8.322a4.407 4.407 0 0 0 0 8.814h55.852a4.407 4.407 0 1 0 0-8.814ZM68.836 25.85H3.66A3.66 3.66 0 0 0 0 29.51v2.557a3.66 3.66 0 0 0 3.66 3.66h65.176a3.66 3.66 0 0 0 3.66-3.66V29.51a3.66 3.66 0 0 0-3.66-3.66Z"
      fill="#DA629E"
    />
    <path
      d="M155.776 26.88c-2.14-16.865-17.43-28.786-34.15-26.628-16.72 2.16-28.54 17.581-26.4 34.445 2.14 16.864 17.431 28.785 34.151 26.626 16.72-2.158 28.539-17.58 26.399-34.444Zm-47.994 6.196c-1.253-9.87 5.665-18.896 15.451-20.16 9.786-1.263 18.734 5.714 19.987 15.584 1.253 9.87-5.665 18.896-15.451 20.16-9.786 1.263-18.734-5.714-19.987-15.584Z"
      fill="#E9E7DD"
    />
    <path
      d="M108.098 34.856a18.73 18.73 0 0 1-.315-1.78 18.068 18.068 0 0 1 3.082-12.618l-10.246-7.498a30.875 30.875 0 0 0-5.392 21.737c.118.932.276 1.85.473 2.75a30.704 30.704 0 0 0 7.456 14.313l9.365-8.592a17.958 17.958 0 0 1-4.423-8.312Z"
      fill="#DA629E"
    />
    <path
      d="M123.233 12.916c6.07-.783 11.818 1.604 15.607 5.886l9.453-8.493C141.819 2.993 131.998-1.086 121.626.253c-8.781 1.133-16.21 5.925-20.975 12.662l10.307 7.413c2.789-3.943 7.137-6.748 12.276-7.412h-.001Z"
      fill="#F096F9"
    />
  </svg>
);
export default SvgAiMarketer2;

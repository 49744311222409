import * as React from 'react';

function SvgLocationIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#ce9200" />
      <g stroke="#fff" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round">
        <path d="M16.616 10.08c0 3.766-4.665 8.792-4.665 8.792s-4.566-5.026-4.566-8.792c0-2.416 2.084-4.541 4.665-4.541 2.58 0 4.566 2.029 4.566 4.54z" />
        <path d="M12 12a1.846 1.846 0 100-3.692A1.846 1.846 0 0012 12z" />
      </g>
    </svg>
  );
}

export default SvgLocationIcon;

import React, { createContext, useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

const NetlifyContext = createContext();

const NetlifyIdentityProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const agencyXPrivateRoutePath = JSON.parse(
      window.localStorage.getItem('agencyXPrivateRoutePath')
    )?.data;
    if (agencyXPrivateRoutePath && user?.email === 'guest@agencyx.ie') {
      navigate(agencyXPrivateRoutePath, { replace: true });
    }
  }, [pathname, user]);

  useEffect(() => {
    netlifyIdentity.on('init', (_user) => {
      if (_user) {
        setUser(_user);
        setIsLoading(false);
      } else {
        netlifyIdentity.open();
      }
    });

    netlifyIdentity.on('login', (_user) => {
      setUser(_user);
      setIsLoading(false);
      netlifyIdentity.close();
    });

    netlifyIdentity.on('logout', () => {
      setUser(null);
      netlifyIdentity.close();
      navigate('/');
      setIsLoading(false);
    });

    netlifyIdentity.on('error', (err) => {
      console.error('Error', err);
      setIsLoading(false);
    });

    netlifyIdentity.on('open', () => {
      // Hides the Netlify logo. Tee-hee! :)
      setTimeout(() => {
        const iframe = document.getElementById('netlify-identity-widget').contentDocument;
        const modal = iframe && iframe.getElementsByClassName('modalContent')[0];

        if (modal) {
          modal.style.marginTop = 0;
        }

        const codedByNetlify = iframe && iframe.getElementsByClassName('callOut')[0];

        if (codedByNetlify) {
          codedByNetlify.style.display = 'none';
        }
      }, 100);
    });

    netlifyIdentity.init();
  }, []);

  return (
    <NetlifyContext.Provider value={{ isLoading, netlifyIdentity, user }}>
      {props.children}
    </NetlifyContext.Provider>
  );
};

export { NetlifyIdentityProvider };
export default NetlifyContext;

import React, { useContext } from 'react';
import { LoadingSpinner } from 'components';
import { NetlifyIdentityContext } from 'context';

const PrivateRoute = ({ children }) => {
  const { isLoading, user, netlifyIdentity } = useContext(NetlifyIdentityContext);

  const isLoggedIn = Boolean(netlifyIdentity && user);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner
          style={{
            color: '#ce9200',
            margin: '0 auto',
            position: 'relative',
            top: 'calc(50vh - 4rem)',
            width: '8rem'
          }}
        />
      ) : isLoggedIn ? (
        children
      ) : null}
    </>
  );
};

export default PrivateRoute;

import * as React from 'react';
const SvgSlide4 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M119.5 190 31 135.5V56.833C31 42.56 37.372 31 45.24 31h149.52C202.628 31 209 42.56 209 56.833V137"
      fill="#CE9200"
    />
    <path
      d="M230 77.42v138.387c0 7.842-6.352 14.193-14.194 14.193H24.193C16.352 230 10 223.649 10 215.807V77.42M31.29 63.758 10.142 77.242M157.329 31.29l-30.09-19.054c-4.684-2.91-10.148-3.052-15.045 0L82.281 31.29M229.858 77.243 208.71 63.83"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m229.858 123.69-102.619 64.971c-4.684 2.91-10.149 3.052-15.045 0L10.142 123.69"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.29 116.452V45.484c0-7.842 6.352-14.193 14.194-14.193h149.032c7.842 0 14.194 6.351 14.194 14.193v70.968"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M129.013 153.462c-13.022.142-26.4-2.945-36.868-10.645-18.522-13.662-21.751-40.913-11.319-60.642 9.652-18.24 31.12-27.181 50.99-22.887 19.268 4.151 33.568 20.793 33.994 39.564v1.703c.71 40.771-31.581 26.329-31.581 15.791V81.004"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M130.752 83.665c-5.926-1.42-11.746-1.987-17.672.745-11 5.074-15.364 18.7-12.348 29.345 1.526 5.429 5.678 10.361 11.355 12.1 11.39 3.513 17.848-2.732 21.113-10.539"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide4;

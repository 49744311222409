import * as React from 'react';

function SvgMobileIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#ce9200" />
      <g stroke="#fff" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round">
        <path d="M8.309 17.504V6.496c0-.575.351-.957.879-.957h5.626c.528 0 .88.382.88.957v11.008c0 .575-.352.958-.88.958H9.188c-.528 0-.88-.383-.88-.958zM8.309 15.015h7.384M11.385 16.643h1.318M11.914 7.166h.264" />
      </g>
    </svg>
  );
}

export default SvgMobileIcon;

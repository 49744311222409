import * as React from 'react';
const SvgAiMarketer1 = (props) => (
  <svg viewBox="0 0 291 209" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M145.25 43.28C65.03 43.28 0 108.315 0 188.54h9.541c0-74.954 60.759-135.717 135.709-135.717s135.708 60.762 135.708 135.717h9.542c0-80.224-65.031-145.259-145.25-145.259Z"
      fill="#fff"
    />
    <path
      d="m101.877 158.951 5.611 3.972a45.718 45.718 0 0 1 23.453-17.713l-2.01-6.585c-11.109 3.631-20.589 10.866-27.054 20.325v.001Z"
      fill="#EDB40D"
    />
    <path
      d="M145.25 136.036a52.47 52.47 0 0 0-16.319 2.59l2.01 6.585a45.559 45.559 0 0 1 14.309-2.302 45.494 45.494 0 0 1 16.529 3.1l2.149-6.548a52.352 52.352 0 0 0-18.678-3.425Z"
      fill="#AE7C01"
    />
    <path
      d="M92.75 188.539h6.872a45.418 45.418 0 0 1 7.866-25.616l-5.61-3.972a52.254 52.254 0 0 0-9.128 29.588ZM163.928 139.46l-2.149 6.549a45.767 45.767 0 0 1 22.156 18.343l5.554-4.095c-6.027-9.408-14.974-16.765-25.562-20.797h.001Z"
      fill="#CE9200"
    />
    <path
      d="M183.935 164.352a45.402 45.402 0 0 1 6.943 24.188h6.872c0-10.414-3.033-20.12-8.261-28.282l-5.554 4.095v-.001Z"
      fill="#EDB40D"
    />
    <path
      d="M9.541 188.539H92.75a52.26 52.26 0 0 1 9.127-29.588l-67.915-48.087c-15.386 22.007-24.42 48.784-24.42 77.675Z"
      fill="#CE9200"
    />
    <path
      d="m33.962 110.865 67.916 48.087c6.464-9.461 15.944-16.694 27.053-20.325L104.63 59.011c-28.922 9.063-53.672 27.546-70.668 51.854Z"
      fill="#EDB40D"
    />
    <path
      d="m189.888 60.346-25.96 79.114c10.587 4.032 19.534 11.389 25.562 20.797l67.034-49.412c-16.202-23.164-39.448-41.032-66.634-50.5h-.002Z"
      fill="#CE9200"
    />
    <path
      d="M145.25 52.824c-14.152 0-27.795 2.168-40.621 6.188l24.302 79.615a52.43 52.43 0 0 1 16.319-2.59 52.356 52.356 0 0 1 18.678 3.425l25.96-79.115c-13.98-4.868-28.998-7.522-44.638-7.522v-.002Z"
      fill="#AE7C01"
    />
    <path
      d="M280.958 188.539c0-28.899-9.039-55.683-24.434-77.695l-67.034 49.412c5.23 8.163 8.261 17.869 8.261 28.282h83.207v.001Z"
      fill="#EDB40D"
    />
    <path d="m148.912 118.495-5.891.017.186 66.61 5.891-.017-.186-66.61Z" fill="#000" />
    <path
      d="m145.935 103.144-8.851 30.724 18.254-.053-9.403-30.671ZM154.306 194.09c4.351-4.351 4.351-11.404 0-15.755-4.35-4.35-11.403-4.35-15.753 0-4.35 4.351-4.35 11.404 0 15.755 4.35 4.35 11.403 4.35 15.753 0Z"
      fill="#000"
    />
    <path
      d="M216.386 96.678h-12.648v17.842h12.648V96.678ZM249.687 114.519h12.648V70.797h-12.648v43.722Z"
      fill="#AE7C01"
    />
    <path d="M239.361 85.038h-12.648v29.481h12.648v-29.48Z" fill="#EDB40D" />
    <path d="M272.661 114.519h12.648V48.03h-12.648v66.489Z" fill="#CE9200" />
    <path d="m210.285 83.945-.446-.814 22.853-12.74.446.814-22.853 12.74Z" fill="#C1BEAC" />
    <path d="m233.145 71.2-.46-.805 23.095-13.438.46.805-23.095 13.439Z" fill="#C1BEAC" />
    <path d="m256.361 57.663-.701-.606 22.083-25.958.7.605-22.082 25.959Z" fill="#C1BEAC" />
    <path
      d="M210.062 86.431c1.585 0 2.869-1.295 2.869-2.893 0-1.598-1.284-2.893-2.869-2.893-1.584 0-2.869 1.295-2.869 2.893 0 1.598 1.285 2.893 2.869 2.893Z"
      fill="#fff"
    />
    <path
      d="M210.062 86.896c-1.835 0-3.33-1.506-3.33-3.358s1.495-3.359 3.33-3.359c1.836 0 3.33 1.507 3.33 3.359s-1.494 3.358-3.33 3.358Zm0-5.786c-1.327 0-2.407 1.09-2.407 2.43 0 1.338 1.08 2.427 2.407 2.427s2.407-1.09 2.407-2.428c0-1.338-1.08-2.429-2.407-2.429Z"
      fill="#C1BEAC"
    />
    <path
      d="M235.797 70.797c0 1.606-1.29 2.908-2.882 2.908s-2.882-1.301-2.882-2.907c0-1.607 1.29-2.908 2.882-2.908s2.882 1.301 2.882 2.907Z"
      fill="#fff"
    />
    <path
      d="M232.915 74.17c-1.844 0-3.343-1.513-3.343-3.372 0-1.86 1.5-3.372 3.343-3.372s3.343 1.512 3.343 3.372c0 1.86-1.5 3.371-3.343 3.371Zm0-5.814c-1.335 0-2.421 1.095-2.421 2.442 0 1.346 1.086 2.442 2.421 2.442 1.335 0 2.42-1.096 2.42-2.442 0-1.347-1.086-2.442-2.42-2.442Z"
      fill="#C1BEAC"
    />
    <path
      d="M258.892 57.36c0 1.605-1.29 2.907-2.882 2.907s-2.882-1.3-2.882-2.907c0-1.606 1.29-2.907 2.882-2.907s2.882 1.3 2.882 2.907Z"
      fill="#fff"
    />
    <path
      d="M256.01 60.731c-1.844 0-3.343-1.512-3.343-3.371 0-1.86 1.5-3.372 3.343-3.372s3.343 1.512 3.343 3.372c0 1.86-1.5 3.371-3.343 3.371Zm0-5.813c-1.335 0-2.42 1.096-2.42 2.442s1.086 2.442 2.42 2.442c1.334 0 2.421-1.096 2.421-2.442s-1.086-2.442-2.421-2.442Z"
      fill="#C1BEAC"
    />
    <path
      d="M278.093 34.31c1.592 0 2.882-1.302 2.882-2.907 0-1.606-1.29-2.908-2.882-2.908s-2.882 1.302-2.882 2.908c0 1.605 1.29 2.907 2.882 2.907Z"
      fill="#fff"
    />
    <path
      d="M278.093 34.774c-1.844 0-3.343-1.512-3.343-3.372 0-1.86 1.5-3.371 3.343-3.371s3.343 1.512 3.343 3.371c0 1.86-1.5 3.372-3.343 3.372Zm0-5.814c-1.335 0-2.421 1.096-2.421 2.442 0 1.347 1.086 2.442 2.421 2.442 1.335 0 2.42-1.095 2.42-2.442 0-1.346-1.086-2.442-2.42-2.442Z"
      fill="#C1BEAC"
    />
    <path
      d="M32.02 19.188A9.046 9.046 0 1 0 19.228 6.396 9.046 9.046 0 0 0 32.02 19.188ZM34.64 41.26a9.045 9.045 0 1 0-9.762 8.268 9.045 9.045 0 0 0 9.762-8.267Z"
      fill="#CE9200"
    />
    <path
      d="M84.71 7.379H51.103c-.669 0-1.21.542-1.21 1.211v1.344c0 .669.541 1.211 1.21 1.211H84.71c.67 0 1.211-.542 1.211-1.211V8.59c0-.669-.542-1.211-1.21-1.211ZM120.356 7.379H94.263c-.669 0-1.211.542-1.211 1.211v1.344c0 .669.542 1.211 1.211 1.211h26.093c.669 0 1.212-.542 1.212-1.211V8.59c0-.669-.543-1.211-1.212-1.211ZM66.716 19.49h-14.57a1.889 1.889 0 0 1-1.884-1.883c0-1.036.847-1.883 1.883-1.883h14.57c1.037 0 1.884.847 1.884 1.883a1.889 1.889 0 0 1-1.883 1.883ZM101.905 19.49H77.002a1.889 1.889 0 0 1-1.883-1.883c0-1.036.847-1.883 1.883-1.883h24.903c1.036 0 1.883.847 1.883 1.883a1.888 1.888 0 0 1-1.883 1.883ZM71.07 38.222H51.775a1.889 1.889 0 0 1-1.883-1.883c0-1.036.847-1.883 1.883-1.883H71.07c1.036 0 1.884.847 1.884 1.883a1.889 1.889 0 0 1-1.883 1.883ZM111.992 38.222h-29.08a1.889 1.889 0 0 1-1.883-1.883c0-1.036.848-1.883 1.884-1.883h29.079c1.036 0 1.883.847 1.883 1.883a1.888 1.888 0 0 1-1.883 1.883ZM66.717 46.567h-14.57a1.889 1.889 0 0 1-1.884-1.883c0-1.036.848-1.883 1.883-1.883h14.57c1.037 0 1.884.847 1.884 1.883a1.889 1.889 0 0 1-1.883 1.883Z"
      fill="#fff"
    />
    <path
      d="M32.022 76.66A9.046 9.046 0 1 0 19.228 63.87 9.046 9.046 0 0 0 32.022 76.66Z"
      fill="#CE9200"
    />
    <path
      d="M71.07 67.975H51.775a1.889 1.889 0 0 1-1.883-1.884c0-1.036.847-1.883 1.883-1.883H71.07c1.036 0 1.884.847 1.884 1.883a1.889 1.889 0 0 1-1.883 1.884ZM111.992 67.975h-29.08a1.889 1.889 0 0 1-1.883-1.884c0-1.036.848-1.883 1.884-1.883h29.079c1.036 0 1.883.847 1.883 1.883a1.888 1.888 0 0 1-1.883 1.884ZM91.778 76.321H52.145a1.889 1.889 0 0 1-1.883-1.883c0-1.036.847-1.884 1.883-1.884h39.633c1.036 0 1.883.848 1.883 1.884a1.889 1.889 0 0 1-1.883 1.883ZM32.448 2.617a1.184 1.184 0 0 0-1.581.552l-5.808 12.045-4.056-4.588a1.184 1.184 0 1 0-1.775 1.57l6.417 7.257L33 4.2a1.184 1.184 0 0 0-.552-1.58l.001-.002ZM32.448 29.377a1.184 1.184 0 0 0-1.581.553l-5.808 12.045-4.056-4.588a1.184 1.184 0 1 0-1.775 1.57l6.417 7.257L33 30.96a1.184 1.184 0 0 0-.552-1.582h.001ZM32.448 59.617a1.184 1.184 0 0 0-1.581.552l-5.808 12.045-4.056-4.588a1.184 1.184 0 1 0-1.775 1.57l6.417 7.258L33 61.199a1.184 1.184 0 0 0-.552-1.581l.001-.001Z"
      fill="#fff"
    />
    <path d="M35.316 156.708H24.192v52.061h11.124v-52.061Z" fill="#EDB40D" />
    <path d="M35.316 164.779H24.192v43.99h11.124v-43.99Z" fill="#AE7C01" />
    <path d="M35.316 188.035H24.192v20.734h11.124v-20.734Z" fill="#CE9200" />
    <path d="M52.408 144.44H41.284v64.33h11.124v-64.33Z" fill="#EDB40D" />
    <path d="M52.408 172.458H41.284v36.311h11.124v-36.311Z" fill="#AE7C01" />
    <path d="M69.074 161.177H57.95v47.593h11.124v-47.593Z" fill="#EDB40D" />
    <path d="M69.074 179.746H57.95v29.024h11.124v-29.024Z" fill="#AE7C01" />
    <path
      d="M52.408 195.188H41.284v13.581h11.124v-13.581ZM69.074 197.645H57.95v11.125h11.124v-11.125Z"
      fill="#CE9200"
    />
    <path d="M87.228 163.208H76.103v45.562h11.125v-45.562Z" fill="#EDB40D" />
    <path d="M87.228 169.189H76.103v39.581h11.125v-39.581Z" fill="#AE7C01" />
    <path d="M87.228 179.746H76.103v29.024h11.125v-29.024Z" fill="#CE9200" />
  </svg>
);
export default SvgAiMarketer1;

import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';

const BigBackgroundSectionTitle = ({ children, style }) => {
  const { windowWidth } = useWindowSize();
  return windowWidth >= 1000 ? (
    <H3 style={{ ...style }} className="big-background-section-title">
      {children}
    </H3>
  ) : null;
};

const H3 = styled.h2`
  font-size: 12.5rem;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  text-transform: lowercase;
  ${({ style }) => style};
`;

export default BigBackgroundSectionTitle;

import * as React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Underline = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
    delay: 1000
  });
  return (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      viewBox="0 0 124 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}>
      <motion.path
        d="M2 5C34.354 2.736 103.65-.433 122 5"
        stroke={props.color}
        strokeWidth={props.width}
        strokeLinecap="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: inView ? 1 : 0 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
    </motion.svg>
  );
};

export default Underline;

import * as React from 'react';
const SvgAiPill = (props) => (
  <svg viewBox="0 0 124 251" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M123.261 61.637C123.261 27.597 95.667 0 61.631 0 27.593 0 0 27.597 0 61.637v59.994c0 .908.735 1.643 1.643 1.643h119.974c.908 0 1.644-.735 1.644-1.643V61.637Z"
      fill={props.primaryColor}
    />
    <path
      d="M0 129.027v59.994c0 34.04 27.594 61.637 61.63 61.637 34.037 0 61.631-27.597 61.631-61.637v-59.994c0-.908-.736-1.643-1.644-1.643H1.643c-.908 0-1.643.735-1.643 1.643Z"
      fill={props.secondaryColor}
    />
  </svg>
);
export default SvgAiPill;

import React from 'react';
import { Location } from '@reach/router';
import { PrivateRoute, Layout } from './src/components';
import { NetlifyIdentityProvider } from './src/context/NetlifyIdentityContext';

const transitionDelay = 375;

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};

export const wrapPageElement = ({ props }) => {
  const { component, page } = props.pageResources;
  const PageComponent = component.default;
  const pageIsPrivate = page.path.includes('/admin/');
  return (
    <Layout {...props}>
      {pageIsPrivate ? <PageComponent {...props} pageIsPrivate /> : <PageComponent {...props} />}
    </Layout>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <Location>
      {({ location }) =>
        location.pathname.includes('/admin/') ? (
          <NetlifyIdentityProvider>
            <PrivateRoute>{element}</PrivateRoute>
          </NetlifyIdentityProvider>
        ) : (
          element
        )
      }
    </Location>
  );
};

export const onClientEntry = () => {
  const { pathname } = window.location;

  if (pathname.includes('/admin')) {
    window.localStorage.setItem(
      'agencyXPrivateRoutePath',
      JSON.stringify({
        data: pathname
      })
    );
  }
};

import * as React from 'react';
const SvgAiWord = (props) => (
  <svg viewBox="0 0 149 137" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m29.626 52.804 14.453 84.033 22.744-13.131-2.456-10.492 26.587-15.35 7.858 7.373 22.744-13.131-65.552-54.531-26.381 15.23.003-.001Zm30.067 39.84-6.2-28.965 21.984 19.852-15.788 9.115.004-.002Zm30.157-70.04a12.08 12.08 0 0 1 4.433-16.548 12.082 12.082 0 0 1 16.546 4.435 12.083 12.083 0 0 1-4.433 16.547 12.082 12.082 0 0 1-16.546-4.435Zm10.198 15.026 18.695-10.793 28.966 50.17L129.014 87.8l-28.966-50.17Z"
      fill={props.color}
    />
  </svg>
);
export default SvgAiWord;

import * as React from 'react';
const SvgSlide8 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M104.667 169.5v-36c0-4.95 4.125-9 9.166-9H219.25c5.042 0 9.167 4.05 9.167 9V201c0 4.95-4.125 9-9.167 9H113.833c-5.041 0-9.166-4.05-9.166-9v-18"
      fill="#CE9200"
    />
    <path
      d="M104.667 169.5v-36c0-4.95 4.125-9 9.166-9H219.25c5.042 0 9.167 4.05 9.167 9V201c0 4.95-4.125 9-9.167 9H113.833c-5.041 0-9.166-4.05-9.166-9v-18M187.167 169.5l27.5 27"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="m118.417 138 36.666 36c5.592 4.23 17.325 4.23 22.917 0l36.667-36M145.917 169.5l-27.5 27M100.083 97.5l13.75 13.5M141.333 106.5v18M104.667 138H26.75c-5.042 0-9.167-4.05-9.167-9V61.5c0-4.95 4.125-9 9.167-9h105.417c5.041 0 9.166 4.05 9.166 9V93"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M31.333 66 68 102c5.592 4.23 17.325 4.23 22.917 0l36.666-36M58.833 97.5l-27.5 27M127.583 93H178M159.667 70.5h36.666M86.333 192H49.667M118.417 169.5H72.583M34 192h4M13 192h9.167M210 70.5h9.167M191.75 30h-77.917"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide8;

import * as React from 'react';
const SvgThreeBalls = (props) => (
  <svg viewBox="0 0 263 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32.826 32.463h196.848M33.087 64.729c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32Z"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path d="M32.774 39.41a7 7 0 1 0-7.001-7 7 7 0 0 0 7 7Z" fill="#F096F9" />
    <path
      d="M131.144 46.359c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Z"
      fill="#000"
    />
    <path d="M229.621 39.409a7 7 0 1 0-7-7 7 7 0 0 0 7 7Z" fill="#1E5FED" />
    <path
      d="M131.512 64.729c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32Z"
      stroke="#F096F9"
      strokeMiterlimit={10}
    />
    <path
      d="M229.936 64.729c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-17.674 0-32 14.327-32 32 0 17.673 14.326 32 32 32Z"
      stroke="#65E1FB"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgThreeBalls;

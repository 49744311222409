import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const AXTimeline = () => {
  const data = useStaticQuery(
    graphql`
      query {
        slide1: file(relativePath: { eq: "event-x/pre-event.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        slide2: file(relativePath: { eq: "event-x/post-event.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    `
  );

  const slides = [
    {
      id: 1,
      text: `Customised welcome cycle, including introductory and reminder emails, event registration, LinkedIn and other social posts.`,
      image: data.slide1
    },
    {
      id: 2,
      text: `Campaign and event analytics & reporting, highlight video reel,
      post event social posts.`,
      image: data.slide2
    }
  ];

  const { windowWidth } = useWindowSize();

  const [slide, setSlide] = useState(slides[0]);

  return (
    <Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <h2>Event Timeline</h2>
      <ToggleButtons>
        <ToggleButton selected={slide.id === 1} type="button" onClick={() => setSlide(slides[0])}>
          Pre Event
        </ToggleButton>
        <span>/</span>
        <ToggleButton selected={slide.id === 2} type="button" onClick={() => setSlide(slides[1])}>
          Post Event
        </ToggleButton>
      </ToggleButtons>
      <AnimatePresence initial={false} exitBeforeEnter>
        <Slide
          key={slide}
          id={slide}
          alt="slide"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}>
          <GatsbyImage image={getImage(slide.image)} alt="Slide Image" />
          <p>{slide.text}</p>
        </Slide>
      </AnimatePresence>
    </Container>
  );
};

const ToggleButton = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  color: ${({ selected }) => (selected ? 'var(--ax-pink)' : 'rgba(0,0,0,.25)')};
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25em;
  transition: 0.5s;
  width: auto;
  min-width: 80px;
  @media (min-width: 768px) {
    white-space: nowrap;
  }
`;

const ToggleButtons = styled.div`
  grid-column: 2/3;
  display: flex;
  max-width: 450px;
  margin: 2rem auto;
  justify-content: space-between;
  align-items: flex-start;
  span {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.25em;
    color: var(--ax-pink);
    margin: 0 1rem;
  }
`;

const Slide = styled(motion.div)`
  background: white;
  width: 100%;
  height: 100%;
  p {
    font-size: 1.25rem;
    line-height: 1.3em;
    margin: 3rem auto;
    text-align: center;
  }
  .gatsby-image-wrapper {
    width: 100%;
    max-height: 350px;
    img {
      object-fit: contain !important;
    }
  }
`;

const Container = styled.div`
  grid-column: 2/3;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 3rem 1rem;
  h2 {
    color: var(--ax-pink);
    font-size: 2rem;
  }
  @media (min-width: 48rem) {
    padding: 5rem 5rem;
    h2 {
      font-size: 2.5rem;
      text-transform: uppercase;
    }
  }
`;

export default AXTimeline;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { AiCross, AiBall } from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';
import { useInView } from 'react-intersection-observer';

const TextGraph = () => {
  const { windowWidth } = useWindowSize();
  const { ref, inView } = useInView({
    threshold: 0,
    delay: 250
  });
  return (
    <Container>
      <RenderWhenReady>
        {windowWidth > 768 && (
          <>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#47AD4A" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBall primaryColor="#E04527" secondaryColor="#CE9200" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#7A68CC" />
            </AnimatedSVG>
          </>
        )}
      </RenderWhenReady>
      <h2>Positive Ground-Up Sentiment</h2>
      <motion.div>
        <Columns>
          <p>
            In a 2023 survey by Wolters Kluwer about generative AI in Healthcare, though one in four
            American consumers (27%) reported feeling nervous about the technology, almost half
            (44%) were ready for gen AI to be used in healthcare.
          </p>
          <p>
            Specifically, respondents were open to gen AI being used in less invasive processes such
            as annual screenings/exams (54%), cancer screening (45%) and diagnosis of diseases
            (43%). Respondents were less accepting of gen AI being used in invasive procedures such
            as surgery (25%) and mental health support (31%).
          </p>
        </Columns>
        <div ref={ref} style={{ display: 'flex', flexDirection: 'column', gap: '2rem'}}>
          <div>
            <h3>Readiness for the use of Gen Ai in healthcare</h3>
            <p>Percentage of consumers per use case:</p>
          </div>
            <Columns
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0, duration: 1 }}>
              <Bars>
                <Analytic>
                  <div>
                    <p>54%</p>
                    <span>Part of an annual screening/exam</span>
                  </div>
                  <Bar color="#CE9200" percentage="54%" inView={inView} />
                </Analytic>
                <Analytic>
                  <div>
                    <p>45%</p>
                    <span>Cancer screening</span>
                  </div>
                  <Bar color="#DA629E" percentage="45%" inView={inView} />
                </Analytic>
                <Analytic>
                  <div>
                    <p>43%</p>
                    <span>Diagnosis of diseases</span>
                  </div>
                  <Bar color="#65E1FB" percentage="43%" inView={inView} />
                </Analytic>
                <Analytic>
                  <div>
                    <p>40%</p>
                    <span>Pain management</span>
                  </div>
                  <Bar color="#47AD4A" percentage="40%" inView={inView} />
                </Analytic>
              </Bars>
              <Bars>
                <Analytic>
                  <div>
                    <p>36%</p>
                    <span>Decisions on treatment</span>
                  </div>
                  <Bar color="#0F88C1" percentage="36%" inView={inView} />
                </Analytic>
                <Analytic>
                  <div>
                    <p>31%</p>
                    <span>Mental health support</span>
                  </div>
                  <Bar color="#E04527" percentage="31%" inView={inView} />
                </Analytic>
                <Analytic>
                  <div>
                    <p>25%</p>
                    <span>Surgery</span>
                  </div>
                  <Bar color="#7A68CC" percentage="25%" inView={inView} />
                </Analytic>
              </Bars>
            </Columns>
        </div>
      </motion.div>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  @keyframes rollLeft {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(-100px) rotate(-60deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;

  > div {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  h2 {
    color: var(--ax-gold);
    font-size: 2rem;
    grid-column: 2/3;
    margin-bottom: 2rem;
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: 25%;
      left: -140px;
      width: 80px;
      svg {
        transform: rotate(45deg);
      }
    }
    :nth-child(2) {
      top: 30%;
      right: 0;
      width: 170px;
      opacity: 0 !important;
      @media (min-width: 64rem) {
        svg {
          opacity: 1 !important;
          animation: rollLeft 3s 1s ease-in-out infinite alternate-reverse;
        }
      }
    }
    :nth-child(3) {
      top: 37.5%;
      right: 30px;
      width: 80px;
      @media (min-width: 64rem) {
        top: 35%;
        right: -100px;
      }
    }
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 3rem 0;
    > div {
      gap: 3rem;
    }
    h2 {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

const Columns = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (min-width: 48rem) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;

const Bars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Analytic = styled(motion.div)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  p {
    font-size: 2rem;
    font-weight: 700;
  }
  div:first-of-type {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const progress = (width) => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${width};
  }
`;

const Bar = styled(motion.div)`
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 60px;
  position: relative;

  &:before {
    content: '';
    background: ${({ color }) => color};
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    animation: ${({ inView, percentage }) => inView && progress(percentage)} 2s ease-in-out;
    animation-fill-mode: forwards;
  }
`;

export default TextGraph;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { LoadingSpinner, Link } from 'components';
import { getPublicationPrices, formatField, getPrices } from 'utils';
import customDropdownMenuStyles from './customDropdownMenuStyles';

const EstimatorApp = ({ publications }) => {
  const [campaignType, setCampaignType] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [audience, setAudience] = useState([]);
  const [mediaSelection, setMediaSelection] = useState([]);
  const [audienceOptions, setAudienceOptions] = useState([]);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [budget, setBudget] = useState(0);
  const [allResults, setAllResults] = useState([]);
  const [totalPublications, setTotalPublications] = useState([]);
  const [colors, setColors] = useState();
  const [message, setMessage] = useState({ type: '', message: '' });

  const formatCamelCaseField = (field) => {
    let formatttedField;
    if (field.charAt(0) === '_') {
      formatttedField = field
        .replace(/_/g, '')
        .replace(/([A-Z])/g, ' $1')
        .trim();
      if (formatttedField.includes(' Mo ')) {
        if (formatttedField.includes('Mpu')) {
          return formatttedField.replace(' Mo Mpu', ' Month MPU');
        }
        return formatttedField.replace(' Mo ', ' Month ');
      }

      if (formatttedField.includes('Mpu')) {
        return formatttedField.replace('Mpu', 'MPU');
      }

      return formatttedField;
    }
    formatttedField = field
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .trim();

    if (formatttedField.includes('Hp Cpm')) {
      return formatttedField.replace('Hp Cpm', 'HP CPM');
    }

    if (formatttedField.includes('lbd  Mpu Cpm')) {
      return formatttedField.replace('lbd  Mpu Cpm', 'LBD/MPU CPM');
    }

    return formatttedField;
  };

  const formatPriceField = (field) => {
    const symbol = '€';
    return field
      .replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
      .replace(/(\$?)(\d[\d,]+)/g, '$$$2')
      .replace('$', symbol)
      .replace('€€', symbol);
  };

  const clearFields = () => {
    setAudience([]);
    setAudienceOptions([]);
    setMediaSelection([]);
    setMediaOptions([]);
    setAllResults([]);
    setBudget(0);
    setTotalPublications([]);
    setMessage({ type: '', message: '' });
  };

  const hcpOnlyOptions = publications.filter((publication) => publication.node.hcpOnly === 'Y');
  const publicOptions = publications.filter((publication) => publication.node.hcpOnly === 'N');

  useEffect(() => {
    clearFields();
    if (campaignType?.value) {
      setCampaignData(campaignType.value === 'HCP Only' ? hcpOnlyOptions : publicOptions);
    }
  }, [campaignType]);

  useEffect(() => {
    const _audienceOptions = [];
    campaignData.forEach((publication) => {
      if (publication.node.audience) {
        const audiences = publication.node.audience.split(', ');
        audiences.forEach((type) => {
          const formattedAudienceType = formatField(type);
          if (!_audienceOptions.some((element) => element.value === formattedAudienceType)) {
            _audienceOptions.push({ label: formattedAudienceType, value: formattedAudienceType });
          }
          setAudienceOptions(_audienceOptions);
        });
      }
    });
  }, [campaignData]);

  useEffect(() => {
    const _mediaOptions = [];
    if (audience.length > 0) {
      const options = campaignData.filter((publication) => {
        let containsSelectedAudience = false;
        if (publication.node.audience) {
          const audiences = publication.node.audience.split(', ');
          audiences.forEach((type) => {
            const formattedAudienceType = formatField(type);
            if (audience.some((aud) => aud.value === formattedAudienceType)) {
              containsSelectedAudience = true;
            }
          });
        }
        return containsSelectedAudience;
      });
      setCampaignData(options);
      options.forEach((option) => {
        const { mediaType } = option.node;
        if (mediaType) {
          const formattedMediaType = formatField(mediaType);
          if (!_mediaOptions.some((element) => element.value === formattedMediaType)) {
            _mediaOptions.push({
              label: formattedMediaType,
              value: formattedMediaType
            });
          }
        }
      });
    }
    setMediaOptions(_mediaOptions);
  }, [audience]);

  const generateResultsWithFilters = () => {
    setLoading(true);
    setTimeout(() => {
      const _filteredArray = [];
      campaignData.forEach((publication) => {
        const { mediaType } = publication.node;
        if (mediaType) {
          if (mediaSelection.some((type) => formatField(mediaType) === type.value)) {
            const publicationPrices = getPublicationPrices(publication.node);
            if (
              publicationPrices.some((service) => {
                if (service) {
                  const price = parseInt(service.match(/\d/g).join(''), 10);
                  return price <= budget;
                }
                return false;
              })
            ) {
              _filteredArray.push(publication.node);
            } else if (publicationPrices.length < 1) {
              _filteredArray.push(publication.node);
            }
          }
        }
        return _filteredArray;
      });

      if (_filteredArray.length > 1) {
        setMessage({
          type: '',
          message: ''
        });
      } else if (_filteredArray.length === 1) {
        // eslint-disable-next-line prefer-destructuring
      } else if (_filteredArray.length === 0) {
        setTimeout(() => {
          setMessage({
            type: 'no-result',
            message:
              "Either we can't find a solution with these selections or we don't have enough data on these selections to make you suggestion."
          });
        }, 1000);
      }

      const _allResults = _filteredArray.sort((a, b) => {
        return a.nameOfPublication.localeCompare(b.nameOfPublication);
      });

      const removeDuplicates = (arr) => {
        const unique = arr.reduce((acc, curr) => {
          if (!acc.includes(curr.nameOfPublication)) {
            if (curr.nameOfPublication.match(/\((.+?)\)/g)) {
              if (!acc.includes(curr.nameOfPublication.split(/\s\((.+?)\)/g)[0])) {
                acc.push(curr.nameOfPublication.split(/\s\((.+?)\)/g)[0]);
              }
            } else {
              acc.push(curr.nameOfPublication);
            }
          }
          return acc;
        }, []);
        return unique;
      };
      setTotalPublications(removeDuplicates(_filteredArray));

      setAllResults(_allResults);
      setLoading(false);
    }, [3000]);
  };

  useEffect(() => {
    if (budget >= 5) {
      generateResultsWithFilters();
    } else {
      setAllResults([]);
      setLoading(false);
    }
  }, [budget, mediaSelection, audience]);

  useEffect(() => {
    if (document?.documentElement) {
      const computedStyle = getComputedStyle(document.documentElement);
      const _colors = {
        primary: computedStyle.getPropertyValue('--ax-gold'),
        secondary: computedStyle.getPropertyValue('--ax-white'),
        tertiary: computedStyle.getPropertyValue('--ax-gold')
      };
      setColors(_colors);
    }
  }, []);

  const handleBudgetChange = (event) => {
    event.persist();
    if (event.target.value === '') {
      setBudget(0);
    } else {
      setBudget(parseInt(event.target.value, 10));
    }
  };

  return (
    <AnimatePresence>
      {colors && (
        <Container id="estimator-app">
          <div>
            <Label>Campaign Type</Label>
            <ReactSelect
              value={campaignType}
              onChange={setCampaignType}
              placeholder="Campaign Type"
              styles={customDropdownMenuStyles(colors)}
              options={[
                { label: 'HCP Only', value: 'HCP Only' },
                { label: 'Public', value: 'Public' }
              ]}
            />
          </div>
          {campaignType && (
            <div>
              <Label>Audience</Label>
              <ReactSelect
                value={audience}
                isMulti
                autoFocus
                closeMenuOnSelect={false}
                captureMenuScroll
                onChange={setAudience}
                placeholder="Audience"
                styles={customDropdownMenuStyles(colors)}
                options={audienceOptions}
              />
            </div>
          )}
          {mediaOptions.length > 0 && (
            <div>
              <Label>Media Type</Label>
              <ReactSelect
                value={mediaSelection}
                isMulti
                autoFocus
                closeMenuOnSelect={false}
                captureMenuScroll
                onChange={setMediaSelection}
                placeholder="Media Type"
                styles={customDropdownMenuStyles(colors)}
                options={mediaOptions}
              />
            </div>
          )}
          {mediaSelection.length > 0 && (
            <div>
              <Label>Budget</Label>
              <StyledInput colors={colors} value={budget} onChange={handleBudgetChange} />
              <span>€</span>
            </div>
          )}
          <AnimatePresence>
            <Header
              colors={colors}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              {loading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem'
                  }}>
                  <LoadingSpinner />
                </div>
              ) : allResults.length > 0 ? (
                <h2>Results</h2>
              ) : message.type === 'no-result' ? (
                <NoResult>
                  <h2>No results</h2>
                  <p>{message.message}</p>
                </NoResult>
              ) : null}
              {!loading && totalPublications.length > 1 && (
                <PublicationsBar>
                  {totalPublications.map((publication) => (
                    <Link
                      href={publication.toLowerCase().replaceAll(' ', '-')}
                      offset={-94}
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        textTransform: 'none',
                        fontSize: '0.75rem',
                        padding: '0 0.75rem',
                        height: '3rem'
                      }}>
                      {publication}
                    </Link>
                  ))}
                </PublicationsBar>
              )}
            </Header>
            {!loading && allResults.length > 0 && (
              <Results>
                {allResults.map((r) => (
                  <Card
                    colors={colors}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Publisher
                      id={r.nameOfPublication
                        .split(/\s\((.+?)\)/g)[0]
                        .toLowerCase()
                        .replaceAll(' ', '-')}>
                      {r.associatedBody && <span>{r.associatedBody}</span>}
                      <h4>{r.nameOfPublication}</h4>
                      {r.contentDescription && <p>{r.contentDescription}</p>}
                    </Publisher>
                    <div>
                      <div>
                        <MainFields>
                          {r.audience && (
                            <Field>
                              <strong>Audience</strong>
                              <p>{r.audience}</p>
                            </Field>
                          )}
                          <Field>
                            <strong>Reach</strong>
                            {r.reach ? (
                              <p>{r.reach?.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')}</p>
                            ) : (
                              <p>Reach unavailable</p>
                            )}
                          </Field>
                          {r.mediaType && (
                            <Field>
                              <strong>Media Type</strong>
                              <p>{r.mediaType}</p>
                            </Field>
                          )}
                          {r.frequency && (
                            <Field>
                              <strong>Frequency</strong>
                              <p>{r.frequency}</p>
                            </Field>
                          )}
                          <Field>
                            <strong>Prices</strong>
                            {getPrices(r).length > 0 ? (
                              <>
                                {getPrices(r).map((price) => {
                                  return (
                                    <p
                                      style={{
                                        textTransform: 'capitalize'
                                      }}>{`${formatCamelCaseField(price[0])} - ${formatPriceField(
                                      price[1]
                                    )}`}</p>
                                  );
                                })}
                                <br />
                                <span>
                                  Prices indicated are guide estimates only,
                                  <br />
                                  to be confirmed at time of order
                                </span>
                              </>
                            ) : (
                              <p>
                                Pricing unavailable
                                <br />
                                <small style={{ color: 'var(--ax-gold)', fontWeight: '700' }}>
                                  Please contact us for more information
                                </small>
                              </p>
                            )}
                          </Field>
                        </MainFields>
                      </div>
                    </div>
                  </Card>
                ))}
              </Results>
            )}
          </AnimatePresence>
        </Container>
      )}
    </AnimatePresence>
  );
};

export const Label = styled.label`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 19px;
  margin-bottom: 0.375rem;
  overflow: hidden;
  width: 100%;
`;

const StyledInput = styled.input`
  color: ${({ colors }) => colors.primary};
  width: 100%;
  height: 2.5rem;
  background: transparent;
  border: ${({ colors }) => `1px solid ${colors.primary}`};
  border-radius: 2px;
  outline: none;
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.5rem;
  letter-spacing: 0.036em;
  padding: 0.5rem;
  padding-left: 1.571em;
  margin-bottom: 12px;
  &:focus {
    border: ${({ colors }) => `0.143em solid ${colors.primary}`};
  }
`;

const Publisher = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.0185em;
  position: relative;
  margin-bottom: 0.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2rem;
  }
  span {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 2rem;
    position: relative !important;
    top: unset !important;
    left: unset !important;
  }
  p {
    font-size: 0.875rem;
    letter-spacing: 0.001em;
    line-height: 1.25rem;
    width: auto;
    max-width: 560px;
  }
  @media screen and (min-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

const Field = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: unset !important;
  strong {
    font-size: 1rem;
    font-weight: 900;
    text-transform: lowercase;
    margin-bottom: 0.65rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  span {
    position: relative !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
    top: unset !important;
    left: unset !important;
  }
`;

const MainFields = styled.div`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0;
  width: 100%;
  div {
    max-width: 260px;
  }
  @media screen and (min-width: 720px) {
    flex-direction: row !important;
  }
`;

const Results = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 0 0.5rem;
  position: relative;
  h3 {
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.0185em;
    position: absolute;
    top: 2rem;
    left: 1.5rem;
  }
  @media screen and (min-width: 800px) {
    padding: 0;
  }
`;

const Card = styled(motion.div)`
  border-bottom: 1px solid #000000;
  width: 100%;
  height: auto;
  padding: 2rem 0 1rem 0;
  max-width: 1579px;
  margin: 0 auto;
  width: 100%;
  a {
    color: #000000;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    strong {
      font-size: 1rem;
      font-weight: 900;
      text-transform: lowercase;
    }
  }
  svg {
    margin: 0 auto;
  }
  div:not(:first-of-type) {
    gap: 0rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.65em;
    }
  }
  @media screen and (min-width: 720px) {
    padding: 2rem 0 1rem 0;
    div:not(:first-of-type) {
      div {
        div:last-of-type {
          margin-left: auto;
        }
        div:nth-of-type(3) {
          margin: 0 auto;
        }
      }
    }
  }
`;

const Header = styled(motion.div)`
  max-width: 1579px;
  margin: 0 auto;
  width: 100%;
  h2 {
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.0185em;
    margin: 1.25em 0;
  }
`;

const Container = styled(motion.section).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  background-color: var(--ax-beige);
  padding: 1.875rem 0;
  height: 100%;
  margin: 0 auto 3rem auto;
  max-width: 1450px;
  > div {
    grid-column: 2 / 3;
    position: relative;
    span {
      position: absolute;
      top: 2.075em;
      left: 0.571em;
      color: var(--ax-gold);
    }
    @media screen and (min-width: 114.75rem) {
      max-width: 108.75rem;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const PublicationsBar = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #ffffff;
  gap: 1rem;
`;

const NoResult = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: -1rem;
  }
`;

export default EstimatorApp;

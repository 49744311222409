import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Underline } from 'components';
import { wrap } from '@popmotion/popcorn';
import { useWindowSize } from 'hooks';
import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  Slide9,
  Slide10
} from 'images/omnichannel';
import Arrow from './Arrow';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const AXCarousel = () => {
  const { windowWidth } = useWindowSize();

  const slides = [
    {
      id: 1,
      title: 'Website & Mobile App',
      text: "Create a dedicated, informative, and user-friendly website for the illness. Use patient stories supported by HCP advice for diagnosis and support. Consider using video storying telling to help simplify complex medical terminology and build patient understanding. Video content can work very well for quickly sharing supportive information. Include a section for HCPs, offering resources such as research data, treatment guidelines, and downloadable materials for their patients.<br /><br />Consider a mobile app for patients and HCPs. The app could offer features like medication reminders, blood pressure tracking, lifestyle tips, and access to educational resources. Additionally, the app can provide HCPs with a platform to monitor their patients' progress and communicate with them securely."
    },
    {
      id: 2,
      title: 'Social Media',
      text: "Leverage social media platforms to engage with patients and HCPs taking care to follow the IPHA Code. Support the relevant PAGs to share educational content and upcoming events."
    },
    {
      id: 3,
      title: 'Digital Advertising',
      text: 'For focused messaging to patients (main digital channels) and HCPs – professional networking and educational channels, digital advertising and messaging can offer clear returning on investment analytics.'
    },
    {
      id: 4,
      title: 'Email Marketing',
      text: 'Develop a permissions based targeted email campaign for HCPs. To be used sparingly for updating on events, offering updates on clinical research, treatment guidelines, and resources to support patient education.'
    },
    {
      id: 5,
      title: 'Events & Webinars<br />— Virtual, Hybrid or In-person',
      text: 'Of increasing importance for time-poor HCPs, virtual events offer access to concise learning sessions with KOLs. Recording the content for post event sharing is a great way to maximise the impact of the event and to enable the content be shared beyond the immediate attendees. Consider (CPD/CME) credits to encourage participation.<br /><br />In-person events and conferences can be an excellent opportunity for building relationships, presenting research and providing the latest information in print and digital formats.'
    },
    {
      id: 6,
      title: 'In-Pharmacy &<br />In-Surgery Promotion',
      text: 'Provide pharmacists with resources to educate patients about the disease, to highlight possible drug interactions, possible additional disease awareness symptoms and provide patient support materials.<br /><br />Options are growing for providing patient materials in GP waiting rooms, either as posters, leaflets or as digital advertising. Worth considering for geo located campaigns and in particular pilot campaigns.'
    },
    {
      id: 7,
      title: 'Patient Support Program',
      text: 'Establish a patient support program, offering personalized assistance with medication management, and access to resources for lifestyle modification.'
    },
    {
      id: 8,
      title: 'Direct Mailing',
      text: 'Become more common as a cut through medium for HCPs - consider a well-designed DM campaign that can geo-target a region or focus on a refined cohort of HCPs with highly targeted specific information.'
    },
    {
      id: 9,
      title: 'Traditional Media',
      text: 'From trade press to local radio advertising, a support campaign of traditional media can be very effective for disease awareness. However it is more difficult to gauge return on investment statistics from traditional media as they are based on broader distribution/audience numbers, and not necessarily direct engagement. Its best to ask HCPs where they are now getting their information, what they read, what the listen to, what tools they use, what conferences they attend.'
    },
    {
      id: 10,
      title: 'Analytics and Attribution',
      text: 'What does success look like and how are we going to measure it? Optimising should be a primary consideration on every Omnichannel campaign. Putting the system in place at the start leads to the greatest performance on-going. An Omnichannel campaign learns from itself and runs long beyond its initial starting date, some in fact for the life of the brand, so start as you mean to go on! Bitesize and agile in method.'
    }
  ];

  const [[page, direction], setPage] = useState([10, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const selectPage = (selectedPage) => {
    setPage([selectedPage, 1]);
  };

  const index = wrap(0, slides.length, page);

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 15000);
    return () => clearTimeout(timer);
  }, [page]);

  return (
    <>
      <ModulesTitle>
        <span>
          <span>Considerations</span>
          <Underline color="#EB5A30" width={2.5} />
        </span>{' '}
        for
        <br />
        an Omnichannel campaign
      </ModulesTitle>
      <NumberContainer
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.25 }}>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(0);
          }}
          selected={slides[index].id === 1}>
          <span>01</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(1);
          }}
          selected={slides[index].id === 2}>
          <span>02</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(2);
          }}
          selected={slides[index].id === 3}>
          <span>03</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(3);
          }}
          selected={slides[index].id === 4}>
          <span>04</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(4);
          }}
          selected={slides[index].id === 5}>
          <span>05</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(5);
          }}
          selected={slides[index].id === 6}>
          <span>06</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(6);
          }}
          selected={slides[index].id === 7}>
          <span>07</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(7);
          }}
          selected={slides[index].id === 8}>
          <span>08</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(8);
          }}
          selected={slides[index].id === 9}>
          <span>09</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(9);
          }}
          selected={slides[index].id === 10}>
          <span>10</span>
        </NumberBox>
        <Prev onClick={() => paginate(-1)} disabled={page === 0} />
        <Next onClick={() => paginate(1)} disabled={page === 9} id="omnichannel-carousel-click" />
      </NumberContainer>
      <Container
        key={page}
        id={slides[index]}
        alt="slide"
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          duration: 0.5
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}>
        <AnimatePresence initial={false} custom={direction}>
          <Slide>
            <SVGContainer>
              {slides[index].id === 1 ? (
                <Slide1 />
              ) : slides[index].id === 2 ? (
                <Slide2 />
              ) : slides[index].id === 3 ? (
                <Slide3 />
              ) : slides[index].id === 4 ? (
                <Slide4 />
              ) : slides[index].id === 5 ? (
                <Slide5 />
              ) : slides[index].id === 6 ? (
                <Slide6 />
              ) : slides[index].id === 7 ? (
                <Slide7 />
              ) : slides[index].id === 8 ? (
                <Slide8 />
              ) : slides[index].id === 9 ? (
                <Slide9 />
              ) : (
                <Slide10 />
              )}
            </SVGContainer>
            <TextContainer>
              <h5>{slides[index].id === 10 ? 10 : `0${slides[index].id}`}</h5>
              <h3 dangerouslySetInnerHTML={{__html: windowWidth < 1200 ? slides[index].title.replace('<br />', ' ') : slides[index].title}} style={{ whiteSpace: windowWidth >= 1200 && [5, 6, 7, 10].includes(slides[index].id) ? 'noWrap' : 'normal' }} />
              <p dangerouslySetInnerHTML={{ __html: slides[index].text }} />
            </TextContainer>
          </Slide>
        </AnimatePresence>
      </Container>
    </>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const ModulesTitle = styled.h3`
  justify-self: center;
  color: black;
  font-size: 2rem;
  line-height: 1.25em;
  margin-bottom: 1em;
  letter-spacing: 0px;
  grid-column: 2/3;
  text-align: center;

  span {
    position: relative;
    z-index: 1;

    svg {
      position: absolute;
      bottom: -0.125em;
      left: 0;
      width: 100%;
      z-index: 0;
    }
  }

  @media (min-width: 48em) {
    font-size: 3rem;
  }
`;

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  grid-column-gap: 1.25rem;
  grid-column: 1/6;
  grid-row-gap: 2.5rem;
  height: auto;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden !important;
  position: relative;

  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Slide = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 3rem;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: 150px 1fr;
    grid-row-gap: 1.5rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 250px 1fr;
  }
`;

const SVGContainer = styled.div`
  text-align: center;
  width: auto;
  svg {
    width: 120px;
    max-width: 100%;
    height: auto;
    @media (min-width: 1150px) {
      width: 220px;
    }
  }
`;

const TextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  h3 {
    color: black;
    font-size: 2.5rem;
    letter-spacing: 0px;
    line-height: 1em;
    margin: 0 auto 0.5em;
    text-align: center;
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 3rem;
      text-align: left;
      width: 100%;
    }
  }

  h5 {
    width: 100%;
    font-size: 2rem;
    line-height: 1.5em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--ax-gold);
    text-transform: uppercase;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 2rem;
      text-align: left;
    }
  }
  p {
    color: black;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5em;
    @media (min-width: 768px) {
      text-align: left;
    }
  }

  @media screen and (min-width: 48rem) {
    align-items: flex-start;
  }
`;

const Prev = styled(Arrow)`
  display: none;
  fill: ${({ disabled }) => (disabled ? 'grey' : '#CE9200')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media (min-width: 48rem) {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    display: block;
    left: 0;
    position: absolute;
    top: 45%;
    transform: rotate(180deg);
    width: 2.563em;
  }

  @media (min-width: 65.625rem) {
    left: 8rem;
  }

  @media screen and (min-width: 71.875rem) {
    left: -4.5rem;
    top: 37%;
  }
`;

const Next = styled(Arrow)`
  display: none;
  fill: ${({ disabled }) => (disabled ? 'grey' : '#CE9200')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media (min-width: 48rem) {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    display: block;
    position: absolute;
    right: 0;
    top: 45%;
    width: 2.563em;
  }

  @media (min-width: 65.625rem) {
    right: 8rem;
  }

  @media screen and (min-width: 71.875rem) {
    top: 37%;
    right: -4.5rem;
  }
`;

const NumberContainer = styled.div`
  display: grid;
  grid-column: 1/6;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(5, minmax(40px, 100px));
  justify-content: center;
  margin-bottom: 2rem;
  margin: 0 auto 2.25em;
  max-width: 940px;
  position: relative;
  transition: 0.75s;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 4rem;
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(10, minmax(40px, 100px));
    grid-column: 3/11;
  }
`;

const NumberBox = styled.button`
  background: ${({ selected }) => (selected ? 'var(--ax-gold) ' : 'var(--ex-beige)')};
  border: 1px solid var(--ax-gold);
  cursor: pointer;
  font-weight: bold;
  height: 0;
  outline: none;
  padding-top: 100%;
  position: relative;
  transition: 0.75s;
  width: 100%;

  :hover,
  :focus {
    border: 1px solid var(--ax-gold);
    outline: none;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: ${({ selected }) => (selected ? 'white' : '#C1BEAC')};
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export default AXCarousel;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AiWave1, AiWave2, AiWave3, AiCross, AiBall, AiPill } from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const WaveBlocks = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <RenderWhenReady>
        {windowWidth > 768 && (
          <>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#7A68CC" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#7A68CC" secondaryColor="#EDB40D" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#CE9200" secondaryColor="#E04527" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBall primaryColor="#0F88C1" secondaryColor="#DA629E" />
            </AnimatedSVG>
          </>
        )}
      </RenderWhenReady>
      <h2>Catching the Ai Wave</h2>
      <motion.div>
        <Block>
          <SVGContainer>
            <AiWave1 />
          </SVGContainer>
          <div>
            <h3>EU Legislative Support</h3>
            The recently drafted AI Act in December 2023 by the European Union ensures that AI
            systems used within Europe adhere to transparency requirements, ethical standards (such
            as disclosing any content generated by AI), are traceable, safe, environmentally
            friendly and non-discriminatory.
          </div>
        </Block>
        <Block>
          <SVGContainer>
            <AiWave2 />
          </SVGContainer>
          <div>
            <h3>Big Tech involvement</h3>
            Tech companies have an open interest in applying gen AI technology in the healthcare
            industry. Most recently, an example is Mayo Clinic&apos;s partnership with Microsoft,
            where the Microsoft 365 Co-pilot is being tested with clinical staff, doctors and
            healthcare workers, hoping to reduce the time spent on monotonous tasks to improve
            patient care. Google Cloud and Accenture formed a partnership to explore possible
            solutions using Google&apos;s large language models (LLMs). These include improving
            operational efficiency, patient engagement, caregiver support, research and development.
          </div>
        </Block>
        <Block>
          <SVGContainer>
            <AiWave3 />
          </SVGContainer>
          <div>
            <h3> Government Support</h3>
            Determined not to be left behind in the AI revolution, governments all around the world
            are heavily investing in, increasing regulation and implementing the use of generative
            AI for their healthcare systems with varying speeds and approaches. The WHO reports that
            up to 70 countries have agreed to step up investments in primary health care by 2030 to
            improve access to healthcare and make services more affordable.
          </div>
        </Block>
      </motion.div>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  grid-template-columns: 1fr !important;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  h2 {
    color: var(--ax-gold);
    font-size: 2rem;
    grid-column: 2/3;
    margin-bottom: 2rem;
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: -20px;
      left: 0;
      width: 80px;
      svg {
        animation: spin 3s 1s ease-in-out infinite alternate-reverse;
      }
      @media (min-width: 64rem) {
        left: -50px;
      }
    }
    :nth-child(2) {
      top: 40%;
      left: -190px;
      width: 120px;
    }
    :nth-child(3) {
      top: -100px;
      right: -120px;
      width: 120px;
      svg {
        transform: rotate(-30deg);
      }
      @media (min-width: 64rem) {
        top: 0;
        right: -180px;
      }
    }
    :nth-child(4) {
      bottom: -20px;
      right: -180px;
      width: 170px;
      opacity: 0 !important;
      @media (min-width: 64rem) {
        opacity: 1 !important;
      }
    }
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 3rem 0;
    h2 {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

const Block = styled.div`
  align-items: center;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1.25rem;
  svg {
    width: 254px;
  }
  @media (min-width: 64rem) {
    flex-direction: row;
    padding: 1.25rem 4rem;
    gap: 5rem;
  }
`;

const SVGContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default WaveBlocks;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Columns = ({ columns }) => {
  return (
    <Container>
      <motion.div>
      {columns.map((column, index) => (
        <div key={index}>
          <h3>{column.title}</h3>
          <p>
            {column.text}
          </p>
        </div>
      ))}
      </motion.div>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;

  > div {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  @media (min-width: 48rem) {
    padding: 0 0 6rem 0;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }
`;

export default Columns;

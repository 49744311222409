import * as React from 'react';
const SvgAiMarketer3 = (props) => (
  <svg viewBox="0 0 243 201" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m43.09 60.713-2.687-2.686c.77-1.37 1.493-2.979 1.908-4.523l3.827-.03a2.31 2.31 0 0 0 2.292-2.291l.008-5.25a2.285 2.285 0 0 0-2.273-2.28L42.32 43.6c-.415-1.544-1.126-3.142-1.894-4.513l2.685-2.686c.886-.885.757-2.01-.129-2.897l-3.706-3.707c-.886-.886-2.292-1.295-3.178-.41l-2.685 2.687a18.896 18.896 0 0 0-4.371-1.757l.045-3.753a2.245 2.245 0 0 0-2.253-2.254l-5.23.012a2.284 2.284 0 0 0-2.272 2.282l-.057 3.86c-1.544.416-3.149 1.143-4.519 1.912L12.07 29.69c-.885-.886-2.41-1.2-3.296-.315L5.07 33.082c-.886.885-.896 2.735-.01 3.62l2.685 2.686c-.769 1.37-1.33 2.816-1.745 4.36l-3.747-.05A2.24 2.24 0 0 0 0 45.949l.013 5.23a2.284 2.284 0 0 0 2.283 2.27l3.71-.094a18.88 18.88 0 0 0 1.76 4.368L5.08 60.409c-.885.885-.92 2.734-.034 3.62l3.707 3.707c.886.885 2.455.572 3.34-.315l2.686-2.686c1.37.77 2.967 1.482 4.51 1.897l.025 3.823a2.304 2.304 0 0 0 2.289 2.288l5.247.007a2.285 2.285 0 0 0 2.28-2.274l-.1-3.695c1.544-.415 2.99-.975 4.361-1.744l2.686 2.686c.886.886 2.336.477 3.222-.409l3.706-3.706c.885-.885.97-2.011.084-2.897v.002Zm-26.413-4.605c-4.175-4.176-4.175-10.946 0-15.12 4.175-4.176 10.945-4.176 15.12 0 4.174 4.174 4.174 10.944 0 15.12-4.175 4.175-10.945 4.175-15.12 0Z"
      fill="#0F88C1"
    />
    <path
      d="m67.376 23.922-1.765-1.765c.506-.9.981-1.957 1.254-2.972l2.516-.02c.823 0 1.506-.683 1.506-1.506l.005-3.45a1.5 1.5 0 0 0-1.495-1.498l-2.526-.033c-.274-1.015-.74-2.066-1.246-2.966l1.765-1.764c.582-.583.497-1.322-.085-1.905l-2.436-2.436c-.582-.581-1.506-.85-2.087-.269l-1.765 1.765c-.9-.505-1.858-.881-2.872-1.155l.03-2.465c0-.824-.66-1.483-1.482-1.483l-3.437.007a1.5 1.5 0 0 0-1.492 1.501l-.037 2.538c-1.015.273-2.07.75-2.97 1.256l-1.764-1.764c-.581-.582-1.584-.788-2.166-.207l-2.436 2.436c-.582.583-.588 1.797-.006 2.379l1.764 1.764c-.505.9-.873 1.85-1.147 2.865l-2.462-.034c-.823 0-1.48.657-1.48 1.48l.009 3.437c0 .823.678 1.492 1.5 1.492l2.439-.062c.273 1.014.651 1.97 1.156 2.87L42.4 23.723c-.583.582-.605 1.797-.022 2.378l2.435 2.436c.583.583 1.613.376 2.196-.206l1.764-1.765c.9.506 1.95.974 2.965 1.247l.016 2.512c0 .823.68 1.504 1.503 1.504l3.449.004c.823 0 1.498-.671 1.498-1.494l-.066-2.428a12.364 12.364 0 0 0 2.865-1.146l1.765 1.765c.581.581 1.534.313 2.117-.27l2.436-2.435c.582-.583.637-1.322.055-1.904Zm-17.358-3.027a7.026 7.026 0 1 1 9.936-9.937 7.026 7.026 0 0 1-9.936 9.937Z"
      fill="#8EB7E8"
    />
    <path
      d="m77.99 51.389-1.124-1.125c.322-.574.625-1.246.799-1.894l1.602-.012c.524 0 .96-.435.96-.96l.002-2.198a.957.957 0 0 0-.952-.955l-1.61-.021a8.535 8.535 0 0 0-.793-1.89L78 41.21c.37-.371.317-.842-.054-1.214l-1.551-1.552c-.371-.37-.96-.542-1.33-.171l-1.125 1.124a7.971 7.971 0 0 0-1.83-.736l.019-1.571a.94.94 0 0 0-.944-.944l-2.19.005a.956.956 0 0 0-.951.957l-.024 1.616c-.645.174-1.318.478-1.892.8l-1.125-1.125c-.37-.37-1.009-.502-1.38-.132l-1.551 1.552c-.37.37-.375 1.145-.005 1.516l1.125 1.124a7.852 7.852 0 0 0-.73 1.826l-1.569-.02a.938.938 0 0 0-.943.942l.006 2.19c0 .523.432.95.956.95l1.554-.039c.173.646.415 1.255.737 1.83l-1.125 1.124c-.37.37-.385 1.145-.015 1.516l1.552 1.551c.37.37 1.028.24 1.399-.131l1.125-1.125a8.52 8.52 0 0 0 1.889.794l.01 1.6c0 .525.435.96.959.96l2.198.001a.955.955 0 0 0 .954-.952l-.043-1.547a7.854 7.854 0 0 0 1.826-.731l1.125 1.125c.37.37.979.2 1.35-.172l1.55-1.551c.371-.371.407-.842.036-1.214l-.001.003ZM66.93 49.46a4.477 4.477 0 1 1 6.332-6.331 4.477 4.477 0 0 1-6.331 6.331Z"
      fill="#A0C8EA"
    />
    <path
      d="M175.423 15.494c-23.524 0-42.663 19.14-42.663 42.665 0 13.218 5.978 25.474 16.4 33.624l13.69-17.508c-4.998-3.909-7.865-9.782-7.865-16.116 0-11.27 9.168-20.44 20.438-20.44 11.269 0 20.438 9.17 20.438 20.44 0 2.01-.288 3.99-.857 5.884a20.305 20.305 0 0 1-2.11 4.74l-5.529-4.49-5.482 34.652 19.562-7.452.232.297a44.6 44.6 0 0 0 .912-.733l12.077-4.601-4.437-3.601a42.492 42.492 0 0 0 6.062-12.424 42.672 42.672 0 0 0 1.794-12.272c0-23.526-19.138-42.665-42.662-42.665Z"
      fill="#0F88C1"
    />
    <path
      d="M117.014 56.123c-37.047 0-67.188 30.143-67.188 67.193 0 5.218.403 9.6 1.381 13.808l-11.215 5.66 35.917 23.456 2.471-42.829-6.342 3.201c.003-1.037.013-2.129.013-3.297 0-24.795 20.17-44.967 44.963-44.967s44.964 20.172 44.964 44.967c0 24.794-20.171 44.966-44.964 44.966v22.226c37.047 0 67.188-30.143 67.188-67.192 0-37.05-30.141-67.193-67.188-67.193v.001Z"
      fill="#0F88C1"
    />
    <path
      d="m242.978 179.481-37.132-21.483.005 10.388H80.141v22.226h125.722l.005 10.388 37.11-21.519Z"
      fill="#0F88C1"
    />
    <path
      d="M98.203 160.695a2.132 2.132 0 0 1-1.362-2.679l2.869-8.808a2.13 2.13 0 0 1 2.678-1.362 2.131 2.131 0 0 1 1.362 2.679l-2.869 8.808a2.13 2.13 0 0 1-2.678 1.362ZM135.377 160.695a2.132 2.132 0 0 0 1.362-2.679l-2.869-8.808a2.13 2.13 0 0 0-2.678-1.362 2.132 2.132 0 0 0-1.362 2.679l2.869 8.808a2.13 2.13 0 0 0 2.678 1.362Z"
      fill="#000"
    />
    <path
      d="M116.79 155.161c17.235 0 31.207-13.973 31.207-31.21 0-17.236-13.972-31.21-31.207-31.21-17.236 0-31.208 13.974-31.208 31.21 0 17.237 13.972 31.21 31.208 31.21Z"
      fill="#0F88C1"
    />
    <path
      d="M116.789 151.828a27.784 27.784 0 0 1-19.71-8.164 27.782 27.782 0 0 1-8.164-19.712c0-3.764.736-7.415 2.189-10.85a27.796 27.796 0 0 1 5.975-8.861 27.777 27.777 0 0 1 19.71-8.165c3.764 0 7.415.736 10.85 2.189a27.797 27.797 0 0 1 8.861 5.976 27.77 27.77 0 0 1 8.164 19.711 27.791 27.791 0 0 1-8.164 19.712 27.77 27.77 0 0 1-19.711 8.164Z"
      fill="#F8F7FE"
    />
    <path
      d="M116.79 148.497a24.39 24.39 0 0 1-9.552-1.927 24.474 24.474 0 0 1-7.803-5.263 24.464 24.464 0 0 1-7.19-17.356c0-3.317.649-6.53 1.927-9.552a24.476 24.476 0 0 1 5.263-7.804 24.474 24.474 0 0 1 7.803-5.263 24.386 24.386 0 0 1 9.552-1.926c3.317 0 6.53.648 9.551 1.926a24.475 24.475 0 0 1 7.804 5.263 24.489 24.489 0 0 1 5.262 7.804 24.387 24.387 0 0 1 1.927 9.552c0 3.317-.649 6.531-1.927 9.552a24.478 24.478 0 0 1-5.262 7.804 24.475 24.475 0 0 1-7.804 5.263 24.378 24.378 0 0 1-9.551 1.927Z"
      fill="#0F88C1"
    />
    <path
      d="M116.79 145.165a21.08 21.08 0 0 1-8.254-1.663 21.152 21.152 0 0 1-6.745-4.55 21.152 21.152 0 0 1-4.55-6.746 21.07 21.07 0 0 1-1.662-8.254c0-2.868.559-5.645 1.662-8.254a21.159 21.159 0 0 1 4.55-6.745 21.12 21.12 0 0 1 14.999-6.213c2.868 0 5.644.559 8.253 1.663a21.136 21.136 0 0 1 6.745 4.55 21.163 21.163 0 0 1 4.55 6.745 21.08 21.08 0 0 1 1.663 8.254c0 2.868-.56 5.645-1.663 8.254a21.157 21.157 0 0 1-4.55 6.746 21.152 21.152 0 0 1-6.745 4.55 21.075 21.075 0 0 1-8.253 1.663Z"
      fill="#F8F7FE"
    />
    <path
      d="M116.79 141.833c-2.42 0-4.76-.472-6.956-1.401a17.822 17.822 0 0 1-5.687-3.837 17.828 17.828 0 0 1-3.837-5.687 17.753 17.753 0 0 1-1.4-6.957c0-2.42.47-4.76 1.4-6.956a17.838 17.838 0 0 1 3.837-5.688 17.8 17.8 0 0 1 5.687-3.837 17.75 17.75 0 0 1 6.956-1.4c2.42 0 4.759.471 6.956 1.4 2.127.9 4.041 2.191 5.687 3.837a17.816 17.816 0 0 1 3.837 5.688 17.75 17.75 0 0 1 1.4 6.956c0 2.42-.471 4.76-1.4 6.957a17.845 17.845 0 0 1-3.837 5.687 17.823 17.823 0 0 1-5.687 3.837 17.754 17.754 0 0 1-6.956 1.401Z"
      fill="#0F88C1"
    />
    <path
      d="M116.79 138.5a14.443 14.443 0 0 1-10.287-4.26 14.45 14.45 0 0 1-4.261-10.288 14.447 14.447 0 0 1 4.261-10.287 14.443 14.443 0 0 1 10.287-4.262 14.441 14.441 0 0 1 10.286 4.261 14.504 14.504 0 0 1 3.124 4.629 14.43 14.43 0 0 1 1.137 5.658 14.43 14.43 0 0 1-1.137 5.658 14.498 14.498 0 0 1-3.124 4.629 14.445 14.445 0 0 1-10.286 4.261v.001Z"
      fill="#F8F7FE"
    />
    <path
      d="M116.79 135.169c-1.523 0-2.99-.294-4.359-.873a11.198 11.198 0 0 1-3.572-2.412 11.19 11.19 0 0 1-2.411-3.571 11.141 11.141 0 0 1-.874-4.36c0-1.523.294-2.99.873-4.36a11.218 11.218 0 0 1 2.411-3.571 11.217 11.217 0 0 1 3.572-2.413 11.128 11.128 0 0 1 4.359-.873 11.124 11.124 0 0 1 7.931 3.286 11.224 11.224 0 0 1 2.413 3.571c.579 1.37.872 2.837.872 4.36 0 1.523-.293 2.989-.872 4.359a11.221 11.221 0 0 1-2.412 3.571 11.188 11.188 0 0 1-3.571 2.412 11.141 11.141 0 0 1-4.36.874Z"
      fill="#0F88C1"
    />
    <path
      d="M116.79 131.836a7.81 7.81 0 0 1-3.062-.61 7.902 7.902 0 0 1-2.513-1.699 7.859 7.859 0 0 1-1.699-2.513 7.814 7.814 0 0 1-.61-3.062c0-1.074.205-2.104.61-3.062a7.902 7.902 0 0 1 1.699-2.513 7.889 7.889 0 0 1 2.513-1.7 7.81 7.81 0 0 1 3.062-.61c1.075 0 2.104.205 3.061.61a7.895 7.895 0 0 1 2.514 1.699 7.902 7.902 0 0 1 1.699 2.513 7.81 7.81 0 0 1 .61 3.062 7.81 7.81 0 0 1-.61 3.062 7.905 7.905 0 0 1-1.698 2.513 7.87 7.87 0 0 1-2.513 1.699 7.817 7.817 0 0 1-3.062.61l-.001.001Z"
      fill="#F8F7FE"
    />
    <path
      d="M116.79 128.504c-.626 0-1.22-.116-1.764-.346a4.57 4.57 0 0 1-1.455-.987 4.567 4.567 0 0 1-.986-1.455 4.514 4.514 0 0 1-.347-1.765c0-.626.116-1.22.346-1.763a4.572 4.572 0 0 1 .986-1.456 4.591 4.591 0 0 1 1.456-.987 4.508 4.508 0 0 1 1.764-.346c.626 0 1.22.116 1.763.347a4.6 4.6 0 0 1 1.455.986c.427.427.76.917.987 1.456a4.5 4.5 0 0 1 .347 1.763c0 .626-.117 1.22-.347 1.764a4.567 4.567 0 0 1-.986 1.455 4.587 4.587 0 0 1-1.455.987 4.505 4.505 0 0 1-1.764.347Z"
      fill="#0F88C1"
    />
    <path
      d="m82.53 100.337-6.332-4.907 3.06-1.834 3.409 2.64.297-.366-3.321-2.78 1.14-3.383 6.142 5.145-.915 2.915 26.732 21.812.545-.697 4.441 5.629-6.534-2.952.521-.667L84.88 98.996l-2.35 1.341Z"
      fill="#000"
    />
  </svg>
);
export default SvgAiMarketer3;

import * as React from 'react';

function SvgXTwitterIcon(props) {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="16" cy="16" r="16" fill="black" />
      <path
        d="M20.8123 9H23.1134L18.0874 14.9298L24 23H19.3716L15.7438 18.1067L11.5978 23H9.29335L14.6682 16.6562L9 9H13.7458L17.0215 13.4726L20.8123 9ZM20.0039 21.5798H21.2784L13.0515 10.3462H11.6825L20.0039 21.5798Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgXTwitterIcon;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { StaticQuery, graphql, Link } from 'gatsby';

const LikeWhatYouSee = ({ margin }) => {
  return (
    <Container margin={margin}>
      <div />
      <motion.div>
        <StaticQuery
          query={graphql`
            {
              peopleInOfficeMobile: file(relativePath: { eq: "home/people_in_office_mobile.jpg" }) {
                name
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 671
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                  )
                }
              }
              peopleInOfficeTablet: file(relativePath: { eq: "home/people_in_office_tablet.jpg" }) {
                name
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 1343
                    placeholder: BLURRED
                    formats: AUTO
                    quality: 90
                  )
                }
              }
            }
          `}
          render={({ peopleInOfficeMobile, peopleInOfficeTablet }) => {
            const peopleInOfficeSources = withArtDirection(getImage(peopleInOfficeMobile), [
              {
                image: getImage(peopleInOfficeTablet),
                media: '(min-width: 48em)'
              }
            ]);
            return <PeopleInOffice image={peopleInOfficeSources} alt="people in office" />;
          }}
        />
        <h3>Like what you see?</h3>
        <StyledLink to="/contact/" id="contact-us-like-what-you-see">Contact Us</StyledLink>
      </motion.div>
      <div />
    </Container>
  );
};

const Container = styled.section`
  background: #fff;
  grid-template-rows: 10.875rem 1fr 6.25rem;
  margin-top: ${({ margin }) => (margin ? '1rem' : '0')};
  > div:last-of-type {
    background-color: var(--ax-beige);
  }
  > div:first-of-type {
    grid-area: 1 / 1 / 2 / -1;
    background-color: white;
  }
  > div:last-of-type {
    grid-area: 3 / 1 / -1 / -1;
  }
  > div:nth-of-type(2) {
    background: var(--ax-gold);
    box-shadow: 0.438rem 0.438rem 1.875rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 1 / -1 / -1;
    padding: 1.85rem 1.25rem 1.55rem;
    margin: -5rem 1rem 1rem 1rem;
    position: relative;
    h3,
    a {
      position: relative;
      text-align: center;
    }
    h3 {
      color: #fff;
      font-family: 'Butler', serif;
      font-display: auto;
      font-size: 3rem;
      font-weight: 400;
      left: 0.021em;
      letter-spacing: 0.013em;
      line-height: 1.25em;
      padding: 1.85rem 1.25rem 1.55rem;
    }
    a {
      grid-area: 1 / 2;
      margin: 1.25rem 1.25rem 1.55rem;
      white-space: nowrap;
    }
  }
  @media screen and (min-width: 48rem) {
    margin-top: ${({ margin }) => (margin ? '2rem' : '0')};
    grid-template-rows: 3.063rem 1fr 5.5rem;
    > div:nth-of-type(2) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      grid-area: 1 / 1 / -1 / -1;
      margin: 0 1.25rem 1.875rem;
      margin-top: -2.5rem;
      padding: 2.25rem 3.2rem 2.25rem 3.2rem;
      max-height: 8rem;
      h3 {
        grid-area: 1 / 1;
      }
      a {
        grid-area: 1 / 2;
      }
    }
  }
  @media screen and (min-width: 68.75rem) {
    margin-top: ${({ margin }) => (margin ? '3rem' : '0')};
    > div:nth-of-type(2) {
      padding: 3rem 6.2rem 3rem 6.2rem;
      justify-self: center;
      min-width: 73.75rem;
      max-width: 112.5rem;
    }
  }
`;

const StyledLink = styled(Link)`
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #ffffff;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: 'pointer';
    transform: scale(1.02);
  }
`;

const PeopleInOffice = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
`;

export default LikeWhatYouSee;

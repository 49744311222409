import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { BlackBar, Menu } from 'components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { isWindows } from 'react-device-detect';
import { useWindowSize } from 'hooks';
import AgencyXLogo from 'images/shared/AgencyXLogo';
import { useLocation } from '@reach/router';

const Header = ({ pathname }) => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    setScrollYPosition(window.scrollY);
    const id = window.addEventListener('scroll', () => {
      setScrollYPosition(window.scrollY);
    });
    return () => window.removeEventListener('scroll', id);
  }, []);

  useEffect(() => {
    if (!isWindows && menuIsOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [menuIsOpen]);

  const userHasScrolled = scrollYPosition > 0;

  return (
    <Container
      pathname={location.pathname}
      variants={headerVariants}
      initial="userIsAtTopOfPage"
      animate={userHasScrolled ? 'userHasScrolled' : 'userIsAtTopOfPage'}>
      <div>
        <Link to="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledAgencyXLogo
            $isHomepage={isHomepage}
            $menuIsOpen={menuIsOpen}
            $userHasScrolled={userHasScrolled}
            $scrollPosition={scrollYPosition}
          />
        </Link>
        <IncreasedTapArea onClick={() => setMenuIsOpen(!menuIsOpen)}>
          <BlackBars pathname={pathname}>
            <BlackBar
              variants={topBlackBarVariants}
              menuIsOpen={menuIsOpen}
              isHomepage={isHomepage}
              userHasScrolled={userHasScrolled}
            />
            <BlackBar
              variants={bottomBlackBarVariants}
              menuIsOpen={menuIsOpen}
              isHomepage={isHomepage}
              userHasScrolled={userHasScrolled}
            />
          </BlackBars>
        </IncreasedTapArea>
        <Menu
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
          userHasScrolled={userHasScrolled}
        />
      </div>
    </Container>
  );
};

const headerVariants = {
  userIsAtTopOfPage: {
    backgroundColor: 'rgba(000, 000, 000, 0)',
    height: '6.25rem',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.375,
      height: {
        delay: 0
      }
    }
  },
  userHasScrolled: {
    backgroundColor: 'rgba(000, 000, 000, 0.725)',
    height: '4rem',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.375
    }
  }
};

/* TODO: DRY Refactor the BlackBar animnation implementation. */
const topBlackBarVariants = {
  menuIsClosed: {
    backgroundColor: '#000',
    clipPath: 'polygon(0% 100%, 7.5% 0%, 100% 0%, 100% 100%)',
    width: '0.875rem',
    x: 6,
    rotate: 0,
    transition: {
      ease: 'easeInOut'
    }
  },
  menuIsClosedAndUserHasScrolled: {
    backgroundColor: '#fff',
    clipPath: 'polygon(0% 100%, 7.5% 0%, 100% 0%, 100% 100%)',
    width: '0.875rem',
    x: 6,
    rotate: 0,
    transition: {
      ease: 'easeInOut'
    }
  },
  menuIsOpen: {
    backgroundColor: '#000',
    clipPath: 'none',
    width: '1.1rem',
    transformOrigin: 'top left',
    x: 0,
    rotate: 45,
    transition: {
      ease: 'easeInOut'
    }
  }
};

const bottomBlackBarVariants = {
  menuIsClosed: {
    backgroundColor: '#000',
    width: '0.875rem',
    clipPath: 'polygon(0% 100%, 0% 0%, 100% 0%, 92.5% 100%)',
    rotate: 0,
    transition: {
      ease: 'easeInOut'
    }
  },
  menuIsClosedAndUserHasScrolled: {
    backgroundColor: '#fff',
    width: '0.875rem',
    clipPath: 'polygon(0% 100%, 0% 0%, 100% 0%, 92.5% 100%)',
    rotate: 0,
    transition: {
      ease: 'easeInOut'
    }
  },
  menuIsOpen: {
    backgroundColor: '#000',
    clipPath: 'none',
    width: '1.1rem',
    transformOrigin: 'bottom left',
    rotate: -45,
    transition: {
      ease: 'easeInOut'
    }
  }
};

const Container = styled(motion.header)`
  grid-area: 1 / 2;
  z-index: 3;
  opacity: ${({ pathname }) => (pathname === '/logobook/' ? 0 : 1)} !important;
  position: fixed;
  width: 100%;
  > div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 1.25rem;

    @media screen and (min-width: 48rem) {
      padding: 0 6.25%;
    }

    @media screen and (min-width: 90rem) {
      padding: 0 9%;
    }

    @media screen and (min-width: 120rem) {
      max-width: 1562px;
      padding: 0;
    }
  }
`;

const StyledAgencyXLogo = styled(AgencyXLogo)`
  position: relative;
  z-index: 2;
  padding: 0.2em 0;
  width: 10rem;
  transition-duration: 0.75s;
  opacity: ${({ $isHomepage, $menuIsOpen, $scrollPosition }) =>
    $isHomepage && !$menuIsOpen && !$scrollPosition > 0 ? 0 : 1};
  path {
    fill: ${({ $isHomepage, $userHasScrolled, $menuIsOpen }) =>
      ($isHomepage && !$menuIsOpen) || ($userHasScrolled && !$menuIsOpen) ? 'white' : 'black'};
    &:nth-child(2) {
      fill: ${({ $menuIsOpen }) => ($menuIsOpen ? 'white' : 'var(--ax-gold)')};
    }
  }
`;

const IncreasedTapArea = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  left: 0.688rem;
  position: relative;
  width: 2.625rem;
  z-index: 3;
`;

const BlackBars = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 0.875rem;
  justify-content: space-between;
  position: relative;
  pointer-events: none;
  width: 1.25rem;
  opacity: 1;
`;

export default Header;

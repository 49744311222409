import React from 'react';
import styled from 'styled-components';
import TopIconSVG from 'images/footer/ScrollTop';
import { motion } from 'framer-motion';
import { scroller } from 'react-scroll';

const scrollToAnchor = (anchor, { offset = 0, duration = 1000, delay = 0 } = {}) => {
  scroller.scrollTo(anchor, {
    offset,
    duration,
    delay,
    smooth: 'easeInOutQuint',
    ignoreCancelEvents: true
  });
};

const ScrollToTopButton = () => {
  return (
    <Container
      type="button"
      onClick={(e) => {
        e.preventDefault();
        scrollToAnchor('top', {
          duration: 1600,
          offset: 0
        });
      }}>
      <TopIcon>
        <TopIconSVG />
      </TopIcon>
    </Container>
  );
};

const TopIcon = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  transition: border-radius 400ms ease-in-out;
  width: 48px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const Container = styled(motion.button)`
  background: transparent;
  border: none;
  bottom: 1.25rem;
  cursor: pointer;
  display: none;
  padding: 0;
  position: absolute;
  right: 1.25rem;
  z-index: 1;

  @media screen and (min-width: 64rem) {
    display: flex;
    right: 3rem;
    bottom: 6rem;
  }
`;

export default ScrollToTopButton;

import * as React from 'react';
const SvgSlide5 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M80.968 230s-4.755-12.1-14.194-17.742l-7.097-3.549c-7.096 3.549-14.193 3.549-14.193 3.549s-7.097 0-14.194-3.549l-7.096 3.549C14.754 217.9 10 230 10 230M45.483 198.064c7.84 0 14.194-6.355 14.194-14.193 0-7.839-6.355-14.194-14.194-14.194-7.838 0-14.193 6.355-14.193 14.194 0 7.838 6.355 14.193 14.193 14.193ZM155.484 229.999s-4.755-12.1-14.193-17.742l-7.097-3.548c-7.097 3.548-14.194 3.548-14.194 3.548s-7.097 0-14.193-3.548l-7.097 3.548c-9.439 5.642-14.194 17.742-14.194 17.742M120.002 198.064c7.839 0 14.193-6.355 14.193-14.194s-6.354-14.193-14.193-14.193c-7.839 0-14.194 6.354-14.194 14.193 0 7.839 6.355 14.194 14.194 14.194ZM230.001 229.999s-4.755-12.1-14.194-17.742l-7.097-3.548c-7.096 3.548-14.193 3.548-14.193 3.548s-7.097 0-14.194-3.548l-7.096 3.548c-9.439 5.642-14.194 17.742-14.194 17.742M194.516 198.064c7.839 0 14.194-6.355 14.194-14.194s-6.355-14.193-14.194-14.193-14.193 6.354-14.193 14.193c0 7.839 6.354 14.194 14.193 14.194ZM95.162 116.452h74.516M70.322 116.452h21.29"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M88.063 123.549a7.097 7.097 0 1 0 0-14.193 7.097 7.097 0 0 0 0 14.193Z"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M88.065 34.839v56.774l60.322-28.387-60.322-28.387Z"
      fill="#CE9200"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M215.806 141.29h-10.645l-10.645 14.194-21.29-14.194h-35.484L120 155.484l-17.742-14.194H66.774l-21.29 14.194-10.645-14.194H24.194c-3.904 0-7.097-3.193-7.097-7.096V17.097c0-3.904 3.193-7.097 7.096-7.097h191.613c3.904 0 7.097 3.194 7.097 7.097v117.097c0 3.903-3.193 7.096-7.097 7.096Z"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide5;

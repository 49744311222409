import * as React from 'react';
import styled from 'styled-components';
import { float } from '../Keyframes';

const SvgDots2 = (props) => (
  <SVG viewBox="0 0 295 290" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M259.823 23.077a1.691 1.691 0 0 0 .541 3.338 1.69 1.69 0 0 0-.541-3.338ZM95.307 106.045a3.613 3.613 0 0 0 0 5.105 3.601 3.601 0 0 0 5.098 0 3.615 3.615 0 0 0 0-5.105 3.601 3.601 0 0 0-5.098 0ZM2.045 113.996c-.66.66-.66 1.731 0 2.392.66.66 1.728.66 2.388 0 .66-.661.66-1.732 0-2.392a1.686 1.686 0 0 0-2.388 0ZM181.595 193.706a1.69 1.69 0 0 0 .914 2.209 1.687 1.687 0 0 0 2.206-.915 1.688 1.688 0 1 0-3.12-1.294Z"
      fill="#174BEB"
    />
    <path
      d="M92.713 217.049a1.693 1.693 0 0 0 0 2.392c.66.66 1.729.66 2.388 0 .66-.66.66-1.731 0-2.392a1.688 1.688 0 0 0-2.388 0Z"
      fill="#000"
    />
    <path d="M51.375 125.367a1.007 1.007 0 1 0 2.013 0 1.007 1.007 0 1 0-2.013 0Z" fill="#45DEFF" />
    <path
      d="M180.896 132.235a2.261 2.261 0 0 0-2.259 2.263 2.261 2.261 0 0 0 2.259 2.262 2.261 2.261 0 0 0 2.26-2.262 2.261 2.261 0 0 0-2.26-2.263ZM101.464 67.634a3.428 3.428 0 0 0-6.837-.515 3.427 3.427 0 0 0 3.161 3.673 3.427 3.427 0 0 0 3.676-3.158Z"
      fill="#EF7E72"
    />
    <path
      d="M132.591 79.57a1.796 1.796 0 0 0-3.582-.269 1.796 1.796 0 0 0 3.582.27Z"
      fill="#F096F9"
    />
    <path
      d="M111.028 287.126a2.262 2.262 0 0 0-4.512-.34 2.261 2.261 0 0 0 2.086 2.423 2.261 2.261 0 0 0 2.426-2.083Z"
      fill="#EF7E72"
    />
    <path
      d="M147.963 263.239a1.557 1.557 0 1 0-3.105-.233 1.557 1.557 0 0 0 3.105.233ZM229.171 116.087a1.558 1.558 0 1 0-1.404 2.776 1.558 1.558 0 0 0 1.404-2.776ZM259.342 250.858a1.558 1.558 0 0 0-3.077.486 1.557 1.557 0 0 0 3.077-.486Z"
      fill="#F096F9"
    />
    <path
      d="M180.886 270.089a1.557 1.557 0 0 0-2.092.687 1.556 1.556 0 1 0 2.092-.687ZM177.063 73.521a1.555 1.555 0 1 0-1.405 2.778 1.557 1.557 0 1 0 1.405-2.778Z"
      fill="#65E1FB"
    />
  </SVG>
);

const SVG = styled.svg`
  animation: ${float} 6s linear infinite;
`;

export default SvgDots2;

import * as React from 'react';
const SvgAiBall = (props) => (
  <svg viewBox="0 0 173 173" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M130.494 43.111c-23.146-23.146-60.554-23.339-83.947-.579a.824.824 0 0 0-.003 1.171l83.359 83.358a.826.826 0 0 0 1.171-.003c22.759-23.387 22.567-60.8-.58-83.947Z"
      fill={props.primaryColor}
    />
    <path
      d="M43.044 130.561c23.147 23.147 60.554 23.339 83.947.58a.823.823 0 0 0 .003-1.171L43.636 46.612a.827.827 0 0 0-1.171.002c-22.76 23.388-22.567 60.801.58 83.947Z"
      fill={props.secondaryColor}
    />
  </svg>
);
export default SvgAiBall;

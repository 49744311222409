import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const BlackBar =({ variants, userHasScrolled, menuIsOpen, isHomepage }) => {
  return (
    <Container
      variants={variants}
      custom={userHasScrolled}
      initial="menuIsClosed"
      animate={
        menuIsOpen
          ? 'menuIsOpen'
          : !menuIsOpen && userHasScrolled
          ? 'menuIsClosedAndUserHasScrolled'
          : !menuIsOpen && isHomepage
          ? 'menuIsClosedAndUserHasScrolled'
          : 'menuIsClosed'
      }
      transition={{
        duration: 0.375,
        ease: 'easeInOut'
      }}
    />
  );
};

const Container = styled(motion.div)`
  height: 0.125rem;
`;

export default BlackBar;

import React from 'react';
import styled from 'styled-components';
import { AiCross } from 'images/aiMarketingCaseStudy';
import { motion } from 'framer-motion';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const References = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <RenderWhenReady>
        {windowWidth > 768 && (
          <SVGContainer
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <AiCross color="#E04527" />
          </SVGContainer>
        )}
      </RenderWhenReady>
      <motion.div>
        <p>
          <small>
            Accenture. (2023). Accenture and Google Cloud Help Organizations Scale Advances in
            Generative AI. Accenture. Retrieved March 4, 2024, from{' '}
            <a href="https://newsroom.accenture.com/news/2023/accenture-and-google-cloud-help-organizations-scale-advances-in-generative-ai">
              https://newsroom.accenture.com/news/2023/accenture-and-google-cloud-help-organizations-scale-advances-in-generative-ai
            </a>
            .
            <br />
            Angler, M., & Ajder, H. (2023). Henry Ajder on Generative AI: “we need a balance between
            excitement and supervision”. European Science-Media Hub.{' '}
            <a href="https://sciencemediahub.eu/2023/09/20/henry-ajder-on-generative-ai-we-need-a-balance-between-excitement-and-supervision/">
              https://sciencemediahub.eu/2023/09/20/henry-ajder-on-generative-ai-we-need-a-balance-between-excitement-and-supervision/
            </a>
            <br />
            Buchter, J., Cordina, J., & Lee, M. (2023, March 14). Driving growth through consumer
            centricity in Healthcare. McKinsey & Company.{' '}
            <a href="https://www.mckinsey.com/industries/healthcare/our-insights/driving-growth-through-consumer-centricity-in-healthcare#/">
              https://www.mckinsey.com/industries/healthcare/our-insights/driving-growth-through-consumer-centricity-in-healthcare#/
            </a>
            <br />
            Chipman, A. (2023). Eight in ten UK Health Orgs have had a security breach since 2021.
            Digital Health.{' '}
            <a href="https://www.digitalhealth.net/2023/06/eight-in-ten-uk-health-orgs-have-had-a-security-breach-since-2021/">
              https://www.digitalhealth.net/2023/06/eight-in-ten-uk-health-orgs-have-had-a-security-breach-since-2021/
            </a>
            <br />
            Dhar, A., Fera, B., & Korenda, L. (2023, November 16). Can GenAI help make health care
            affordable? Consumers think so. Deloitte United States.{' '}
            <a href="https://www2.deloitte.com/us/en/blog/health-care-blog/2023/can-gen-ai-help-make-health-care-affordable-consumers-think-so.html">
              https://www2.deloitte.com/us/en/blog/health-care-blog/2023/can-gen-ai-help-make-health-care-affordable-consumers-think-so.html
            </a>
            <br />
            Edelman. (2023). 2023 Edelman Trust Barometer Special report - Trust and Health.{' '}
            <a href="https://www.edelman.com/trust/2023/trust-barometer/special-report-health">
              https://www.edelman.com/trust/2023/trust-barometer/special-report-health
            </a>
            <br />
            Elsevier. (2023). New report finds doctors and nurses ready to embrace generative AI to
            answer the pressure points facing global health systems. Elsevier. Retrieved March 4,
            2024, from{' '}
            <a href="https://www.elsevier.com/en-gb/about/press-releases/new-report-finds-doctors-and-nurses-ready-to-embrace-generative-ai-to-answer">
              https://www.elsevier.com/en-gb/about/press-releases/new-report-finds-doctors-and-nurses-ready-to-embrace-generative-ai-to-answer
            </a>
            .
            <br />
            Elsevier. (2023) Clinician of the Future Report 2023. Retrieved March 4, 2024, from{' '}
            <a href="https://elsevier.com/connect/clinician-of-the-future">
              https://elsevier.com/connect/clinician-of-the-future
            </a>
            <br />
            Foo, Y. (2024, February 13). EU lawmakers ratify political deal on Artificial
            Intelligence Rules, Reuters.{' '}
            <a href="https://www.reuters.com/technology/eu-lawmakers-back-political-deal-artificial-intelligence-rules-2024-02-13/">
              https://www.reuters.com/technology/eu-lawmakers-back-political-deal-artificial-intelligence-rules-2024-02-13/
            </a>
            <br />
            Inokuchi, Y., Bauer, A., Pi, T., & Butt, S. (2024). Navigating Asia’s Regulatory Mosaic:
            Achieving Excellence in Generative AI for Healthcare. L.E.K. Consulting.{' '}
            <a href="https://www.lek.com/insights/hea/cn/ei/navigating-asias-regulatory-mosaic-achieving-excellence-generative-ai-healthcare">
              https://www.lek.com/insights/hea/cn/ei/navigating-asias-regulatory-mosaic-achieving-excellence-generative-ai-healthcare
            </a>
            <br />
            Khedkar, P., & Reiss, D. (2023). Why and how we must accelerate AI’s impact on
            Healthcare. World Economic Forum.{' '}
            <a href="https://www.weforum.org/agenda/2023/01/ai-impact-on-global-health-davos23/">
              https://www.weforum.org/agenda/2023/01/ai-impact-on-global-health-davos23/
            </a>
            <br />
            Khedkar, P., & Bishen, S. (2024). Can we start rebuiding trust to unlock huge gains from
            using AI in Healthcare? . World Economic Forum.{' '}
            <a href="https://www.weforum.org/agenda/2024/01/3-ways-generative-ai-is-reshaping-the-patient-experience/">
              https://www.weforum.org/agenda/2024/01/3-ways-generative-ai-is-reshaping-the-patient-experience/
            </a>
            <br />
            Konieczko, C. (2022). How COVID and the cost-of-living crisis is reshaping health and
            wellness marketing. Retrieved March 4, 2024. Lyons, P., & Gupta, L. (2023). 2024 outlook
            for life sciences. Deloitte United States.{' '}
            <a href="https://www2.deloitte.com/us/en/blog/health-care-blog/2023/outlook-for-life-sciences.html">
              https://www2.deloitte.com/us/en/blog/health-care-blog/2023/outlook-for-life-sciences.html
            </a>
            <br />
            Martin, A. (2024). Health insurance data breach affects nearly half of France’s
            population, Privacy Regulator warns. The Record from Recorded Future News.{' '}
            <a href="https://therecord.media/health-insurance-data-breach-affects-half-of-france-cnil">
              https://therecord.media/health-insurance-data-breach-affects-half-of-france-cnil
            </a>
            <br /> Microsoft. (2023). Mayo Clinic to deploy and test Microsoft generative AI tools.
            Microsoft. Retrieved March 4, 2024, from{' '}
            <a href="https://news.microsoft.com/2023/09/28/mayo-clinic-to-deploy-and-test-microsoft-generative-ai-tools/">
              https://news.microsoft.com/2023/09/28/mayo-clinic-to-deploy-and-test-microsoft-generative-ai-tools/
            </a>
            .
            <br />
            PwC strategy+business. (2023). From healthcare to life care. PwC. Retrieved March 4,
            2024, from{' '}
            <a href="https://www.pwc.com/gx/en/issues/transformation/insights/transforming-precision-health.html">
              https://www.pwc.com/gx/en/issues/transformation/insights/transforming-precision-health.html
            </a>
            .
            <br />
            The European Union Agency for Cybersecurity. (2023). Checking-up on Health: Ransomware
            Accounts for 54% of Cybersecurity Threats. ENISA. Retrieved March 4, 2024, from{' '}
            <a href="https://www.enisa.europa.eu/news/checking-up-on-health-ransomware-accounts-for-54-of-cybersecurity-threats">
              https://www.enisa.europa.eu/news/checking-up-on-health-ransomware-accounts-for-54-of-cybersecurity-threats
            </a>
            .
            <br />
            WARC. (2024). Industry Snapshot: Healthcare and pharmaceuticals . Retrieved March 2024.
            <br />
            Wolters Kluwer. (2023). Generative AI in Healthcare: Gaining Consumer Trust{' '}
            <a href="https://assets.contenthub.wolterskluwer.com/api/public/content/2110022-generative-ai-infographic-23d5925f95">
              https://assets.contenthub.wolterskluwer.com/api/public/content/2110022-generative-ai-infographic-23d5925f95
            </a>
            .
          </small>
        </p>
      </motion.div>
    </Container>
  );
};

const SVGContainer = styled(motion.div)`
  position: absolute;
  z-index: 1;
  top: -110px;
  left: -60px;
  width: 80px;
  svg {
    transform: rotate(45deg);
  }
  @media (min-width: 64rem) {
    left: -100px;
    top: -80px;
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
  }

  p {
    line-height: 1;
  }
  a {
    color: #000000;
    word-break: break-all;
  }
  @media (min-width: 48rem) {
    padding: 0 0 3rem 0;
  }
`;

export default References;

import * as React from 'react';
const SvgPrevArrow = (props) => (
  <svg viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.784 2.466 8.14.946 0 8.473 8.14 16l1.644-1.52-5.332-4.932H27v-2.15H4.452l5.332-4.931Z"
      fill="#fff"
    />
  </svg>
);
export default SvgPrevArrow;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AiCross, AiBot, AiWord, AiPill } from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const SideText = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <motion.div>
        <div>
          <h2>Consumers believe that Gen Ai could improve healthcare access</h2>
          <p>
            According to a survey by Deloitte, over half (53%) of respondents believe gen AI could
            help reduce wait times in healthcare. Additionally, almost two in three (63%)
            respondents who have used gen AI for health reasons believe it could potentially make
            healthcare more affordable and remove barriers to accessing critical healthcare.
            <br />
            <br />
            Barriers to accessing healthcare:
          </p>
        </div>
        <RenderWhenReady>
        {windowWidth > 768 && (
          <div style={{ position: 'relative' }}>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiWord color="#7A68CC" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#DA629E" secondaryColor="#EDB40D" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBot />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#CE9200" secondaryColor="#E04527" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#47AD4A" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#0F88C1" />
            </AnimatedSVG>
          </div>
        )}
        </RenderWhenReady>
      </motion.div>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0 0 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
    position: relative;
  }
  h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    line-height: 1;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.3;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: -160px;
      right: -40px;
      width: 120px;
      svg {
        transform: rotate(45deg);
      }
    }
    :nth-child(2) {
      top: -80px;
      right: 150px;
      width: 120px;
    }
    :nth-child(3) {
      top: -40px;
      right: -40px;
      width: 170px;
    }
    :nth-child(4) {
      bottom: -20px;
      right: -180px;
      width: 120px;
    }
    :nth-child(5) {
      width: 80px;
      left: 20%;
      top: 220px;
      svg {
        transform: rotate(45deg);
      }
      @media (min-width: 64rem) {
        top: 20px;
      }
    }
    :nth-child(6) {
      width: 80px;
      left: 60%;
      top: 220px;
      svg {
        animation: spin 2s 1s ease-in-out infinite alternate-reverse;
      }
      @media (min-width: 64rem) {
        top: 100px;
        left: 35%;
      }
    }
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 3rem 0;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;

export default SideText;

import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useWindowSize } from 'hooks';

const OtherCaseStudies = ({ data }) => {
  const { windowWidth } = useWindowSize();

  return (
    <Wrapper>
      <SectionTitle style={{ marginLeft: '0em' }}>Other projects</SectionTitle>
      <CaseStudies>
        {windowWidth >= 1100
          ? data.map((edge, index) => {
              const { previewImage, company, whatWeDid, alt, slug } = edge.node;
              return (
                <PreviewLink to={`/our-work/${slug.current}`}>
                  <CaseStudyPreview
                    key={index}
                    variants={caseStudyPreviewVariants}
                    whileHover="hover"
                    whileTap="tap"
                    layout>
                    <GatsbyImage image={getImage(previewImage.asset.gatsbyImageData)} alt={alt} />
                    <p>{company}</p>
                    <motion.h4 variants={caseStudyPreviewVariants}>{whatWeDid}</motion.h4>
                    <SeeMore variants={caseStudyPreviewVariants}>
                      See case study
                      <Seperator />
                    </SeeMore>
                  </CaseStudyPreview>
                </PreviewLink>
              );
            })
          : data.slice(0, 2).map((edge, index) => {
              const { previewImage, company, whatWeDid, alt, slug } = edge.node;
              return (
                <PreviewLink to={`/our-work/${slug.current}`}>
                  <CaseStudyPreview
                    key={index}
                    variants={caseStudyPreviewVariants}
                    whileHover="hover"
                    whileTap="tap"
                    layout>
                    <GatsbyImage image={getImage(previewImage.asset.gatsbyImageData)} alt={alt} />
                    <p>{company}</p>
                    <motion.h4 variants={caseStudyPreviewVariants}>{whatWeDid}</motion.h4>
                    <SeeMore variants={caseStudyPreviewVariants}>
                      See case study
                      <Seperator />
                    </SeeMore>
                  </CaseStudyPreview>
                </PreviewLink>
              );
            })}
      </CaseStudies>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -6.5rem;
  margin-bottom: 6.5rem;
  @media only screen and (max-width: 48rem) {
    margin-top: -8.5rem;
    margin-bottom: 3.75rem;
  }
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 0.0185em;
  margin: 1.85em auto 1em;
  text-transform: lowercase;
`;

const CaseStudies = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 30rem) {
    flex-direction: column;
    > a:first-of-type {
      margin-bottom: 2.75rem;
    }
  }
`;

export const CaseStudyPreview = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
  p {
    font-size: 0.75rem;
    margin: 1.25rem 0 0.5rem 0;
  }
  h4 {
    color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-black)'};
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  &:hover {
    h4 {
      color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-gold)'};);
      font-weight: 900;
    }
    div {
      color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-gold)'};
      div {
        background-color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-gold)'};;
      }
    }
  }
  @media only screen and (max-width: 48rem) {
    h4 {
      font-size: 1.75rem;
    }
  }
`;

export const PreviewLink = styled(Link)`
  color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-black)'};
  height: auto;
  text-decoration: none;
  width: calc(100% - 5rem);
  margin-right: 5rem;
  &:last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 48rem) {
    width: calc(100% - 1rem);
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SeeMore = styled(motion.div)`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-top: auto;
  text-transform: uppercase;
  width: 100%;
`;

export const Seperator = styled.div`
  background-color: ${({ highlighedCaseStudy }) => highlighedCaseStudy ? '#fff' : 'var(--ax-black)'};
  height: 0.063rem;
  margin: 0.2rem 0;
  transition: background-color 150ms ease-in-out;
  width: 50%;
`;

export const caseStudyPreviewVariants = {
  hover: {
    cursor: 'pointer',
    scale: 1.02,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3
    }
  }
};

export default OtherCaseStudies;

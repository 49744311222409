import React from 'react';
import styled from 'styled-components';
import { LonerX, XCombo } from 'images/event-x';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import {Link} from 'gatsby'

const HcpEngageDemo = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Demo
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {windowWidth > 768 && (
        <>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <XCombo />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <LonerX />
          </AnimatedSVG>
        </>
      )}
      <h2>Demo AX Engage</h2>
      <p>To fully experience AX Engage in action, contact us and see our Demo website </p>
      <button type="button">
        <Link to="/contact" id="contact-us-ax-demo">
          Contact Us
        </Link>
      </button>
    </Demo>
  );
};

const AnimatedSVG = styled(motion.div)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    10% {
      transform: rotate(0deg);
    }
  }
`;

const Demo = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: white;
  padding: 3rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.75s;

  @media (min-width: 1150px) {
    padding: 3rem;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      bottom: -10%;
      right: -5%;
      width: 10rem;
    }
    :nth-child(2) {
      bottom: -12%;
      right: -9%;
      width: 5rem;
      svg {
        animation: spin 3s ease-in-out infinite;
      }
    }
  }
  h2 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: var(--ax-pink);
  }
  p {
    font-size: 1.5rem;
    line-height: 1.25em;
    color: black;
    font-weight: 300;
    max-width: 800px;
    @media (min-width: 1150px) {
      font-size: 2rem;
      line-height: 1.5em;
    }
  }
  button {
    width: 166px;
    height: 46px;
    font-size: 16px;
    line-height: 19px;
    background: var(--ax-pink);
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 0;
    transition:.375s;
    cursor:pointer;
    &:hover, &:focus{
      border:none;
      outline:none;
      background: rgb(255, 60, 120);
    }
    a {
      color: white;
      height:100%;
      width:100%;
      text-decoration: none;
    }
    @media (min-width: 1150px) {
      margin-top: 3rem;
    }
  }
`;

export default HcpEngageDemo;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AiWord, AiBall, AiPill } from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const BlockText = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
    <RenderWhenReady>
      {windowWidth > 768 && (
        <>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <AiWord color="#0F88C1" />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <AiBall primaryColor="#E04527" secondaryColor="#47AD4A" />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <AiPill primaryColor="#7A68CC" secondaryColor="#30F1FE" />
          </AnimatedSVG>
        </>
      )}
      </RenderWhenReady>
      <motion.div>
        <div>
          <h2>Considerations for the implementation of Ai</h2>
          <h3>The Human element</h3>
          <p>
            Human supervision is required to ensure AI output is reliable and accurate. Rather than
            relying on generative AI for content creation, marketers should view generalised AI as a
            collaborative tool to assist in brainstorming and idea generation processes. AI models
            still make mistakes and do not have emotions to deal with sensitive topics in an
            empathetic manner. Using generative AI also presents a risk of generating content that
            contains inappropriate responses, misrepresentation and misinterpretation caused by a
            lack of contextual understanding. Human oversight and involvement in the use of
            generative AI is imperative. In light of ethical concerns, the human using the tool has
            to be responsible for AI outputs that are used or implemented.
          </p>
          <h3>Evolving restrictions on use of gen AI in healthcare</h3>
          <p>
            In 2023, the EU reached a provisional agreement on the AI Act. Brands who do not comply
            with these guidelines are likely to face disciplinary action. Even though governments
            around the globe are pursuing best practices for AI, regulations will vary. It is
            crucial for brand managers to stay updated on specific laws in their country of
            operation.
            <br />
            To maintain brand trust, organisations should be transparent about the source of
            AI-generated content.
          </p>
        </div>
        <div>
          <h3>Waning consumer trust caused by cybersecurity breaches and data leaks </h3>
          <p>
            According to the United Kingdom&apos;s Information Commissioner&apos;s Office,
            cyber-related attacks in the health sector more than tripled from 3% in 2018, to 10% in
            2022. The Health Service Executive (HSE) recorded more than 500 data protection breaches
            in 2022. In mid 2023, it was reported that 8 in 10 health organisations in the UK have
            had a security breach since 2021. In late January 2024, a data breach in the health
            insurance sector compromised the personal information of over 33 million people in
            France.
          </p>
          <p>
            The WHO has raised concerns about the rapid adoption of LLMs within healthcare, noting
            the lack of caution taken when using these platforms could hurt patients. Motivated by
            financial gain, cybercriminals collect sensitive data to extort or sell. It is natural
            for consumers to lose trust in brands who have experienced data leaks and consciously
            choose to engage with organisations that seem more secure.
          </p>
          <p>
            To build resilience, a sizeable budget must be allocated to ensure data security, which
            is fundamental to patient trust and system integrity. Should there be an instance of a
            data breach, marketers should respond swiftly to mitigate further damage to the
            brand&apos;s reputation. Brands must take accountability for its oversight and engage in
            transparent communication directly with customers.
          </p>
        </div>
      </motion.div>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  grid-template-columns: 1fr !important; 
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
  }

  h2 {
    color: var(--ax-gold);
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
    margin-bottom: 2rem;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: 0;
      left: -170px;
      width: 120px;
    }
    :nth-child(2) {
      top: 30%;
      left: -190px;
      width: 170px;
      opacity: 0 !important;
      svg {
        transform: rotate(-110deg);
      }
      @media (min-width: 64rem) {
        opacity: 1 !important;
      }
    }
    :nth-child(3) {
      bottom: 30%;
      right: -180px;
      width: 100px;
    }
  }
  @media (min-width: 48rem) {
    padding: 6rem 0 6rem 0;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }
  }
`;

export default BlockText;

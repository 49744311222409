import React from 'react';
import styled from 'styled-components';
import { AxEngageLogoContrast } from 'images/event-x';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import Background from 'images/event-x/hcp-engage-background.jpg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const HcpEngageBanner = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Banner
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <div>
        <AxEngageLogoContrast />
        <p>
          A unique live event platform specifically designed to connect HCPs, industry and patient
          groups with the latest research, science, and case studies relevant to their therapy area.
        </p>
        <button type="button">
          <AnchorLink to="/pharmaceutical-healthcare-marketing-ireland/#hcp-engage">Learn More</AnchorLink>
        </button>
      </div>
    </Banner>
  );
};

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-image: url(${Background});
  background-size: cover;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  text-align: center;
  transition: 0.75s;
  div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    background: rgba(235, 0, 69, .8);
    svg {
      width: 25rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 2rem;
      padding:.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.3em;
      color: white;
      font-weight: 300;
      max-width: 800px;
      @media (min-width: 1150px) {
        font-size: 1.25rem;
      }
    }
    button {
      width: 166px;
      height: 46px;
      font-size: 16px;
      line-height: 19px;
      background: black;
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 1.5rem;
      margin-bottom: 0;
      transition: 0.375s;
      cursor: pointer;
      &:hover,
      &:focus {
        border: none;
        outline: none;
        background: rgb(255, 60, 120);
      }
      a {
        color: white;
        height: 100%;
        width: 100%;
        text-decoration: none;
      }
      @media (min-width: 1150px) {
        margin-top: 3rem;
      }
    }
  }
`;

export default HcpEngageBanner;

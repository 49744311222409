import * as React from 'react';
import styled from 'styled-components';
import { float } from '../Keyframes';

const SvgDots1 = (props) => (
  <SVG viewBox="0 0 277 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M155.039 198.196a1.692 1.692 0 0 0-.542-3.339 1.691 1.691 0 0 0 .542 3.339ZM7.903 217.888a3.615 3.615 0 0 0 0-5.106 3.602 3.602 0 0 0-5.098 0 3.615 3.615 0 0 0 0 5.106 3.6 3.6 0 0 0 5.098 0ZM233.267 27.567a1.688 1.688 0 1 0-3.121-1.294 1.688 1.688 0 1 0 3.121 1.294Z"
      fill="#174BEB"
    />
    <path d="M26.402 113.243a.845.845 0 1 0-1.69-.001.845.845 0 0 0 1.69.001Z" fill="#000" />
    <path
      d="M233.965 89.038a2.26 2.26 0 0 0 2.259-2.263 2.26 2.26 0 0 0-2.259-2.263 2.26 2.26 0 0 0-2.259 2.263 2.26 2.26 0 0 0 2.259 2.263ZM37.263 160.746a2.262 2.262 0 0 0 1.868-2.596 2.26 2.26 0 1 0-4.46.725 2.26 2.26 0 0 0 2.592 1.871ZM130.89 36.58a2.261 2.261 0 0 0 2.26-2.263 2.26 2.26 0 1 0-4.519 0 2.26 2.26 0 0 0 2.259 2.263Z"
      fill="#EF7E72"
    />
    <path
      d="M272.548 224.495a2.262 2.262 0 0 0-1.038-4.404 2.262 2.262 0 0 0-1.679 2.722 2.258 2.258 0 0 0 2.717 1.682Z"
      fill="#45A5FF"
    />
    <path
      d="M207.051 218.885a1.044 1.044 0 1 0-.003-2.087 1.044 1.044 0 0 0 .003 2.087Z"
      fill="#FF45AE"
    />
    <path
      d="M157.475 71.63c.859 0 1.555-.698 1.555-1.558a1.556 1.556 0 1 0-3.111 0c0 .86.696 1.558 1.556 1.558ZM178.177 181.798a1.559 1.559 0 0 0-.715-3.033 1.558 1.558 0 0 0 .715 3.033Z"
      fill="#F096F9"
    />
    <path
      d="M153.118 104.984a1.555 1.555 0 1 0-2.875-1.193 1.555 1.555 0 1 0 2.875 1.193Z"
      fill="#65E1FB"
    />
  </SVG>
);

const SVG = styled.svg`
  animation: ${float} 9s linear infinite;
`;

export default SvgDots1;

import React from 'react';
import styled from 'styled-components';
import { BlueAndBlackSpray, PinkSpray, Mesh } from 'images/omnichannel/start-your-journey';
import { ThreeBalls } from 'images/omnichannel/versus';
import { AgencyXLogo } from 'images/shared';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

const StartYourJourney = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {windowWidth > 768 && (
        <>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <PinkSpray />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <Mesh />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <ThreeBalls />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <BlueAndBlackSpray />
          </AnimatedSVG>
        </>
      )}
      <AgencyXLogo style={{ maxWidth: '242px' }} />
      <p>Start your Omnichannel journey with us</p>
      <button type="button">
        <Link to="/contact" id="contact-us-omnichannel">Contact Us</Link>
      </button>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  gap: 1.325rem;
  height: auto;
  padding: 2.5rem 2rem;
  position: relative;
  text-align: center;
  transition: 0.75s;
  width: 100%;

  @media (min-width: 1150px) {
    padding: 2.5rem 3rem;
  }

  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: -90px;
      right: 40px;
      width: 207px;
      svg {
        animation: pulse 2s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(2) {
      top: -70px;
      right: -60px;
      width: 198px;
      svg {
        animation: spin 3s ease-in-out infinite;
      }
    }
    :nth-child(3) {
      bottom: -25px;
      left: -16px;
      width: 153px;
    }
    :nth-child(4) {
      bottom: -65px;
      left: -90px;
      width: 220px;
      svg {
        animation: pulse 2s 1s ease-in-out infinite alternate-reverse;
      }
    }
  }
  h2 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: var(--ax-gold);
  }
  p {
    font-size: 1.5rem;
    line-height: 1.25em;
    color: black;
    font-weight: 300;
    max-width: 800px;
    @media (min-width: 1150px) {
      font-size: 2rem;
      line-height: 1.5em;
    }
  }
  button {
    width: 166px;
    height: 46px;
    font-size: 16px;
    line-height: 19px;
    background: var(--ax-gold);
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    transition: 0.375s;
    cursor: pointer;
    &:hover,
    &:focus {
      border: none;
      outline: none;
      background: var(--ax-black);
    }
    a {
      color: white;
      height: 100%;
      width: 100%;
      text-decoration: none;
    }
  }
`;

export default StartYourJourney;

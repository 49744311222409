import * as React from 'react';
const SvgVs = (props) => (
  <svg viewBox="0 0 70 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m3.012 11.57 4.055-.777 17.856 27.21 6.642-31.864 4.125-.787-7.864 36.517-4.125.788L3 11.569h.012ZM46.129 30.939c2.692 2.315 7.03 3.808 11.86 2.891 5.666-1.081 8.5-4.513 7.759-8.427-.823-4.337-5.678-4.725-10.827-5.054-5.865-.34-12.377-.623-13.623-7.205C40.31 7.961 43.86 2.942 51.56 1.484c5.807-1.104 10.085.4 13.071 2.74L63.08 7.125c-2.47-2.291-6.454-3.42-10.933-2.562-5.254 1-7.981 4.196-7.311 7.699.729 3.855 5.266 4.078 10.191 4.372 6.09.364 12.907.74 14.27 7.945 1.035 5.454-2.385 10.755-10.778 12.341-5.455 1.035-9.969.224-14.024-2.844l1.646-3.138h-.012Z"
      fill="#000"
    />
    <path
      d="m.022 11.193 4.055-.776 17.856 27.21 6.642-31.864 4.126-.788-7.864 36.518-4.126.788L.01 11.193h.011ZM43.14 30.563c2.691 2.315 7.029 3.808 11.86 2.89 5.666-1.08 8.499-4.513 7.758-8.426-.823-4.337-5.678-4.725-10.826-5.054-5.866-.341-12.378-.623-13.624-7.205C37.32 7.585 40.87 2.566 48.57 1.108c5.807-1.104 10.086.4 13.071 2.74L60.09 6.75c-2.469-2.292-6.454-3.42-10.932-2.562-5.255.999-7.982 4.196-7.312 7.698.729 3.856 5.266 4.079 10.192 4.373 6.089.364 12.907.74 14.27 7.945 1.034 5.453-2.386 10.754-10.78 12.341-5.453 1.034-9.967.223-14.023-2.844l1.646-3.139h-.012Z"
      fill="#fff"
    />
  </svg>
);
export default SvgVs;

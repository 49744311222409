import { keyframes } from 'styled-components';

export const float = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg) translate3d(2%, 0, 0) rotate(0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg) translate3d(2%, 0, 0) rotate(-360deg);
  }
`;

export const glow = keyframes`
  0% {
    opacity: 0.85;
    filter: contrast(100%);
  }

  100% {
    filter: contrast(200%);
    opacity: 1;
  }
`;

const formatFields = (field) =>
  field
    .trim()
    .split(' ')
    .map((word) => {
      if (word !== 'eMagazine') {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(' ');

export default formatFields;

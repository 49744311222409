import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { RenderWhenReady } from 'components';
import { useWindowSize, useIsomorphicLayoutEffect } from 'hooks';
import { SmootherContext } from 'context';
import { motion } from 'framer-motion';
import {
  Lines,
  BlueSpray,
  OrangeSpray,
  LightBlueSpray,
  PinkSpray,
  Dots1,
  Dots2,
  OmniverseMobile,
  OmniverseDesktop
} from './AnimatedSVGs';

const Hero = () => {
  const { windowWidth } = useWindowSize();
  const smoother = useContext(SmootherContext);

  const linesRef = useRef();

  const pinkSprayRef = useRef();

  const lightBlueSprayRef = useRef();

  const orangeSprayRef = useRef();

  const dots1Ref = useRef();

  const dots2Ref = useRef();

  const blueSpray1Ref = useRef();

  const blueSpray2Ref = useRef();

  useIsomorphicLayoutEffect(() => {
    if (smoother) {
      const planets = [linesRef.current, pinkSprayRef.current, blueSpray2Ref.current];

      if (windowWidth >= 1440) {
        planets.push(lightBlueSprayRef.current);
        planets.push(orangeSprayRef.current);
      }

      // smoother.effects(planets, {
      //   speed: 0.65
      // });

      // const dots = [dots1Ref.current, dots2Ref.current];

      // smoother.effects(dots, {
      //   speed: 0.75
      // });
      // smoother.effects(blueSpray1Ref.current, {
      //   speed: 0.85
      // });
      smoother.effects(planets, {
        speed: 'clamp(0.65)'
      });

      const dots = [dots1Ref.current, dots2Ref.current];

      smoother.effects(dots, {
        speed: 'clamp(0.75)'
      });
      smoother.effects(blueSpray1Ref.current, {
        speed: 'clamp(0.85)'
      });
    }
  }, [
    smoother,
    linesRef.current,
    pinkSprayRef.current,
    lightBlueSprayRef.current,
    orangeSprayRef.current,
    dots1Ref.current,
    dots2Ref.current,
    blueSpray1Ref.current,
    blueSpray2Ref.current
  ]);

  return (
    <Container>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 1 }}>
          <BlueSpray1Wrapper ref={blueSpray1Ref}>
            <BlueSpray />
          </BlueSpray1Wrapper>
          <LinesWrapper ref={linesRef}>
            <Lines />
          </LinesWrapper>
          <PinkSprayWrapper ref={pinkSprayRef}>
            <PinkSpray />
          </PinkSprayWrapper>
          <BlueSpray2Wrapper ref={blueSpray2Ref}>
            <BlueSpray />
          </BlueSpray2Wrapper>
          {windowWidth >= 1440 && (
            <>
              <LightBlueSprayWrapper ref={lightBlueSprayRef}>
                <LightBlueSpray />
              </LightBlueSprayWrapper>
              <OrangeSprayWrapper ref={orangeSprayRef}>
                <OrangeSpray />
              </OrangeSprayWrapper>
            </>
          )}
          <Dots1Wrapper ref={dots1Ref}>
            <Dots1 />
          </Dots1Wrapper>
          <Dots2Wrapper ref={dots2Ref}>
            <Dots2 />
          </Dots2Wrapper>
        </motion.div>
      <h1>
        <RenderWhenReady>
          {windowWidth < 768 ? <OmniverseMobile /> : <OmniverseDesktop />}
        </RenderWhenReady>
      </h1>
      <Description
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.3, ease: 'easeInOut', delay: 0.65 }}>
        <h2>
          A practitioner&apos;s guide{windowWidth < 768 ? ' ' : <br />}to integrated Healthcare
          Campaigns
        </h2>
        <p>
          An Omnichannel marketing campaign for a pharmaceutical company should consider various
          channels and touchpoints to create a seamless and integrated experience for HCPs,
          patients, and other stakeholders. Here are some suggested considerations for your
          campaign:
        </p>
      </Description>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  margin: 49vw 0 5rem;

  @media screen and (min-width: 48rem) {
    margin: 17.5vw 0 7.5rem;
  }

  @media screen and (min-width: 67.5rem) {
    margin: 8.5vw 0 4.75rem;
  }

  @media screen and (min-width: 90rem) {
    margin: 4rem auto 6.7rem;
    max-width: 125rem;
  }

  h1,
  h2,
  p {
    position: relative;
    z-index: 1;
  }

  h1 {
    margin-top: 60vw;
    grid-column: 2;

    @media screen and (min-width: 48rem) {
      margin-top: 13.125rem;
      width: 32rem;
    }

    @media screen and (min-width: 90rem) {
      margin-top: 11.875rem;
      width: 51.438rem;
    }
  }
`;

const Description = styled(motion.div)`
  grid-column: 2;
  position: relative;
  width: 97%;
  z-index: 1;

  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.313em;
    margin: 0.6em 0;
    max-width: 18.188em;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.6em;

    @media screen and (min-width: 48rem) {
      max-width: 34.65em;
    }
  }
`;

const LinesWrapper = styled.div`
  position: absolute;
  top: -25.25vw;
  right: -108vw;
  width: 207vw;

  @media screen and (min-width: 48rem) {
    top: 1.875rem;
    right: -36.875rem;
    width: 80.625rem;
  }

  @media screen and (min-width: 90rem) {
    top: 2.313rem;
    right: -25.688rem;
    width: 87.5rem;
  }

  @media screen and (min-width: 125rem) {
    right: -13rem;
  }
`;

const Dots1Wrapper = styled.div`
  position: absolute;
  right: 0vw;
  top: -10vw;
  width: 70vw;

  @media screen and (min-width: 48rem) {
    top: 6.25rem;
    right: 0;
    width: 25rem;
  }

  @media screen and (min-width: 90rem) {
    top: 9.25rem;
    right: -2.5rem;
    width: 40.625rem;
  }

  @media screen and (min-width: 125rem) {
    right: 12.5rem;
  }
`;

const Dots2Wrapper = styled.div`
  position: absolute;
  right: -5vw;
  top: -30vw;
  width: 79.5vw;
  opacity: 0.75;

  @media screen and (min-width: 48rem) {
    top: -3rem;
    right: 12.5rem;
    width: 28.125rem;
  }

  @media screen and (min-width: 90rem) {
    right: 18.75rem;
    width: 34.375rem;
  }

  @media screen and (min-width: 125rem) {
    right: 28.875rem;
  }
`;

const PinkSprayWrapper = styled.div`
  filter: brightness(100%);
  transform: scale(1);
  opacity: 0.85;
  position: absolute;
  right: -29vw;
  top: -35vw;
  width: 84vw;

  @media screen and (min-width: 48rem) {
    top: -1rem;
    width: 33.125rem;
    right: -6.25rem;
  }

  @media screen and (min-width: 90rem) {
    top: -3.25rem;
    width: 39.063rem;
    right: 5rem;
  }

  @media screen and (min-width: 125rem) {
    right: 18.25rem;
  }
`;

const BlueSpray1Wrapper = styled.div`
  position: absolute;
  top: 102vw;
  left: -20vw;
  width: 45vw;
  opacity: 0.85;

  @media screen and (min-width: 48rem) {
    left: -9.375rem;
    top: 22rem;
    width: 18.75rem;
  }

  @media screen and (min-width: 90rem) {
    left: -1.25rem;
    top: 28rem;
    width: 18.75rem;
  }

  @media screen and (min-width: 125rem) {
    left: 2.125rem;
    width: 18.75rem;
  }
`;

const BlueSpray2Wrapper = styled.div`
  position: absolute;
  top: 72vw;
  right: -18vw;
  width: 35vw;
  opacity: 0.85;

  @media screen and (min-width: 48rem) {
    right: -6.25rem;
    top: 29.5rem;
    width: 12.938rem;
  }

  @media screen and (min-width: 90rem) {
    right: 14.063rem;
    top: 34.5rem;
  }

  @media screen and (min-width: 125rem) {
    right: 25.5rem;
  }
`;

const OrangeSprayWrapper = styled.div`
  position: absolute;
  opacity: 0.85;
  top: 31rem;
  width: 8.188rem;
  right: 4.688rem;

  @media screen and (min-width: 125rem) {
    right: 14.25rem;
    top: 27rem;
  }
`;

const LightBlueSprayWrapper = styled.div`
  position: absolute;
  opacity: 0.85;
  top: 6.75rem;
  width: 14.688rem;
  right: -7.5rem;

  @media screen and (min-width: 125rem) {
    right: 2rem;
  }
`;

export default Hero;

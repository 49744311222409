import React from 'react';
import styled from 'styled-components';
import FacebookIcon from 'images/footer/FacebookIcon';
import LinkedInIcon from 'images/footer/LinkedinIcon';
import XTwitterIcon from 'images/footer/XTwitterIcon';
import InstagramIcon from 'images/footer/InstagramIcon';

const SocialMedia = ({ invert }) => {
  return (
    <Container invert={invert}>
      <a href="https://www.linkedin.com/company/agencyx-dublin/" target="__blank" id="social-linked-in">
        <LinkedInIcon />
      </a>
      <a href="https://twitter.com/AgencyX_Dublin" target="__blank" id="social-twitter">
        <XTwitterIcon />
      </a>
      <a href="https://www.instagram.com/agency_x_dublin" target="__blank" id="social-instagram">
        <InstagramIcon />
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex !important;
  gap: 1.5rem;
  svg {
    width: 2rem;
    circle {
      transition: fill 100ms ease-in-out;
    }

    &:hover {
      circle {
        fill: ${({ invert }) => invert ? '#fff' : 'var(--ax-gold)'};
      }

      path {
        fill: ${({ invert }) => invert ? '#000' : '#fff'};
      }
    }

    &:not(:hover) {
      circle {
        fill: #000;
      }
    }

    &:active {
      circle {
        fill: var(--ax-beige);
      }
    }
  }
`;

export default SocialMedia;

import * as React from 'react';
const SvgSlide7 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M103.881 67.859c14.219 0 23.299-2.274 26.954-4.434 2.284-1.364 5.282-.653 6.682 1.535 2.198 3.439 6.767 7.588 13.591 7.588"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="m77.413 141.977 24.841-7.077v-14.124c-4.425-4.007-10.65-7.077-14.19-21.201-1.428-5.627-2.913-15.404-3.541-21.201-.742-6.65-.628-12.96 0-17.649C86.35 46.8 103.454 36 120.014 36c16.561 0 33.664 10.8 35.492 24.725.628 4.718.742 11.027 0 17.649-.657 5.769-2.142 15.574-3.541 21.2-3.54 14.125-9.794 17.195-14.191 21.202V134.9l24.841 7.077M151.966 153.446l11.907 18.984M76.157 172.43l11.907-18.984M180.347 102.574h14.191M59.682 102.575h-14.22"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M212.269 120.224c9.793 0 17.731-7.902 17.731-17.649s-7.938-17.648-17.731-17.648c-9.793 0-17.732 7.901-17.732 17.648s7.939 17.649 17.732 17.649ZM27.732 120.223c9.792 0 17.73-7.901 17.73-17.648s-7.938-17.649-17.73-17.649C17.939 84.926 10 92.828 10 102.575s7.939 17.648 17.732 17.648ZM173.236 205c9.793 0 17.732-7.902 17.732-17.649s-7.939-17.648-17.732-17.648c-9.792 0-17.731 7.901-17.731 17.648S163.444 205 173.236 205ZM66.762 205c9.793 0 17.732-7.902 17.732-17.649s-7.939-17.648-17.732-17.648-17.731 7.901-17.731 17.648S56.969 205 66.762 205Z"
      fill="#CE9200"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M66.764 74.297c-4.54 8.412-7.11 18.047-7.11 28.278 0 33.165 27.012 60.05 60.333 60.05s60.332-26.885 60.332-60.05a59.754 59.754 0 0 0-7.081-28.278"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide7;

import * as React from 'react';
const SvgPlayIcon = (props) => (
  <svg viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32.412 19.043a2 2 0 0 1 0 3.388L3.675 40.465C2.343 41.301.61 40.344.61 38.771V2.702c0-1.572 1.732-2.53 3.064-1.694l28.737 18.035Z"
      fill="#fff"
    />
  </svg>
);
export default SvgPlayIcon;

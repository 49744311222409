import React from 'react';
import styled, { css } from 'styled-components';
import { scrollToAnchor } from 'utils';
import { Link } from 'gatsby';

const Container = ({ children, to, style, href, invert, invertLight, offset, target = '__self' }) =>
  to ? (
    <GatsbyLink to={to} style={style} invert={invert} invertLight={invertLight}>
      {children}
    </GatsbyLink>
  ) : (
    <HTMLLink
      href={href}
      target={target}
      onClick={(e) => {
        if (!href.includes('https:')) {
          e.preventDefault();
          scrollToAnchor(href, offset);
        }
      }}
      style={style}
      invert={invert}
      invertLight={invertLight}>
      {children}
    </HTMLLink>
  );

export const LinkStyle = () => css`
  align-items: center;
  border-radius: 0.125em;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  height: 3em;
  justify-content: center;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
  pointer-events: auto;
  text-decoration: none;
  text-transform: uppercase;
  width: 10.375em;

  &:hover {
    background-color: ${({ invert, invertLight }) =>
      invert ? '#000' : invertLight ? 'transparent' : 'var(--ax-gold)'};
    color: #fff;

    :active {
      border: ${({ invertLight }) => (invertLight ? '0.063em solid #FFF' : '0.063em solid #000')};
      background-color: ${({ invertLight }) => (invertLight ? 'transparent' : '#000')};
      color: #fff;
      transition: background-color 75ms ease-in-out, border 75ms ease-in-out, color 75ms ease-in-out;
    }

    :not(:active) {
      border: ${({ invertLight }) => (invertLight ? '0.063em solid #FFF' : 'none')};
      transition: background-color 75ms ease-in-out, border 75ms ease-in-out, color 75ms ease-in-out;
    }
  }

  &:not(:hover) {
    :visited {
      color: ${({ invert }) => (invert ? '#fff' : 'var(--ax-gold)')};
    }

    :not(:active) {
      border: 0.063em solid
        ${({ invert, invertLight }) =>
          invert ? '#fff' : invertLight ? 'transparent' : 'var(--ax-gold)'};
      background-color: ${({ invertLight }) => (invertLight ? 'var(--ax-beige)' : 'transparent')};
      color: ${({ invert }) => (invert ? '#fff' : 'var(--ax-gold)')};
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out;
    }
  }
`;

const GatsbyLink = styled(Link)`
  ${({ invert }) => LinkStyle(invert)};
`;

const HTMLLink = styled.a`
  ${({ invert }) => LinkStyle(invert)};
`;

export default Container;

import React from 'react';
import styled from 'styled-components';

const SectionTitle = ({ children, position, maxWidth = '54.375em', capitalize }) => {
  return (
    <H3 maxWidth={maxWidth} position={position} capitalize={capitalize}>
      {children}
    </H3>
  );
};

const H3 = styled.h3`
  font-size: 2rem;
  font-weight: 900;
  grid-row: 1 / 2;
  letter-spacing: 0.0185em;
  margin: 1.85em auto 0.65em;
  text-transform: ${({ capitalize }) => (capitalize ? 'none' : 'lowercase')};
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ position }) => position};
`;

export default SectionTitle;

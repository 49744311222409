import * as React from 'react';

function SvgLonerX(props) {
  return (
    <svg viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M85.715 31.204L71.396 8.87 46.925 24.555 31.248.116l-22.32 14.28L24.65 38.882.178 54.566 14.496 76.9l24.426-15.685 15.723 24.44 22.273-14.28-15.676-24.486 24.473-15.684z"
        fill="#C1BEAC"
      />
    </svg>
  );
}

export default SvgLonerX;

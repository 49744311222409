import * as React from 'react';
const SvgSlide6 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M197.229 167.821V230H44.189v-62.179" fill="#CE9200" />
    <path
      d="M197.229 167.821V230H44.189v-62.179M44.189 53.057V153.48M197.23 53.057V153.48M139.831 167.821v-23.902l19.121-19.122c3.535-3.501 9.561-8.955 9.561-14.341V91.335c0-10.268-8.686-19.122-19.122-19.122v28.682l-14.341 14.342c-2.861 2.962-5.42 4.78-9.561 4.78h-23.902c-11.749 0-23.902 8.416-23.902 23.902v23.902"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M115.928 105.406c7.945 0 14.341-6.228 14.341-13.903v-5.42c0-7.676-6.396-13.904-14.341-13.904-7.945 0-14.341 6.228-14.341 13.904v5.42c0 7.675 6.396 13.903 14.341 13.903ZM96.806 143.919v23.902M25.066 167.821H216.35M173.293 186.943H68.09v23.902h105.203v-23.902ZM25.067 10v23.902c0 10.57 8.551 19.122 19.122 19.122 10.57 0 19.122-8.551 19.122-19.122 0 10.57 8.55 19.122 19.121 19.122 10.571 0 19.122-8.551 19.122-19.122 0 10.57 8.551 19.122 19.121 19.122 10.571 0 19.122-8.551 19.122-19.122 0 10.57 8.551 19.122 19.122 19.122 10.57 0 19.121-8.551 19.121-19.122 0 10.57 8.551 19.122 19.122 19.122s19.122-8.551 19.122-19.122V10H25h.067ZM63.311 29.122v4.814M101.587 29.122v4.814M139.83 29.122v4.814M178.107 29.122v4.814"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide6;

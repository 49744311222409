import * as React from 'react';
const SvgSlide2 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m42 32 20.5 52.5L75 121l12 4.5 36 44 16 43.5H40l2-181Z" fill="#CE9200" />
    <path
      d="M159.935 176.774v39.032c0 7.842-6.352 14.194-14.194 14.194H32.193C24.352 230 18 223.648 18 215.806v-74.515M18 137.741V24.193C18 16.352 24.352 10 32.194 10h78.064"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M96 31H39v128M39 163v50h100v-7.143"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="m106.462 91.931 7.629 18.452-27.677 11.461c-5.11 2.129-10.93-.319-13.058-5.394L63.81 93.386c-2.13-5.11.319-10.929 5.393-13.058l27.677-11.461 1.916 4.613"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M114.09 110.384s49.961-9.9 77.035 3.229L149.077 12.094c-8.906 30.729-52.196 56.774-52.196 56.774M164 45.195c8.114-1.245 16.294 3.555 19.598 12.16C186.87 65.96 185.544 75.167 179 80.5M86 125l32.704 39.922a7.461 7.461 0 0 0 8.658 2.164l20.261-8.374a4.984 4.984 0 0 0 2.696-6.529l-1.632-3.974c-.816-1.916-2.058-3.619-3.69-4.932l-17.671-14.265c-2.803-2.235-5.145-4.967-6.99-8.055l-6.246-10.538M197.798 51.41l13.838-5.749M208.867 78.909l13.839 5.748M186.158 24.122l5.749-13.874"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide2;

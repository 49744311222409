import React from 'react';
import { CaseStudyPreviews, SectionTitle, Link } from 'components';
import styled from 'styled-components';

const RecentWork = ({ caseStudies }) => {
  return (
    <Container>
      <div>
        <SectionTitle maxWidth="49.281em">Featured Work</SectionTitle>
        <CaseStudyPreviews caseStudies={caseStudies} />
        <Link
          to="/our-work/"
          style={{
            marginTop: '2.95rem',
            marginLeft: '50%',
            transform: 'translateX(-50%)'
          }}
          id="see-more-work">
          See More Work
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background-color: var(--ax-beige);
  margin-bottom:5rem;
  > div {
    grid-column: 2 / 3;
    @media screen and (min-width: 48rem) {
      grid-column: 1 / -1;
      margin: 0 1.25rem;
    }
    @media screen and (min-width: 76.875rem) {
      margin: 0 3rem
    }
    @media screen and (min-width: 114.75rem) {
      max-width: 108.75rem;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default RecentWork;

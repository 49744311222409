import * as React from 'react';

function SvgBenefitsX(props) {
  return (
    <svg viewBox="0 0 265 317" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M177.823 115.785c-10.385 9.261-11.264 25.258-1.96 35.686 9.303 10.427 25.3 11.375 35.685 2.115 10.385-9.261 11.264-25.258 1.96-35.686-9.303-10.427-25.3-11.376-35.685-2.115zm23.375 26.2c-4.024 3.588-10.177 3.224-13.782-.817-3.605-4.041-3.267-10.193.757-13.782 4.024-3.589 10.177-3.224 13.782.817 3.605 4.041 3.267 10.193-.757 13.782zM121.325 93.725l-9.888 22.443-24.802-10.644-10.708 24.639-22.541-9.662 10.806-24.865-24.865-10.807 9.888-22.443 24.64 10.708 10.87-24.703 22.541 9.662-10.807 24.865 24.866 10.807zM155.098 175.319l15.29 31.968-34.916 16.747 16.656 35.064-31.969 15.29-16.655-35.064-35.008 16.895-15.29-31.968 34.916-16.747-16.804-35.156 31.969-15.29 16.804 35.156 35.007-16.895z"
        fill="#C1BEAC"
        fillOpacity={0.2}
      />
    </svg>
  );
}

export default SvgBenefitsX;

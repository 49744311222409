import * as React from 'react';
const SvgSlide10 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M230 32H10v29h220V32Z" fill="#CE9200" />
    <path
      d="M230 32H10v176h220V32ZM215.652 184H24.348M10 60.54h220M24.348 46.27h9.565M38.696 46.27h9.565M53.044 46.27h9.565"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M53.044 179.459v-42.81h19.13v42.81M91.304 179.46v-71.352h19.131v71.352M148.696 179.459v-52.324h-19.131v52.324M186.957 179.46V93.838h-19.131v85.622"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide10;

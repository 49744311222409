import * as React from 'react';
const SvgAgencyXLogo = (props) => (
  <svg viewBox="0 0 191 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M37.087 0c3.468 0 5.987 1.41 7.906 3.624l-1.357.98c-1.472-1.783-3.875-2.958-6.549-2.958-4.941 0-8.758 3.78-8.758 9.325 0 5.485 3.817 9.364 8.758 9.364 2.81 0 5.019-1.41 6.181-2.586v-5.035h-8.7l.717-1.665h9.766v7.425A10.642 10.642 0 0 1 37.087 22c-5.93 0-10.657-4.467-10.657-11.03C26.43 4.428 31.158 0 37.087 0ZM81.653 3.252v18.336h-1.802V.353h1.822l13.176 18.081V.354h1.802v21.235h-1.763L81.653 3.253ZM106.94 10.99c0-6.523 4.689-10.99 10.58-10.99 3.41 0 5.929 1.489 7.712 3.761l-1.512.98c-1.317-1.842-3.623-3.095-6.2-3.095-4.883 0-8.7 3.78-8.7 9.325 0 5.485 3.817 9.325 8.7 9.325 2.577 0 4.883-1.235 6.2-3.096l1.512.96c-1.86 2.331-4.321 3.781-7.712 3.781-5.891.04-10.58-4.427-10.58-10.95ZM140.52 12.518 132.168.353h2.171l7.091 10.441L148.522.353h2.17l-8.351 12.165v9.07h-1.821v-9.07ZM10.832.353H8.603L0 21.588h2.015L9.708 2.136l5.038 12.577h-6.24l-.678 1.666h7.48l2.112 5.21h2.015L10.832.354ZM69.427.353h-14.03v1.665h14.03V.353ZM65.823 9.521H55.398v1.646h9.708l.717-1.646ZM69.427 19.943h-14.03v1.665h14.03v-1.665Z"
      fill="#000"
    />
    <path d="m179.021 11.95-7.537 9.678h2.267l6.375-8.365-1.105-1.313Z" fill="#CE9200" />
    <path
      d="M182.218 10.677 190.202.353h-2.267l-6.84 9.011-6.802-9.011h-2.306l16.451 21.235h2.229l-8.449-10.911Z"
      fill="#000"
    />
  </svg>
);
export default SvgAgencyXLogo;

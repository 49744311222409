import * as React from 'react';
const SvgAiBot = (props) => (
  <svg viewBox="0 0 177 251" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M175.772 163.992c-2.079 14.308-16.447 55.436-16.447 55.436-.879 2.959-5.239 2.326-5.239-.76V117.316c0-6.106 4.951-11.058 11.057-11.058 5.957 0 10.847 4.722 11.052 10.676.473 13.815.998 37.274-.423 47.058ZM.922 163.992c2.08 14.308 16.447 55.436 16.447 55.436.88 2.959 5.24 2.326 5.24-.76V117.316c0-6.106-4.952-11.058-11.057-11.058-5.958 0-10.847 4.722-11.053 10.676-.472 13.815-.998 37.274.423 47.058ZM149.978 61.637C149.978 27.597 122.384 0 88.348 0 54.31 0 26.716 27.597 26.716 61.637v59.994c0 .908.735 1.643 1.643 1.643h119.974c.908 0 1.644-.735 1.644-1.643V61.637ZM26.717 129.027v59.994c0 34.04 27.594 61.637 61.63 61.637 34.037 0 61.631-27.597 61.631-61.637v-59.994c0-.908-.736-1.644-1.644-1.644H28.36c-.908 0-1.643.736-1.643 1.644Z"
      fill="#fff"
    />
    <path
      d="M136.057 61.637c0-26.352-21.361-47.715-47.71-47.715s-47.71 21.363-47.71 47.715c0 10.347 8.386 18.73 18.727 18.73h57.962c10.345 0 18.727-8.387 18.727-18.73h.004Z"
      fill="#0F88C1"
    />
    <path
      d="M70.828 55.54a8.395 8.395 0 0 0 8.394-8.396 8.395 8.395 0 1 0-16.788 0 8.394 8.394 0 0 0 8.394 8.395ZM105.867 55.54a8.394 8.394 0 0 0 8.394-8.396 8.394 8.394 0 1 0-16.788 0 8.395 8.395 0 0 0 8.394 8.395Z"
      fill="#30F1FE"
    />
  </svg>
);
export default SvgAiBot;

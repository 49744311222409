import * as React from 'react';

function SvgLinkedinIcon(props) {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <circle cx={16} cy={16} r={16} fill="#000" />
      <path
        d="M12.448 22.24v-8.159H10v8.16h2.448zm0-11.072c0-.729-.48-1.263-1.296-1.263-.72 0-1.248.534-1.248 1.263 0 .728.48 1.262 1.248 1.262.816.049 1.296-.534 1.296-1.262zm4.08 11.072v-4.662c0-.243 0-.486.096-.68.191-.486.623-1.02 1.391-1.02 1.008 0 1.584.583 1.584 1.7v4.662h2.496v-4.76c0-2.573-1.344-3.739-3.12-3.739-1.488 0-2.112.826-2.495 1.409V14.08h-2.448c.048.777 0 8.208 0 8.208h2.495v-.049z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLinkedinIcon;

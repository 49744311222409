import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import mailIconSVG from 'images/shared/mail_icon.svg';

const ContactUsButton = ({ style, menuIsOpen, setMenuIsOpen, pathname }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.375, delay: 0.375 }}>
      <Link
        to="/contact/"
        id="contact-us-sticky-button"
        onClick={() => menuIsOpen && setMenuIsOpen(false)}
        style={{ textDecoration: 'none', display: 'inline-block', ...style }}>
        <Container className="contact-button" pathname={pathname}>
          <div>Contact Us</div>
          <MailIcon>
            <img src={mailIconSVG} alt="mail icon" />
          </MailIcon>
        </Container>
      </Link>
    </motion.div>
  );
};

const MailIcon = styled.div`
  align-items: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.125);
  display: flex;
  height: 48px;
  justify-content: center;
  transition: border-radius 400ms ease-in-out;
  width: 48px;

  img {
    width: 1.625rem;
  }
`;

const Container = styled.div`
  bottom: ${({ pathname }) => (pathname === '/logobook/' ? '3.05rem' : '1.25rem')};
  cursor: pointer;
  display: grid;
  grid-template-columns: 10.375rem 3rem;
  grid-template-rows: 3rem;
  perspective-origin: 166px;
  perspective: 550px;
  position: fixed;
  right: 1.25rem;
  z-index: 1;

  @media screen and (min-width: 48rem) {
    right: 3rem;
  }

  > div:first-of-type {
    transition: 400ms transform ease-in-out;
    transform-origin: right;
    align-items: center;
    background-color: #000;
    border-radius: 0.125rem 0 0 0.125rem;
    color: #fff;
    display: flex;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
  }

  rect {
    transition: fill 400ms ease-in-out;
  }

  path {
    transition: stroke 400ms ease-in-out;
  }

  &:hover {
    > div:first-of-type {
      transform: rotate3d(0, 1, 0, 0deg);
    }

    rect {
      fill: var(--ax-gold);
    }

    ${MailIcon} {
      background-color: var(--ax-gold);
      border-radius: 0 0.125rem 0.125rem 0;
    }
  }

  &:not(:hover) {
    > div:first-of-type {
      transform: rotate3d(0, 1, 0, -90deg);
    }

    rect {
      background-color: #000;
    }

    ${MailIcon} {
      background-color: #000;
      border-radius: 0.125rem;
    }
  }
`;

export default ContactUsButton;

import * as React from 'react';
const SvgEventXLogo = (props) => (
  <svg viewBox="0 0 48 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.9497 59H14.2377V50.328H17.3417V48.328H8.82969V50.328H11.9497V59ZM17.8956 53.672C17.8956 56.888 20.2476 59.192 23.4636 59.192C26.6956 59.192 29.0476 56.888 29.0476 53.672C29.0476 50.456 26.6956 48.152 23.4636 48.152C20.2476 48.152 17.8956 50.456 17.8956 53.672ZM26.7116 53.672C26.7116 55.656 25.4316 57.176 23.4636 57.176C21.4956 57.176 20.2316 55.656 20.2316 53.672C20.2316 51.672 21.4956 50.168 23.4636 50.168C25.4316 50.168 26.7116 51.672 26.7116 53.672ZM30.6419 59H32.9139V55.176H35.6339C37.9379 55.176 39.2179 53.624 39.2179 51.768C39.2179 49.896 37.9539 48.328 35.6339 48.328H30.6419V59ZM36.8979 51.752C36.8979 52.648 36.2259 53.224 35.3299 53.224H32.9139V50.28H35.3299C36.2259 50.28 36.8979 50.872 36.8979 51.752Z"
      fill="#CE9200"
    />
    <path
      d="M30.4848 19.7489L32 18.1103L24.5 10L17 18.1103L18.5152 19.7489L23.4287 14.4356L23.4287 37L25.5715 37L25.5715 14.4358L30.4848 19.7489Z"
      fill="#CE9200"
    />
    <rect x="17" y="36" width="15" height="2" fill="#CE9200" />
  </svg>
);
export default SvgEventXLogo;

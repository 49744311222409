import React from 'react';
import styled from 'styled-components';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import {
  Lines,
  Dots1,
  Dots2,
  OrangeSpray,
  LightBlueSpray
} from '../Hero/AnimatedSVGs';
import { BabyPinkSpray, DarkBlueSpray } from './AnimatedSVGs';

const WereSpecialists = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <RenderWhenReady>
        <Underlay>
          <div>
            {windowWidth > 1024 && (
              <>
                <StyledSVG
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}>
                  <Lines />
                </StyledSVG>
                <StyledSVG
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}>
                  <Dots1 />
                </StyledSVG>
                <StyledSVG
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}>
                  <Dots2 />
                </StyledSVG>
              </>
            )}
            <StyledSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <BabyPinkSpray />
            </StyledSVG>
            <StyledSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <OrangeSpray />
            </StyledSVG>
            <StyledSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <DarkBlueSpray />
            </StyledSVG>
          </div>
        </Underlay>
        {windowWidth > 768 && (
          <>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <DarkBlueSpray />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <LightBlueSpray />
            </AnimatedSVG>
          </>
        )}
      </RenderWhenReady>
      <h3>Omnichannel. We&apos;re Specialists.</h3>
      <p>
        We base our Strategy on Omnichannel methodologies.
        <br />
        Learn how you can apply an Omnichannel approach for your campaigns here.
      </p>
      <button type="button">
        <Link to="/omnichannel-healthcare-campaigns/" id="find-out-more-omnichannel">
          Find Out More
        </Link>
      </button>
    </Container>
  );
};

export const AnimatedSVG = styled(motion.div)`
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const StyledSVG = styled(motion.div)`
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  gap: 1.325rem;
  height: auto;
  padding: 2.5rem 2rem;
  position: relative;
  text-align: center;
  transition: 0.75s;
  width: 100%;

  @media (min-width: 1150px) {
    padding: 2.5rem 3rem;
  }

  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      bottom: -90px;
      left: -70px;
      width: 100px;
      @media (min-width: 1150px) {
        bottom: -90px;
        left: -70px;
        width: 255px;
      }
      svg {
        animation: pulse 2s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(2) {
      bottom: -60px;
      left: -50px;
      width: 150px;
      @media (min-width: 1150px) {
        bottom: -90px;
        left: -70px;
        width: 255px;
      }
      svg {
        animation: pulse 2s ease-in-out infinite alternate-reverse;
      }
    }
    :nth-child(3) {
      display: none;
      @media (min-width: 1150px) {
        display: block;
        bottom: 60%;
        width: 115px;
        right: -70px;
      }
      svg {
        animation: none;
      }
    }
  }
  h3 {
    z-index: 2;
    font-family: 'Butler';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
    @media (min-width: 1150px) {
      font-size: 3rem;
      line-height: 3.75rem;
    }
  }
  p {
    z-index: 2;
    font-family: "proxima-nova";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    @media (min-width: 1150px) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
  button {
    z-index: 2;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 1.125rem 1.25rem;
    background: var(--ax-gold);
    border-radius: 2px;
    color: var(--ax-beige);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    transition: 0.375s;
    cursor: pointer;
    &:hover,
    &:focus {
      border: none;
      outline: none;
      background: var(--ax-black);
    }
    a {
      color: white;
      height: 100%;
      width: 100%;
      text-decoration: none;
    }
  }
`;

const Underlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    ${StyledSVG} {
      position: absolute;
      z-index: 1;
      :nth-child(1) {
        width: 289px;
        top: 15px;
        right: -20px;
        @media (min-width: 1150px) {
          top: -50px;
          right: -200px;
          width: 50%;
          height: auto;
        }
      }
      :nth-child(2) {
        top: 100px;
        right: 0px;
        width: 30px;
        z-index: 2;
        @media (min-width: 1150px) {
          top: -50px;
          right: 100px;
          width: 20%;
          height: auto;
        }
      }
      :nth-child(3) {
        top: 20px;
        right: -20px;
        width: 110px;
        @media (min-width: 1150px) {
          top: -50px;
          right: 100px;
          width: 20%;
          height: auto;
        }
      }
      :nth-child(4) {
        width: 289px;
        top: 15px;
        right: -20px;
      }
      :nth-child(5) {
        width: 62px;
        top: 50%;
        right: -20px;
      }
      :nth-child(6) {
        width: 100px;
        right: 75px;
        top: 60%;
      }
    }
  }
`;

export default WereSpecialists;

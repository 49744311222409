import * as React from 'react';
const SvgSlide1 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m119.033 29.704 33.413 14.244v38.337L119.033 96.53 85.62 82.285V43.948l33.413-14.244ZM162.821 100.575l33.414 14.42v38.162l-33.414 14.42-33.413-14.42v-38.162l33.413-14.42ZM76.827 100.575l33.413 14.42v38.162l-33.413 14.42-33.414-14.42v-38.162l33.414-14.42Z"
      fill="#CE9200"
    />
    <path
      d="M138.905 191.494h-38.161M91.247 191.494H10v4.748c0 7.914 6.33 14.421 14.42 14.421h191.16c7.913 0 14.42-6.331 14.42-14.421v-4.748h-81.423M86.323 47.993H33.917c-7.914 0-14.42 6.33-14.42 14.42v129.081M220.152 191.494V62.414c0-7.915-6.331-14.421-14.42-14.421h-52.407M19.496 67.162h66.827M153.325 67.162h66.827"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="m86.323 43.245 33.589 14.42 33.413-14.42"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m86.323 81.582 33.589 14.245 33.413-14.245V43.245L119.912 29l-33.59 14.245v38.337Z"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M119.912 95.826V57.665M43.413 114.995l33.414 14.245 33.413-14.245"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m43.413 153.157 33.414 14.42 33.413-14.42v-38.162l-33.413-14.42-33.414 14.42v38.162Z"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M76.827 129.24v38.337M129.408 114.995l33.413 14.245 33.414-14.245"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m129.408 153.157 33.413 14.42 33.414-14.42v-38.162l-33.414-14.42-33.413 14.42v38.162Z"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M162.822 129.24v38.337"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgSlide1;

import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql, Link } from 'gatsby';

const HcpEngageCaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      parkinsonsImage: file(relativePath: { eq: "caseStudyImgs/parkinsons.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
        }
      }
      retinaImage: file(relativePath: { eq: "caseStudyImgs/retina-20.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
        }
      }
      afibImage: file(relativePath: { eq: "caseStudyImgs/afib-campaign.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
        }
      }
      iphaImage: file(relativePath: { eq: "caseStudyImgs/ipha.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
        }
      }
    }
  `);
  return (
    <Cases
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}
       >
      <Case to="/our-work/healthcare-communications-retina-2020/">
        <GatsbyImage
          image={getImage(data.retinaImage)}
          alt="Virtual Events For Business Ireland- Case Study Retina 20"
        />
        <h4>Retina 2020 - Virtual Event Conference</h4>
        <h5>Fighting Blindness</h5>
      </Case>
      <Case to="/our-work/virtual-event-parkinsons-media/">
        <GatsbyImage
          image={getImage(data.parkinsonsImage)}
          alt="Virtual Events For Business Ireland- Case Study Global Dairy Tech Spotlight"
        />
        <h4>Parkinson&apos;s Virtual Conference 2021</h4>
        <h5>Parkinson&apos;s Association of Ireland</h5>
      </Case>
      <Case to="/our-work/online-engagement-campaign-ipha-forum/">
        <GatsbyImage
          image={getImage(data.iphaImage)}
          alt="The Forum Digital Campaign"
        />
        <h4>The Forum Digital Campaign</h4>
        <h5>IPHA</h5>
      </Case>
      <Case to="/our-work/social-media-campaign-strategy-pfizer-bristol-myers-squibb/">
        <GatsbyImage
          image={getImage(data.afibImage)}
          alt="The Forum Digital Campaign"
        />
        <h4>Atrial Fibrillation Awareness Campaign</h4>
        <h5>Pfizer & Bristol Myers Squibb</h5>
      </Case>
    </Cases>
  );
};

const Cases = styled(motion.div)`
  transition: 0.75s;
  grid-column: 1/6;
  display: grid;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 450px), 1fr));
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Case = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration:none;
  height: 100%;
  .gatsby-image-wrapper{
    height: 100% !important;
  }
  h4,
  h5 {
    font-size: 1.25rem !important;
    line-height: 1.25rem;
    text-align: left;
    @media (min-width: 1150px) {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
  h4 {
    color: var(--ax-pink);
    margin-top: 1rem;
    margin-bottom: 0.55rem !important;
    line-height: 1em;
  }
  h5 {
    color: black;
    font-weight: normal;
    margin-bottom: 0.5rem;
    margin-top:0;
  }
`;

export default HcpEngageCaseStudies;

import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';

const AXCases = () => {
  const data = useStaticQuery(graphql`
    query {
      iphaConference: file(relativePath: { eq: "caseStudyImgs/ipha-conference.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      janssenImage: file(relativePath: { eq: "caseStudyImgs/janssen.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <Cases
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}>
          <Case href="https://www.agencyx.ie/our-work/ipha-conference-2024/" target="blank">
            <GatsbyImage image={getImage(data.iphaConference)} alt="IPHA Conference 2024" />
            <h4>IPHA Conference 2024</h4>
            <h5>Irish Pharmaceutical Healthcare Association</h5>
          </Case>
          <Case
            href="https://www.agencyx.ie/our-work/precision-oncology-ireland-janssen-event/"
            target="blank">
            <GatsbyImage image={getImage(data.janssenImage)} alt="POI Janssen Event" />
            <h4>POI Janssen Event</h4>
            <h5>Janssen</h5>
          </Case>
    </Cases>
  );
};

const Cases = styled(motion.div)`
  transition: 0.75s;
  grid-column: 1/6;
  display: grid;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Case = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  h4,
  h5 {
    font-size: 1.25rem !important;
    line-height: 1.25rem;
    text-align: left;
    @media (min-width: 1150px) {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
  h4 {
    color: var(--ax-pink);
    margin-top: 1rem;
    margin-bottom: 0.55rem !important;
    line-height: 1em;
  }
  h5 {
    color: black;
    font-weight: normal;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
`;

export default AXCases;

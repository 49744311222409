import * as React from 'react';
const SvgStar = (props) => (
  <svg viewBox="0 0 148 149" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m148 70.897-37.201 7.416 34.579 15.611-37.673-4.452 28.06 25.531-34.461-15.871 18.801 32.958-27.87-25.743 7.7 37.151-18.554-33.089-4.157 37.706-7.416-37.21-15.613 34.577 4.44-37.671-25.532 28.058 15.872-34.458-32.95 18.8 25.734-27.87-37.141 7.7 33.09-18.551L0 77.333l37.2-7.416L2.635 54.306l37.673 4.44-28.06-25.52 34.449 15.86-18.79-32.946 27.86 25.743-7.688-37.15 18.54 33.088L70.789.115l7.416 37.21L93.805 2.748l-4.44 37.67 25.532-28.057-15.872 34.458 32.949-18.8-25.733 27.87 37.141-7.7-33.091 18.551L148 70.897Z"
      fill="#CE9200"
    />
  </svg>
);
export default SvgStar;

import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

const StaticCaseStudies = ({ caseStudies, color }) => {
  return (
    <Cases
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}>
      {caseStudies.map((preview, i) => (
        <Case key={i} to={preview.slug} target="blank" $color={color}>
          <GatsbyImage image={getImage(preview.image)} alt={preview.title} />
          <h4 dangerouslySetInnerHTML={{ __html: preview.title }} />
          <h5>{preview.company}</h5>
        </Case>
      ))}
    </Cases>
  );
};

const Cases = styled(motion.div)`
  transition: 0.75s;
  grid-column: 1/6;
  display: grid;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 3rem;
  @media (min-width: 48rem) {
    margin-bottom: 0;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Case = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  h4,
  h5 {
    font-size: 1.25rem !important;
    line-height: 1.25rem;
    text-align: left;
    color: ${({ $color }) => ($color ? `${$color}` : 'var(--ax-gold)')};
    @media (min-width: 1150px) {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
  h4 {
    margin-top: 1rem;
    margin-bottom: 0.55rem !important;
    line-height: 1em;
  }
  h5 {
    color: black;
    font-weight: normal;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
`;

export default StaticCaseStudies;

import * as React from 'react';
const SvgSlide9 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 136.579V79.316C22 71.442 28.567 65 36.593 65h167.814C212.433 65 219 71.442 219 79.316v107.368c0 7.874-6.567 14.316-14.593 14.316H36.593C28.567 201 22 194.558 22 186.684v-28.631"
      fill="#CE9200"
    />
    <path
      d="M22 136.579V79.316C22 71.442 28.567 65 36.593 65h167.814C212.433 65 219 71.442 219 79.316v107.368c0 7.874-6.567 14.316-14.593 14.316H36.593C28.567 201 22 194.558 22 186.684v-28.631"
      stroke="#000"
      strokeWidth={8}
      strokeMiterlimit={10}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path d="M49 109h68M49 125h68M49 141h68M174.5 65l-79-40" stroke="#000" strokeWidth={8} />
    <circle cx={168} cy={134} r={31} fill="#EFEDE4" stroke="#000" strokeWidth={8} />
  </svg>
);
export default SvgSlide9;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { BigBackgroundSectionTitle } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useScrollPercentage } from 'react-scroll-percentage';

const getJSX = (node, index, percentage, ref, pathname) => {
  const { company, whatWeDid, slug, previewImage } = node;
  const [hovered, setHovered] = useState(false);
  return (
    <Link to={`/our-work/${slug.current}/`} key={slug.current}>
      <CaseStudyPreview
        ref={ref}
        index={index}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        slideIn={percentage > 0 || pathname === '/our-work/'}
        showOverlay={
          isMobileOnly
            ? percentage > 0.4 && percentage < 0.8
            : isTablet
            ? percentage > 0.5 && percentage < 0.8
            : hovered
        }>
        <GatsbyImage image={getImage(previewImage.asset)} alt={previewImage.alt} />
        <Overlay>
          <p dangerouslySetInnerHTML={{ __html: company }} />
          <h3>{whatWeDid}</h3>
          <p>See Case Study</p>
          <Seperator />
        </Overlay>
      </CaseStudyPreview>
    </Link>
  );
};

const CaseStudyPreviews = ({ caseStudies, pathname }) => (
  <Container id="#case-studies">
    <Grid centerLastCaseStudy={caseStudies.length % 2 !== 0}>
      {caseStudies.map(({ node }, index) => {
        const [ref, percentage] = useScrollPercentage();
        return getJSX(node, index, percentage, ref, pathname);
      })}
    </Grid>
  </Container>
);

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.275rem;

  @media screen and (min-width: 64em) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0;

    > a:last-of-type {
      ${({ centerLastCaseStudy }) =>
        centerLastCaseStudy &&
        css`
          position: relative;
          left: 50%;
        `};
    }
  }
`;

const CaseStudyPreview = styled.div`
  overflow: hidden;
  position: relative;
  opacity: 0;
  transform: ${({ index }) =>
    index % 2 === 0 ? `translate3d(-300px, 0, 0)` : `translate3d(300px, 0, 0)`};
  height: 100%;

  > div {
    height: 100%;
  }

  ${({ slideIn }) =>
    slideIn &&
    css`
      opacity: 1;
      transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;
      transform: translate3d(0, 0, 0);
    `};

  ${({ showOverlay }) =>
    showOverlay
      ? css`
          > div:last-of-type {
            opacity: 1;
          }
        `
      : css`
          > div:last-of-type {
            opacity: 0;
          }
        `}
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 1em;
  position: absolute;
  top: 0;
  transition: opacity 400ms ease-in-out;
  width: 100%;

  p {
    font-size: 0.75rem;
  }

  p:first-of-type {
    color: #fff;
    letter-spacing: 0.01em;
    margin-bottom: 0.325em;
  }

  p:last-of-type {
    color: var(--ax-gold);
    font-weight: 700;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  h3 {
    color: var(--ax-gold);
    font-size: 1.325rem;
    font-weight: 700;
    margin-bottom: 0.325em;
  }

  @media screen and (min-width: 48rem) {
    p {
      font-size: 1rem;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 0.25em;
    }
  }
`;

const Seperator = styled.div`
  background-color: var(--ax-black);
  height: 0.063rem;
  width: 100%;
`;

export default CaseStudyPreviews;

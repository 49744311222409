import * as React from 'react';
const SvgDrag = (props) => (
  <svg viewBox="0 0 62 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={13} cy={13} r={13} fill="#000" />
    <circle cx={49} cy={13} r={13} fill="#000" />
    <circle cx={13} cy={49} r={13} fill="#000" />
    <circle cx={49} cy={49} r={13} fill="#000" />
    <circle cx={13} cy={85} r={13} fill="#000" />
    <circle cx={49} cy={85} r={13} fill="#000" />
  </svg>
);
export default SvgDrag;

import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { SocialMedia, RenderWhenReady } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import GoldAward from 'images/footer/footer-award-gold.png';
import SilverAward from 'images/footer/footer-award-silver.png';
import {
  CaseStudyPreview,
  PreviewLink,
  SeeMore,
  Seperator,
  caseStudyPreviewVariants
} from 'components/Shared/OtherCaseStudies';

const Menu = ({ menuIsOpen, setMenuIsOpen, userHasScrolled, menuRef }) => {
  const { windowWidth, windowHeight } = useWindowSize();

  // The below useEffect hook sets a CSS variable which solves a menu positioning bug on Windows
  // https://stackoverflow.com/a/57748743
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.documentElement.clientWidth}px`
    );
  }, []);

  const { sanityCaseStudy: highlightedCaseStudy } = useStaticQuery(graphql`
    query {
      sanityCaseStudy(highlightedCaseStudy: { eq: true }) {
        title
        company
        whatWeDid
        slug {
          current
        }
        previewImage {
          asset {
            gatsbyImageData(layout: FIXED, width: 340, height: 224, formats: AUTO)
            altText
          }
        }
      }
    }
  `);

  const { previewImage, company, whatWeDid, alt, slug } = highlightedCaseStudy;

  return (
    <RenderWhenReady>
      <Container
        $navHeight={windowHeight}
        ref={menuRef}
        $menuIsOpen={menuIsOpen}
        userHasScrolled={userHasScrolled}>
        <NavAndHighLightedCaseStudyWrapper>
          <Nav $menuIsOpen={menuIsOpen}>
            {links.map((link, i) => (
              <StyledLink
                onClick={(e) => {
                  e.preventDefault();
                  setMenuIsOpen(false);
                  navigate(link.url);
                }}
                $menuIsOpen={menuIsOpen}
                key={i}
                index={i + 1}
                to={link.url}
                id={`menu-${link.name.toLowerCase().replaceAll(' ', '-')}`}>
                {link.name}
              </StyledLink>
            ))}
          </Nav>
          {/* {windowWidth >= 1024 && (
            <HighlightedCaseStudy $menuIsOpen={menuIsOpen} style={{ maxWidth: '21.25rem' }}>
              <StyledPreviewLink
                to={`/our-work/${slug.current}`}
                onClick={() => {
                  setMenuIsOpen(false);
                }}
                highlighedCaseStudy>
                <h4>Highlighted Case Study</h4>
                <CaseStudyPreview
                  highlighedCaseStudy
                  variants={caseStudyPreviewVariants}
                  whileHover="hover"
                  whileTap="tap"
                  layout>
                  <GatsbyImage image={previewImage.asset.gatsbyImageData} alt={alt} />
                  <p>{company}</p>
                  <motion.h4 variants={caseStudyPreviewVariants}>{whatWeDid}</motion.h4>
                  <SeeMore variants={caseStudyPreviewVariants}>
                    See case study
                    <Seperator highlighedCaseStudy />
                  </SeeMore>
                </CaseStudyPreview>
              </StyledPreviewLink>
            </HighlightedCaseStudy>
          )} */}
          {windowWidth >= 1024 && (
            <OurAwards $menuIsOpen={menuIsOpen} style={{ maxWidth: '21.25rem' }}>
              <h4>Our Awards</h4>
              <img src={GoldAward} alt="Award" />
              <img src={SilverAward} alt="Award" />
            </OurAwards>
          )}
        </NavAndHighLightedCaseStudyWrapper>
        <ContactUsAndSocialMedia $menuIsOpen={menuIsOpen}>
          {/* <ContactUsButton
            isVisible
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
            style={{ marginBottom: '2.5rem' }}
          /> */}
          <SocialMedia invert />
        </ContactUsAndSocialMedia>
      </Container>
    </RenderWhenReady>
  );
};

const NavAndHighLightedCaseStudyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 43%;
  transform: translateY(-43%);

  padding: 0 0.313em;

  @media screen and (min-width: 48rem) {
    padding: 0 5.75%;
  }

  @media screen and (min-width: 90rem) {
    padding: 0 8.6%;
  }

  @media screen and (min-width: 90.063rem) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 120rem) {
    margin: 0 auto;
    max-width: 99.125rem;
    padding: 0;
    position: relative;
    width: 100%;
  }
`;

const OurAwards = styled.div`
  position: relative;
  right: 1.8rem;
  display: flex;
  align-self: start;
  margin-top: 1rem;
  flex-direction: column;
  gap: 1.4rem;
  ${({ $menuIsOpen }) =>
    $menuIsOpen
      ? css`
          opacity: 1;
          transition: opacity 500ms ${5 * 140 + 187.5}ms ease-in-out;
        `
      : css`
          opacity: 0;
          transition: opacity 187.5ms ease-in-out;
        `};
  h4 {
    text-transform: uppercase;
    color: white;
    font-weight: 800;
    margin-left: 2rem;
  }
  @media screen and (min-width: 90.063rem) {
    left: 19.875rem;
    right: 0;
  }
`;

const HighlightedCaseStudy = styled.div`
  position: relative;
  right: 1.8rem;

  @media screen and (min-width: 90.063rem) {
    left: 19.875rem;
    right: 0;
  }

  ${({ $menuIsOpen }) =>
    $menuIsOpen
      ? css`
          opacity: 1;
          transition: opacity 500ms ${5 * 140 + 187.5}ms ease-in-out;
        `
      : css`
          opacity: 0;
          transition: opacity 187.5ms ease-in-out;
        `};
`;

const StyledPreviewLink = styled(PreviewLink)`
  width: 100%;

  h4 {
    margin-bottom: 1.4rem;
  }
`;

const links = [
  {
    name: 'Home',
    url: '/'
  },
  {
    name: 'Services',
    url: '/#services'
  },
  {
    name: 'Omnichannel',
    url: '/omnichannel-healthcare-campaigns/'
  },
  {
    name: 'Our Work',
    url: '/our-work/'
  },
  {
    name: 'About Us',
    url: '/about-us/'
  },
  {
    name: 'Contact',
    url: '/contact/'
  }
];

const bounce = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -16px, 0);
  }

  100% {
    transform: translate3d(0, 0 ,0);
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContactUsAndSocialMedia = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1.25rem;
  opacity: 0;

  ${({ $menuIsOpen }) =>
    $menuIsOpen
      ? css`
          pointer-events: auto;
          opacity: 1;
          transition: opacity 500ms ${5 * 140 + 187.5}ms ease-in-out;
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transition: opacity 187.5ms ease-in-out;
        `};

  @media screen and (min-width: 48rem) {
    bottom: 3.5rem;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1.219em;
  text-decoration: none;
  opacity: 0;

  ${({ $menuIsOpen, index }) =>
    $menuIsOpen
      ? css`
          pointer-events: auto;
          opacity: 1;
          animation: ${bounce} 500ms ${index * 140 + 187.5}ms ease-in-out;
          transition: opacity 200ms ${index * 140 + 187.5}ms ease-in-out;
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transition: opacity 187.5ms ease-in-out;
        `};

  @media screen and (min-width: 26rem) {
    font-size: 4rem;
  }

  @media screen and (min-width: 48rem) {
    font-size: 4.5rem;
    font-weight: 900;
  }

  @media screen and (min-width: 1920px) {
    /* margin: 0 auto; */
    /* width: 1579px; */
  }
`;

const fadeIn = keyframes`
  to {
    pointer-events: auto;
    opacity: 1;
  }
`;

const Container = styled.div`
  background-color: var(--ax-gold);
  display: flex;
  flex-direction: column;
  height: ${({ $navHeight }) => `${$navHeight}px`};
  left: 0;
  position: fixed;
  transition: clip-path 375ms ease-in-out, top 375ms ease-in-out;
  width: 100vw;
  z-index: 1;
  opacity: 1;

  ${({ userHasScrolled, $menuIsOpen }) =>
    userHasScrolled && !$menuIsOpen
      ? css`
          top: -18px;
        `
      : css`
          top: 0;
        `}

  ${({ $menuIsOpen }) =>
    $menuIsOpen
      ? css`
          clip-path: polygon(0vw 0vh, 100vw 0vw, 100vw 100vh, 0vw 100vh);
        `
      : css`
          pointer-events: none;
          clip-path: polygon(
            calc(100% - 2.5rem - var(--scrollbar-width)) 3.063rem,
            calc(100% - 1.25em - var(--scrollbar-width)) 3.063rem,
            calc(100% - 1.25em - var(--scrollbar-width)) 3.188rem,
            calc(100% - 2.5rem - var(--scrollbar-width)) 3.188rem
          );

          @media screen and (min-width: 48rem) {
            clip-path: polygon(
              calc(93.75% - 1.25rem - var(--scrollbar-width)) 3.063rem,
              calc(93.75% - var(--scrollbar-width)) 3.063rem,
              calc(93.75% - var(--scrollbar-width)) 3.188rem,
              calc(93.75% - 1.25rem - var(--scrollbar-width)) 3.188rem
            );
          }

          @media screen and (min-width: 90rem) {
            clip-path: polygon(
              calc(91% - 1.25rem - var(--scrollbar-width)) 3.063rem,
              calc(91% - var(--scrollbar-width)) 3.063rem,
              calc(91% - var(--scrollbar-width)) 3.188rem,
              calc(91% - 1.25rem - var(--scrollbar-width)) 3.188rem
            );
          }

          @media screen and (min-width: 120rem) {
            clip-path: polygon(
              calc(100% - ((100% - 1579px) / 2) - 1.25rem - var(--scrollbar-width)) 3.063rem,
              calc(100% - ((100% - 1579px) / 2) - var(--scrollbar-width)) 3.063rem,
              calc(100% - ((100% - 1579px) / 2) - var(--scrollbar-width)) 3.188rem,
              calc(100% - ((100% - 1579px) / 2) - 1.25rem - var(--scrollbar-width)) 3.188rem
            );
          }
        `}
`;

export default Menu;

import * as React from 'react';
const SvgAxEngageLogo = (props) => (
  <svg viewBox="0 0 447 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#ax-engage-logo_svg__a)">
      <path
        d="M441.089 4.918h-.53V1.24h-1.175V.749h2.878v.492h-1.176v3.677h.003Zm5.381-3.449-1.42 3.449h-.151l-1.42-3.449v3.449h-.53V.749h.816l1.211 3.034L446.187.75h.816v4.169h-.53V1.469h-.003ZM22.305.82h-4.603L0 44.097h4.15L19.974 4.455l10.375 25.629H17.513l-1.414 3.371h15.416l4.343 10.642h4.15L22.305.82Z"
        fill="#000"
      />
      <path d="M62.371 24.462 46.87 44.181h4.667l13.135-17.054-2.3-2.665Z" fill="#EB0045" />
      <path
        d="M68.979 21.843 85.387.82h-4.67l-14.07 18.362L52.638.82h-4.731l33.847 43.277h4.603L68.979 21.843ZM180.391 6.644v37.51h-3.711V.718h3.775l27.204 36.987V.717h3.71v43.435h-3.646L180.387 6.64l.004.004ZM254.644 0c7.16 0 12.367 2.864 16.337 7.424l-2.798 2.019c-3.058-3.648-8.004-6.059-13.539-6.059-10.218 0-18.094 7.75-18.094 19.082 0 11.333 7.876 19.146 18.094 19.146 5.791 0 10.35-2.863 12.756-5.274V25.982h-16.466v-3.387h20.176v15.173c-3.906 4.298-9.634 7.229-16.466 7.229-12.235 0-22-9.118-22-22.534 0-13.415 9.765-22.463 22-22.463ZM364.665 0c7.16 0 12.367 2.864 16.337 7.424l-2.798 2.019c-3.058-3.648-8.004-6.059-13.539-6.059-10.218 0-18.094 7.75-18.094 19.082 0 11.333 7.876 19.146 18.094 19.146 5.791 0 10.35-2.863 12.756-5.274V25.982h-16.466v-3.387h20.176v15.173c-3.906 4.298-9.634 7.229-16.466 7.229-12.235 0-22-9.118-22-22.534 0-13.415 9.761-22.463 22-22.463ZM310.44.717h-4.623l-17.766 43.438h4.163l15.881-39.79 10.414 25.722h-12.881l-1.42 3.387h15.473l4.359 10.68h4.166L310.44.718ZM157.366.717h-28.987v3.387h28.987V.717ZM149.92 19.455h-21.541v3.387h20.053l1.488-3.387ZM157.366 40.767h-28.987v3.388h28.987v-3.388ZM432.198.717h-28.987v3.387h28.987V.717ZM424.752 19.455h-21.541v3.387h20.054l1.487-3.387ZM432.198 40.767h-28.987v3.388h28.987v-3.388Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="ax-engage-logo_svg__a">
        <path fill="#fff" d="M0 0h447v45H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAxEngageLogo;

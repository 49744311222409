import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  AiMarketer1,
  AiMarketer2,
  AiMarketer3,
  AiCross,
  AiBall,
  AiPill
} from 'images/aiMarketingCaseStudy';
import { RenderWhenReady } from 'components';
import { useWindowSize } from 'hooks';

const MarketerBlocks = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <RenderWhenReady>
        {windowWidth > 768 && (
          <>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiCross color="#DA629E" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#0F88C1" secondaryColor="#47AD4A" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiPill primaryColor="#47AD4A" secondaryColor="#EDB40D" />
            </AnimatedSVG>
            <AnimatedSVG
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <AiBall primaryColor="#7A68CC" secondaryColor="#DA629E" />
            </AnimatedSVG>
          </>
        )}
      </RenderWhenReady>
      <h2>What does this mean for Marketers?</h2>
      <motion.div>
        <Block>
          <SVGContainer>
            <AiMarketer1 />
          </SVGContainer>
          <div>
            <h3>Experiment</h3>
            <p>
              Applications across healthcare marketing include tailored product recommendations,
              creating descriptions, headlines and images to enhance campaigns.
              <br />
              <br />
              Experimenting with AI for solutions to break down barriers preventing consumers from
              accessing critical healthcare. For example, the World Economic Forum suggests LLMs
              trained on high-quality data in different languages could help consumers access care,
              regardless of their location or cultural context.
              <br />
              <br />
              Explore how gen AI can enhance the user experience of patients. For example, Amazon
              Pharmacy uses gen AI to provide customers with up-to-date medication prices and speedy
              answers to their queries.
            </p>
          </div>
        </Block>
        <Block>
          <SVGContainer>
            <AiMarketer2 />
          </SVGContainer>
          <div>
            <h3>Plan</h3>
            <p>
              Scenario planning is useful for companies to understand and anticipate potential
              threats to healthcare caused by gen AI. For example, designing plans for possible
              scenarios - such as intentional sharing of disinformation. By putting in place
              safeguards, marketers will be better equipped to protect their brands when and if such
              a situation arises. Companies should start developing an internal governance structure
              for the use of generative AI, outlining guidelines in accordance to the
              organisations&apos; goals.
            </p>
          </div>
        </Block>
        <Block>
          <SVGContainer>
            <AiMarketer3 />
          </SVGContainer>
          <div>
            <h3>Consider</h3>
            <p>
              Empower your audiences. With the rise of inflation, consumers are worried about rising
              costs of healthcare. Providing education through marketing efforts help consumers make
              informed decisions about self-care, take control of their health and allow brands to
              build trust and credibility.
              <br />
              <br />
              Foster partnerships with industry players and stay abreast of news about small
              pilot-scale Ai projects that will provide valuable insight about risks and gains in
              different use cases.
            </p>
          </div>
        </Block>
      </motion.div>
    </Container>
  );
};

const SVGContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 290px;
`;

const Block = styled.div`
  align-items: center;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1.25rem;
  @media (min-width: 64rem) {
    flex-direction: row;
    padding: 2rem 4rem;
    gap: 5rem;
  }
`;

export const AnimatedSVG = styled(motion.div)`
  grid-template-columns: 1fr !important;
  @keyframes rollRight {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    50% {
      transform: translateX(50px) rotate(60deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;
  position: relative;
  > div {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  h2 {
    color: var(--ax-gold);
    font-size: 2rem;
    grid-column: 2/3;
    margin-bottom: 2rem;
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: -60px;
      left: 0;
      width: 80px;
      @media (min-width: 64rem) {
        left: -50px;
        top: -20px;
      }
    }
    :nth-child(2) {
      top: 40%;
      left: -190px;
      width: 120px;
    }
    :nth-child(3) {
      top: -80px;
      right: -120px;
      width: 120px;
      svg {
        transform: rotate(-30deg);
      }
      @media (min-width: 64rem) {
        top: 0;
        right: -180px;
      }
    }
    :nth-child(4) {
      bottom: -20px;
      right: -180px;
      width: 170px;
      opacity: 0 !important;
      @media (min-width: 64rem) {
        opacity: 1 !important;
        svg {
          animation: rollRight 3s 1s ease-in-out infinite alternate-reverse;
        }
      }
    }
  }
  ${Block} {
    :nth-child(1) {
      svg {
        width: 290px;
      }
    }
    :nth-child(2) {
      svg {
        width: 190px;
      }
    }
    :nth-child(3) {
      svg {
        width: 245px;
      }
    }
  }
  @media (min-width: 48rem) {
    padding: 3rem 0 3rem 0;
    h2 {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

export default MarketerBlocks;

import * as React from 'react';

function SvgEmailIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#ce9200" />
      <g stroke="#fff" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round">
        <path d="M7.362 8.308h9.275c.54 0 .9.365.9.913v6.481c0 .548-.36.913-.9.913H7.361c-.54 0-.9-.365-.9-.913V9.221c0-.548.36-.913.9-.913z" />
        <path d="M17.076 8.77l-5.077 5.077L6.922 8.77M13.846 12l3.692 3.692M10.153 12l-3.692 3.692" />
      </g>
    </svg>
  );
}

export default SvgEmailIcon;

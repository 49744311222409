import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg viewBox="0 0 29 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22 8.868H0V6.132h22L23.197 7.5 22 8.868z" fill="#EB0045" />
      <path
        d="M23.197.5h-3.493l5.507 6.993-5.507 7.007h3.492l5.508-7.007L23.196.5z"
        fill="#EB0045"
      />
    </svg>
  );
}

export default SvgArrow;

import * as React from 'react';
const SvgSlide3 = (props) => (
  <svg viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M162.901 149.502v70.145c0 5.144-4.19 9.353-9.311 9.353H79.1c-5.122 0-9.312-4.209-9.312-9.353V98.063c0-5.144 4.19-9.353 9.311-9.353h13.967M69.788 200.942h93.113M139.623 112.092H69.788M107.033 214.971H121M138.649 65.116h-6.391l-19.74 23.148c-2.793 3.273-8.194 1.31-8.194-3.04V65.116h-4.655c-5.121 0-9.312-4.209-9.312-9.353v-37.41c0-5.144 4.19-9.353 9.312-9.353h74.49c5.121 0 9.311 4.209 9.311 9.353V46.41"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M161.165 57.719h60.524c5.121 0 9.311 4.208 9.311 9.352v42.087c0 5.144-4.19 9.353-9.311 9.353h-46.557l-15.038 18.144c-2.793 3.367-8.24 1.403-8.24-2.993v-66.59c0-5.145 4.19-9.353 9.311-9.353Z"
      fill="#CE9200"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M76.179 60.652H20.31c-5.12 0-9.311 4.209-9.311 9.353v56.116c0 5.144 4.19 9.352 9.311 9.352h4.656l14.851 20.857c2.654 3.694 8.427 1.824 8.427-2.712v-18.145h9.311"
      stroke="#000"
      strokeWidth={8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSlide3;
